import "./PieChart.css";

import { Doughnut } from "react-chartjs-2";

import { ChartDataWithSuffix, ChartProps } from "../models";
import { maybeSetGlobalChartJsConfiguration } from "../utils";
import { ChartLegend, ChartLegendWithSuffix } from ".";
import { usePieChart } from "./usePieChart";

maybeSetGlobalChartJsConfiguration();

type PieChartVariant = "Primary" | "Secondary";

export interface PieChartProps extends ChartProps {
  size: number;
  variant?: PieChartVariant;
  centerTextHidden?: boolean;
  noDataMessage?: string;
  hasSuffix?: boolean;
  dataWithSuffix?: ChartDataWithSuffix;
  isGradient?: boolean;
  direction?: "Horizontal" | "Vertical";
}

export const PieChart = ({
  data,
  size,
  variant = "Primary",
  centerTextHidden = false,
  noDataMessage = "No data",
  hasSuffix = false,
  dataWithSuffix,
  isGradient = false,
  direction = "Horizontal",
}: PieChartProps): JSX.Element => {
  const { chartData, legendData, chartColours, plugins, options, hasData, pieChartRef } = usePieChart(
    data,
    centerTextHidden,
    noDataMessage,
    isGradient
  );

  return (
    <>
      {plugins && (
        <div className={`PieChart ${variant} ${direction}`}>
          <div className="Chart" style={{ maxWidth: size, maxHeight: size }}>
            <Doughnut ref={pieChartRef} data={chartData} options={options} plugins={plugins} />
          </div>
          {hasSuffix && dataWithSuffix ? (
            <ChartLegendWithSuffix colours={chartColours} hasData={hasData} data={dataWithSuffix} />
          ) : (
            <ChartLegend data={legendData} colours={chartColours} hasData={hasData} variant={variant} />
          )}
        </div>
      )}
      {(data === null || data === undefined || Object.values(data).length === 0) && (
        <div className={`PieChart ${variant} ${direction}`}>
          <div className="Chart" style={{ maxWidth: size, maxHeight: size }}>
            <Doughnut ref={pieChartRef} data={chartData} options={options} />
          </div>
          {hasSuffix && dataWithSuffix ? (
            <ChartLegendWithSuffix colours={chartColours} hasData={hasData} data={dataWithSuffix} />
          ) : (
            <ChartLegend data={legendData} colours={chartColours} hasData={hasData} variant={variant} />
          )}
        </div>
      )}
    </>
  );
};
