import { Dispatch, SetStateAction } from "react";

import { BasicDetailsData, LocationData, SelectData } from "../../../../../models";
import { Autocomplete, Column, KanaTooltip, ReadonlyText, Row, Select, TextInput } from "../../../../index";
import { useEditableBasic } from "./useEditableBasic";

interface BasicProps {
  basicDetailsData: BasicDetailsData;
  setBasicDetailsData: Dispatch<SetStateAction<BasicDetailsData>>;
  locationData: LocationData;
  setLocationData: Dispatch<SetStateAction<LocationData>>;
  validatorData: SelectData;
}

export const EditableBasic = ({
  basicDetailsData,
  setBasicDetailsData,
  locationData,
  setLocationData,
  validatorData,
}: BasicProps): JSX.Element => {
  const { countries } = useEditableBasic();

  return (
    <>
      <Row spacingV="ll">
        <Column span={4}>
          <TextInput
            label="Display name"
            name="Display name"
            type="text"
            value={basicDetailsData.displayName}
            onChange={(displayNameNewValue) =>
              setBasicDetailsData({
                ...basicDetailsData,
                displayName: displayNameNewValue,
              })
            }
          />
        </Column>
        <Column span={4} offset={1} />
      </Row>
      <Row spacingV="ll">
        <Column span={4}>
          <ReadonlyText
            label="Registry name"
            value={basicDetailsData.name || "Not available"}
            noDataAvailable={!basicDetailsData.name}
          />
        </Column>
        <Column span={4} offset={1}>
          <ReadonlyText
            label="Registry ID"
            value={basicDetailsData.registryId || "Not available"}
            noDataAvailable={!basicDetailsData.registryId}
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        {/* TODO The Select for Project Type will be added in a follow-up ticket */}
        <Column span={4}>
          <ReadonlyText
            label="Project type"
            value={basicDetailsData.projectType || "Not available"}
            noDataAvailable={!basicDetailsData.projectType}
          />
        </Column>
        <Column span={4} offset={1}>
          <TextInput
            label="Total area"
            name="Total area"
            type="number"
            value={basicDetailsData.totalArea}
            onChange={(totalAreaNewValue) =>
              setBasicDetailsData({
                ...basicDetailsData,
                totalArea: totalAreaNewValue,
              })
            }
          />
        </Column>
      </Row>
      <Row spacingV="ll">
        <Column span={4}>
          <TextInput
            label="Grid reference"
            name="Grid reference"
            value={locationData.gridReference}
            onChange={(gridReferenceNewValue) =>
              setLocationData({
                ...locationData,
                gridReference: gridReferenceNewValue,
              })
            }
          />
        </Column>
        <Column span={4} offset={1}>
          {countries && (
            <Autocomplete
              label="Country"
              data={countries}
              initialKey={locationData.addressCountryCode}
              onSelect={(addressCountryCodeNewValue) =>
                setLocationData({
                  ...locationData,
                  addressCountryCode: addressCountryCodeNewValue,
                })
              }
            />
          )}
        </Column>
      </Row>
      <Row spacingV="2xl">
        <Column span={4}>
          <TextInput
            label="Latitude"
            name="Latitude"
            tooltip={
              <KanaTooltip
                tooltipHeader="Why do we need this?"
                tooltipText="We use the longitude and latitude to display the project location in the Directory"
              />
            }
            value={locationData.latitude}
            onChange={(latitudeNewValue) =>
              setLocationData({
                ...locationData,
                latitude: latitudeNewValue,
              })
            }
          />
        </Column>
        <Column span={4} offset={1}>
          <TextInput
            label="Longitude"
            name="Longitude"
            tooltip={
              <KanaTooltip
                tooltipHeader="Why do we need this?"
                tooltipText="We use the longitude and latitude to display the project location in the Directory"
              />
            }
            value={locationData.longitude}
            onChange={(longitudeNewValue) =>
              setLocationData({
                ...locationData,
                longitude: longitudeNewValue,
              })
            }
          />
        </Column>
      </Row>
      {!!validatorData.length && (
        <Row spacingV="2xl">
          <Column span={4}>
            <Select
              label="Validator"
              data={validatorData}
              value={basicDetailsData.validatorUuid}
              onChange={(validatorUuidNewValue) =>
                setBasicDetailsData({
                  ...basicDetailsData,
                  validatorUuid: validatorUuidNewValue,
                })
              }
            />
          </Column>
        </Row>
      )}
    </>
  );
};
