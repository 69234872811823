import "./InformationBox.css";

import { ReactNode } from "react";

import { AlertIcon, InformationIcon, RocketLargeCoralIcon, SuccessIcon } from "../../../assets";
import { logError } from "../../../service/error";
import { capitalize } from "../../../utils";

type InformationBoxVariant = "success" | "alert" | "info" | "pilotProject";

export interface InformationBoxProps {
  variant: InformationBoxVariant;
  title?: string;
  children?: ReactNode;
}

const getIcon = (variant: InformationBoxVariant): JSX.Element | null => {
  switch (variant) {
    case "success":
      return <SuccessIcon />;
    case "alert":
      return <AlertIcon />;
    case "info":
      return <InformationIcon />;
    case "pilotProject":
      return <RocketLargeCoralIcon />;
    default:
      logError({ error: `No valid InformationBox could be found for variant: ${variant}` });
      return null;
  }
};

export const InformationBox = ({ variant, title, children }: InformationBoxProps): JSX.Element => {
  return (
    <div className={`InformationBox InformationBox${capitalize(variant)}`}>
      {title ? (
        <>
          <div className={`InformationBoxHeaderArea ${children ? "" : "InformationBoxNoChildren"}`}>
            <div className="InformationBoxIconContainer">{getIcon(variant)}</div>
            <h3>{title}</h3>
          </div>
          {children && <div className="body2 InformationBoxTextArea">{children}</div>}
        </>
      ) : (
        <div className="InformationBoxWithoutHeaderArea">
          <div className="InformationBoxIconContainer">{getIcon(variant)}</div>
          {children}
        </div>
      )}
    </div>
  );
};
