import "./AccessTab.css";

import { ProjectPermissionConstants } from "../../../../../../constants";
import { Button, Column, CursorDataGrid, Row } from "../../../../../../widget";
import { useAccessTab } from "./useAccessTab";

export const AccessTab = (): JSX.Element => {
  const { onNewInvitation, defaultSortingCriteria, columns, dataIsLoading, onChange, hasPermission } = useAccessTab();

  return (
    <div className="DeveloperProjectTabSection ProjectAccessTab">
      <Row spacingV="ll">
        <Column span={12}>
          <div className="AccessHeader">
            <div>
              <h2 className="AccessTitle">Access</h2>
              <span className="body1">You can invite other developers to access this project here.</span>
            </div>
            {hasPermission(ProjectPermissionConstants.MANAGE_PROJECT_ORGANISATION_INVITATION) && (
              <Button text="New invitation" onClick={onNewInvitation} />
            )}
          </div>
        </Column>
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        noDataMessage="No invitations to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
      />
    </div>
  );
};
