import { PillVariant } from "../../widget";

export class ActivityStatusConstants {
  public static readonly IN_PROGRESS = "In progress";

  public static readonly SUBMITTED = "Submitted";

  public static readonly APPROVED = "Approved";

  public static readonly REJECTED = "Rejected";

  public static readonly DELETED = "Deleted";

  public static readonly SUPERSEDED = "Superseded";

  public static readonly ACTIVITY_STATUS_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    "In Progress": "cyan",
    Submitted: "coral",
    Approved: "green",
    Rejected: "red",
    Deleted: "grey",
  };
}
