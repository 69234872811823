import { MutableRefObject, useEffect, useRef, useState } from "react";

interface useWorkflowReturnData {
  hasLeftArrow: boolean;
  hasNoCompleteButtonPadding: boolean;
  hasNoSubmitButtonPadding: boolean;
  areRightActivitiesHidden: boolean;
  onRightArrowClick: () => void;
  onLeftArrowClick: () => void;
  workflowRef: MutableRefObject<null | HTMLDivElement>;
  activitiesWrapperWidth: string;
  onScroll: () => void;
}

const LEFT_ARROW_SPACING = 56;

export const useWorkflow = (): useWorkflowReturnData => {
  const workflowRef = useRef<HTMLDivElement>(null);
  const [hasLeftArrow, setHasLeftArrow] = useState(false);
  const [hasNoSubmitButtonPadding, setHasNoSubmitButtonPadding] = useState(false);
  const [hasNoCompleteButtonPadding, setHasNoCompleteButtonPadding] = useState(false);

  const [areRightActivitiesHidden, setAreRightActivitiesHidden] = useState(true);
  const [activitiesWrapperWidth, setActivitiesWrapperWidth] = useState("");

  const onLeftArrowClick = (): void => {
    if (workflowRef.current) {
      workflowRef.current.scrollLeft = -(workflowRef.current.scrollWidth - workflowRef.current.clientWidth);
    }
    setAreRightActivitiesHidden(true);
  };

  const onRightArrowClick = (): void => {
    if (workflowRef.current) {
      workflowRef.current.scrollLeft = workflowRef.current.scrollWidth - workflowRef.current.clientWidth;
    }
    setAreRightActivitiesHidden(false);
  };

  const calcLeftArrowValue = (): void => {
    if (workflowRef.current) {
      const scrollPositionCloseToEnd = workflowRef.current.scrollWidth - workflowRef.current.clientWidth - 10;
      setHasLeftArrow(workflowRef.current.scrollLeft > scrollPositionCloseToEnd);
    }
  };

  const onScroll = (): void => {
    calcLeftArrowValue();
  };

  useEffect(() => {
    window.addEventListener("resize", calcLeftArrowValue);

    if (workflowRef.current) {
      setActivitiesWrapperWidth(`${(workflowRef.current.clientWidth - LEFT_ARROW_SPACING) / 2}px`);
    }

    return () => {
      window.removeEventListener("resize", calcLeftArrowValue);
    };
  }, []);

  useEffect(
    () =>
      workflowRef.current?.offsetWidth && workflowRef.current?.offsetWidth > 786
        ? setHasNoCompleteButtonPadding(false)
        : setHasNoCompleteButtonPadding(true),
    [workflowRef.current?.offsetWidth]
  );

  useEffect(
    () =>
      workflowRef.current?.offsetWidth && workflowRef.current?.offsetWidth > 751
        ? setHasNoSubmitButtonPadding(false)
        : setHasNoSubmitButtonPadding(true),
    [workflowRef.current?.offsetWidth]
  );

  return {
    hasLeftArrow,
    hasNoSubmitButtonPadding,
    hasNoCompleteButtonPadding,
    areRightActivitiesHidden,
    onRightArrowClick,
    onLeftArrowClick,
    workflowRef,
    activitiesWrapperWidth,
    onScroll,
  };
};
