import { Dispatch, SetStateAction, useContext, useState } from "react";

import { ActivityWizardContext } from "../../../../../route/developer/activities";
import { createDiscussionThread } from "../../../../../service/discussion";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserFullName, useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../general";
import { ProfileMenuItemType } from "../../../../navigation";
import { Thread } from "../../../models";

export type NewThreadInformation = {
  componentKey: string | null;
  dataPath: string | null;
  messageObjectType: string;
  messageObjectUuid: string;
  messageSourceOrganisationUuid: string;
  messageTargetOrganisationUuid: string;
  messageType: string;
  threadObjectUuid: string;
  visibility: string;
  messageTargetOrganisationRoles: string[];
};

interface useNewDiscussionProps {
  internalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  newThreadInformation: NewThreadInformation;
  handleInternalTeamSelect: () => void;
  handleDeveloperSelect: () => void;
}

interface useNewDiscussionReturnData {
  errors: ServiceError[] | undefined;
  showMessageDropdown: boolean;
  messageDropdownOptions: ProfileMenuItemType[];
  setShowMessageDropdown: Dispatch<SetStateAction<boolean>>;
  newMessage: string;
  setNewMessage: Dispatch<SetStateAction<string>>;
  handleSendDiscussion: () => void;
  firstStepCompleted: boolean;
  setFirstStepCompleted: Dispatch<SetStateAction<boolean>>;
  secondStepCompleted: boolean;
  internalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  isHandleSendDiscussionLoading: boolean;
}

export const useNewDiscussion = ({
  internalThreads,
  setInternalThreads,
  newThreadInformation,
  handleInternalTeamSelect,
  handleDeveloperSelect,
}: useNewDiscussionProps): useNewDiscussionReturnData => {
  const { user } = useAuth();
  const { activityHistoryRowVersion, setActivityHistoryRowVersion } = useContext(ActivityWizardContext);

  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [showMessageDropdown, setShowMessageDropdown] = useState<boolean>(false);
  const [newMessage, setNewMessage] = useState<string>("");

  const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false);
  const [secondStepCompleted, setSecondStepCompleted] = useState<boolean>(false);
  const [isHandleSendDiscussionLoading, setIsHandleSendDiscussionLoading] = useState(false);

  const [messageDropdownOptions] = useState<ProfileMenuItemType[]>([
    {
      id: 1,
      value: "Internal Team",
      action: () => {
        handleInternalTeamSelect();
        setShowMessageDropdown(false);
        setFirstStepCompleted(true);
      },
    },
    {
      id: 2,
      value: "Developer",
      action: () => {
        handleDeveloperSelect();
        setShowMessageDropdown(false);
        setFirstStepCompleted(true);
      },
    },
  ]);

  const handleSendDiscussion = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await createDiscussionThread({
      componentKey: newThreadInformation.componentKey,
      dataPath: newThreadInformation.dataPath,
      messageContent: newMessage,
      messageObjectType: newThreadInformation.messageObjectType,
      messageObjectUuid: newThreadInformation.messageObjectUuid,
      messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
      messageType: newThreadInformation.messageType,
      messageStatus: "Draft",
      threadObjectType: "Activity",
      threadObjectUuid: newThreadInformation.threadObjectUuid,
      threadType: "Discussion",
      visibility: newThreadInformation.visibility,
      messageTargetOrganisationRoles: newThreadInformation.messageTargetOrganisationRoles,
    });

    if (res.status === Status.Success && res.data && user) {
      setInternalThreads(
        internalThreads.concat([
          {
            uuid: res.data.threadUuid,
            resolved: false,
            hasNonDeletedMessagesCreatedByOtherUsers: false,
            messages: [
              {
                uuid: res.data.messageUuid,
                content: newMessage,
                createdAt: new Date(),
                isRead: false,
                status: "Draft",
                createdByUserUuid: user.userUuid,
                createdByUserFullName: getUserFullName(user.fullName, user.firstName, user.lastName),
                createdByUserAvatarUrl: user.avatar?.url,
                rowVersion: 1,
              },
            ],
          },
        ])
      );

      setActivityHistoryRowVersion(activityHistoryRowVersion + 1);

      setSecondStepCompleted(true);

      Toast.success({ message: "Discussion added" });
    }

    if (res.status === Status.Error) {
      setErrors(res.errors);
    }
  }, setIsHandleSendDiscussionLoading);

  return {
    errors,
    showMessageDropdown,
    messageDropdownOptions,
    setShowMessageDropdown,
    newMessage,
    setNewMessage,
    handleSendDiscussion,
    firstStepCompleted,
    setFirstStepCompleted,
    secondStepCompleted,
    internalThreads,
    setInternalThreads,
    isHandleSendDiscussionLoading,
  };
};
