import "./Overview.css";

import { ActionButtons, Column, EditableBasic, ErrorBox, HorizontalLifecycle, Row } from "../../../../../widget";
import { BadgesSection, BasicDetailsSection, CertificationsSection, StandardSpecificSection } from "./components";
import { GroupSection } from "./components/GroupSection";
import { useOverview } from "./useOverview";

const Overview = (): JSX.Element => {
  const {
    projectDetails,
    currentStatus,
    errors,
    isEditable,
    isReadOnly,
    basicDetailsData,
    setBasicDetailsData,
    locationData,
    setLocationData,
    onCancel,
    onSubmit,
    validatorData,
    certificateData,
    badgeData,
    standardSpecificDefinition,
    isOnSubmitLoading,
  } = useOverview();

  return (
    <>
      <div className="DeveloperProjectTabSection">
        <Row spacingV="2xl">
          <Column span={12}>
            <h2>Overview</h2>
          </Column>
        </Row>
        {errors && errors.length > 0 && (
          <Row spacingV="ml">
            <Column span={4}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row spacingV={isEditable ? "2xl" : "ll"}>
          <Column span={7}>
            <Row spacingV="ml">
              <Column span={12}>
                <h3>Lifecycle</h3>
              </Column>
            </Row>
            <HorizontalLifecycle currentStatus={currentStatus} />
          </Column>
        </Row>
        <Row>
          <Column span={12}>
            <form onSubmit={onSubmit}>
              {isEditable ? (
                <EditableBasic
                  basicDetailsData={basicDetailsData}
                  setBasicDetailsData={setBasicDetailsData}
                  locationData={locationData}
                  setLocationData={setLocationData}
                  validatorData={validatorData}
                />
              ) : (
                projectDetails && (
                  <BasicDetailsSection
                    projectDetails={projectDetails}
                    basicDetailsData={basicDetailsData}
                    setBasicDetailsData={setBasicDetailsData}
                    isReadOnly={isReadOnly}
                  />
                )
              )}
              {certificateData.length > 0 && <CertificationsSection certificates={certificateData} />}
              {badgeData.length > 0 && <BadgesSection badges={badgeData} />}
              {!isReadOnly && (
                <Row className="OverviewActionButtons">
                  <Column span={2}>
                    <ActionButtons onCancel={onCancel} isLoading={isOnSubmitLoading} />
                  </Column>
                </Row>
              )}
            </form>
          </Column>
        </Row>
      </div>
      {standardSpecificDefinition && projectDetails?.standardSpecificData && (
        <div className="DeveloperProjectTabSection">
          <StandardSpecificSection standardSpecificDefinition={standardSpecificDefinition} isReadOnly={isReadOnly} />
        </div>
      )}
      {projectDetails?.group?.groupUuid && projectDetails.uuid && (
        <div className="DeveloperProjectTabSection">
          <GroupSection groupUuid={projectDetails?.group?.groupUuid} currentProjectUuid={projectDetails.uuid} />
        </div>
      )}
    </>
  );
};

export default Overview;
