import { Dispatch, RefObject, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";

import { ActivityWizardContext } from "../../../../../route/developer/activities";
import {
  deleteDiscussionMessage,
  deleteDiscussionThread,
  updateDiscussionMessage,
  updateDiscussionThread,
} from "../../../../../service/discussion";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { formatDateToDDMMYYYY, isSameDay, useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../general";
import { ProfileMenuItemType } from "../../../../navigation";
import { Message, Thread } from "../../../models";

interface useDiscussionMessageProps {
  internalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  message: Message;
  closeDiscussion: () => void;
  threadUuid: string;
}

interface useDiscussionMessageReturnData {
  showResolve: boolean;
  showMisc: boolean;
  editable: boolean;
  editedMessage: string;
  editableErrors: ServiceError[] | undefined;
  setEditableErrors: Dispatch<SetStateAction<ServiceError[] | undefined>>;
  resolveMenuItem: ProfileMenuItemType;
  otherMessagesMenuItems: ProfileMenuItemType[] | undefined;
  setShowResolve: Dispatch<SetStateAction<boolean>>;
  setShowMisc: Dispatch<SetStateAction<boolean>>;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setEditedMessage: Dispatch<SetStateAction<string>>;
  formatDate: (date: Date) => string;
  handleEditMessage: (messageUuid: string) => Promise<void>;
  resolveRef: RefObject<HTMLDivElement>;
  miscRef: RefObject<HTMLDivElement>;
  firstMessageMenuItems: ProfileMenuItemType[] | undefined;
  showDeleteDiscussionModal: boolean;
  confirmDelete: () => Promise<void>;
  cancelDelete: () => void;
  showMarkAsRead: boolean;
  showUnreadIndicator: boolean;
  isHandleEditMessageLoading: boolean;
}

let globalInternalThreads: Thread[];

export const useDiscussionMessage = ({
  internalThreads,
  setInternalThreads,
  message,
  closeDiscussion,
  threadUuid,
}: useDiscussionMessageProps): useDiscussionMessageReturnData => {
  const { user } = useAuth();
  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);
  const { setRefreshActivityWizard, refreshActivityWizard } = useContext(ActivityWizardContext);
  const [showResolve, setShowResolve] = useState<boolean>(false);
  const [showMisc, setShowMisc] = useState<boolean>(false);

  const [editable, setEditable] = useState<boolean>(false);
  const [editedMessage, setEditedMessage] = useState<string>(message.content);
  const [editableErrors, setEditableErrors] = useState<ServiceError[] | undefined>();

  const [showDeleteDiscussionModal, setShowDeleteDiscussionModal] = useState<boolean>(false);
  const [uuidOfThreadToBeDeleted, setUuidOfThreadToBeDeleted] = useState<string | undefined>(undefined);
  const [isHandleEditMessageLoading, setIsHandleEditMessageLoading] = useState(false);

  const [refresh, setRefresh] = useState<boolean>(false);

  const createdByCurrentUser = message?.createdByUserUuid === userUuid;
  const isUnread = message?.isRead === false;
  const showMarkAsRead = !createdByCurrentUser && isUnread;
  const showUnreadIndicator = !createdByCurrentUser && isUnread;

  useEffect(() => {
    setRefresh(!refresh);
    globalInternalThreads = internalThreads;
  }, [userUuid, internalThreads]);

  let firstMenuItemsInitState: ProfileMenuItemType[] | undefined;
  let otherMessagesMenuItems: ProfileMenuItemType[] | undefined;

  if (isUnread) {
    firstMenuItemsInitState = [
      {
        id: 1,
        value: "Edit",
        action: () => {
          setEditable(true);
          setShowMisc(false);
        },
      },
      {
        id: 2,
        value: "Delete",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: async (args: { [p: string]: any } | undefined): Promise<void> => {
          setShowDeleteDiscussionModal(true);
          setUuidOfThreadToBeDeleted(args?.threadUuid);
        },
      },
    ];

    otherMessagesMenuItems = [
      {
        id: 1,
        value: "Edit",
        action: () => {
          setEditable(true);
          setShowMisc(false);
        },
      },
      {
        id: 2,
        value: "Delete",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        action: async (args: any): Promise<void> => {
          const res = await deleteDiscussionMessage({ messageUuid: args?.messageUuid });

          if (res.status === Status.Success) {
            setInternalThreads(
              internalThreads.map((t) => {
                if (t.messages.find((m) => m.uuid === args?.messageUuid)) {
                  return { ...t, messages: t.messages.filter((m) => m.uuid !== args?.messageUuid) };
                }

                return t;
              })
            );
            Toast.success({ message: "Message deleted" });
          } else if (res.status === Status.Error && res.errors && res.errors.length) {
            Toast.error({ message: res.errors[0].message });
          }

          setShowMisc(false);
        },
      },
    ];
  }

  const markAsReadAction = async (): Promise<void> => {
    const res = await updateDiscussionMessage({
      acceptOrReject: null,
      isRead: true,
      rejectedReason: null,
      content: message?.content || "",
      messageUuid: message?.uuid || "",
      rowVersion: message?.rowVersion ?? 0,
    });

    if (res.status === Status.Success) {
      setInternalThreads(
        globalInternalThreads.map((t) => {
          if (t.messages.find((m) => m.uuid === message?.uuid)) {
            return {
              ...t,
              messages: t.messages.map((m) => {
                if (m.uuid === message?.uuid) {
                  return {
                    ...m,
                    isRead: true,
                  };
                }
                return m;
              }),
            };
          }
          return t;
        })
      );
      Toast.success({ message: "Message read" });
    } else if (res.status === Status.Error && res.errors && res.errors.length) {
      Toast.error({ message: res.errors[0].message });
    }

    setRefresh(!refresh);
    setRefreshActivityWizard(!refreshActivityWizard);
  };

  if (showMarkAsRead) {
    firstMenuItemsInitState = [
      {
        id: 0,
        value: "Mark as read",
        action: markAsReadAction,
      },
    ];

    otherMessagesMenuItems = [
      {
        id: 0,
        value: "Mark as read",
        action: markAsReadAction,
      },
    ];
  }

  const [firstMessageMenuItems, setFirstMessageMenuItems] = useState<ProfileMenuItemType[] | undefined>(
    firstMenuItemsInitState
  );

  const resolveMenuItem = {
    id: 1,
    value: "Resolve discussion",
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    action: async (args: { [p: string]: any } | undefined): Promise<void> => {
      const res = await updateDiscussionThread({ isResolved: true, rowVersion: 1, threadUuid: args?.threadUuid });

      if (res.status === Status.Success) {
        setInternalThreads(
          internalThreads.map((t) => {
            if (t.uuid === args?.threadUuid) {
              return {
                ...t,
                resolved: true,
              };
            }

            return t;
          })
        );

        Toast.success({ message: "Discussion resolved" });
      }

      if (res.status === Status.Error && res.errors && res.errors.length) {
        Toast.error({ message: res.errors[0].message });
      }

      setShowResolve(false);
    },
  };

  const resolveRef = useRef<HTMLDivElement>(null);
  const miscRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const threadNotDeletable = internalThreads.find((t) => t.uuid === threadUuid)
      ?.hasNonDeletedMessagesCreatedByOtherUsers;

    if (threadNotDeletable) {
      // Filter out "Delete" option from the menu items
      setFirstMessageMenuItems(firstMessageMenuItems?.filter((i) => i.id !== 2));
    }
  }, [internalThreads, threadUuid]);

  const handleEditMessage = useIsLoadingWrapper(async (messageUuid: string): Promise<void> => {
    const res = await updateDiscussionMessage({
      acceptOrReject: null,
      isRead: null,
      rejectedReason: null,
      content: editedMessage,
      messageUuid,
      rowVersion: message?.rowVersion ?? 0,
    });

    if (res.status === Status.Success) {
      setInternalThreads(
        internalThreads.map((t) => {
          if (t.messages.find((m) => m.uuid === messageUuid)) {
            return {
              ...t,
              messages: t.messages.map((m) => {
                if (m.uuid === messageUuid) {
                  return {
                    ...m,
                    content: editedMessage,
                  };
                }
                return m;
              }),
            };
          }

          return t;
        })
      );

      setEditable(false);
      setEditableErrors(undefined);
      Toast.success({ message: "Message updated" });
    } else if (res.status === Status.Error && res.errors) {
      setEditableErrors(res.errors);
    }
  }, setIsHandleEditMessageLoading);

  const formatDate = (date: Date): string => {
    const currentDate = new Date();
    const yesterdayDate = new Date(currentDate);
    yesterdayDate.setDate(yesterdayDate.getDate() - 1);

    if (isSameDay(currentDate, date)) {
      return "Today";
    }
    if (isSameDay(yesterdayDate, date)) {
      return "Yesterday";
    }
    return formatDateToDDMMYYYY(date);
  };

  const confirmDelete = async (): Promise<void> => {
    setShowDeleteDiscussionModal(false);
    if (uuidOfThreadToBeDeleted) {
      const res = await deleteDiscussionThread({ threadUuid: uuidOfThreadToBeDeleted });

      if (res.status === Status.Success) {
        setInternalThreads(internalThreads.filter((t) => t.uuid !== uuidOfThreadToBeDeleted));

        closeDiscussion();
        Toast.success({ message: "Discussion deleted" });
      }

      if (res.status === Status.Error && res.errors && res.errors.length) {
        Toast.error({ message: res.errors[0].message });
      }
    }
  };

  const cancelDelete = (): void => {
    setShowDeleteDiscussionModal(false);
  };

  return {
    showResolve,
    showMisc,
    editable,
    editedMessage,
    editableErrors,
    setEditableErrors,
    resolveMenuItem,
    otherMessagesMenuItems,
    setShowResolve,
    setShowMisc,
    setEditable,
    setEditedMessage,
    formatDate,
    handleEditMessage,
    resolveRef,
    miscRef,
    firstMessageMenuItems,
    showDeleteDiscussionModal,
    confirmDelete,
    cancelDelete,
    showUnreadIndicator,
    showMarkAsRead,
    isHandleEditMessageLoading,
  };
};
