import "../VerifierSingleCardTemplate/VerifierSingleCardTemplate.css";
import "./VerifierSingleCardWithTabsTemplate.css";

import { NavigationActiveElement, TabsList } from "../../../models";
import { Column, LeftNavigation, Row, Tabs, UserHeader } from "../../../widget";

interface VerifierSingleCardWithTabsTemplateProps {
  activeElement: NavigationActiveElement;
  pageTitle: string | undefined;
  tabsList: TabsList;
}

export const VerifierSingleCardWithTabsTemplate = ({
  activeElement,
  pageTitle,
  tabsList,
}: VerifierSingleCardWithTabsTemplateProps): JSX.Element => {
  return (
    <div className="VerifierSingleCardTemplate">
      <div className="VerifierSingleCardTemplateNavigation">
        <LeftNavigation activeElement={activeElement} />
      </div>
      <div className="VerifierSingleCardTemplateContainer">
        <UserHeader />
        <Row spacingV="ll">
          <Column span={12}>
            <h1>{pageTitle}</h1>
          </Column>
        </Row>
        <Tabs className="TemplateTabs" tabsList={tabsList} />
      </div>
    </div>
  );
};
