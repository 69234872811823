import { Navigate, Route, Routes } from "react-router-dom";

import { OrganisationPermissionConstants } from "../constants";
import {
  ActivityPageParams,
  DownloadActivityZipPageParams,
  PrivateActivityDocumentsParams,
  PrivateProjectDocumentsParams,
  PublicDeveloperDetailsPreviewParams,
  PublicProjectOverviewPreviewParams,
} from "../models";
import {
  ActivitiesPage,
  ActivityEditPage,
  ActivityViewPage,
  ActivityWizardProvider,
} from "../route/developer/activities";
import { DashboardPage } from "../route/developer/dashboard";
import { DownloadZipPage } from "../route/developer/download";
import { DeveloperError403, DeveloperError404, DeveloperError500 } from "../route/developer/errors";
import { MarketingStatsPage } from "../route/developer/marketing";
import { ChangePasswordPage, ChangePersonalDetailsPage } from "../route/developer/profile";
import {
  MarketingSettingsTab,
  ProjectAccessAcceptInvitationPage,
  ProjectAccessDetailsPage,
  ProjectAccessInvitePage,
  ProjectAccessTab,
  ProjectPage,
  ProjectsPage,
} from "../route/developer/projects";
import { SettingsPage, SettingsProjectAccessInvitePage, SettingsProjectAccessTab } from "../route/developer/settings";
import { AuditHistoryTab } from "../route/developer/settings/audit-history";
import { DocumentTypesTab, DocumentTypeViewPage } from "../route/developer/settings/document-types";
import { EmbeddingTab } from "../route/developer/settings/embedding";
import { DeveloperMarketingAssetsTab } from "../route/developer/settings/marketing-assets";
import { OrganisationTab } from "../route/developer/settings/organisation";
import { UsersEditPage, UsersListPage, UsersSendInvitationPage } from "../route/developer/settings/users";
import { PublicPage } from "../route/public";
import { PublicDeveloperDetailsPreviewPage } from "../route/public/developer";
import { PublicProjectOverviewPreviewPage, PublicProjectPreviewContainer } from "../route/public/project";
import {
  ActivityDashboardProvider,
  ActivityDiscussionProvider,
  ActivityDiscussionTab,
  ActivityPage,
  DocumentsTab as ActivityDocumentsTab,
  VersionHistoryTab,
} from "../route/shared/activities-dashboard";
import { ActivityDocumentHistoryPage } from "../route/shared/activities-dashboard/documents";
import {
  DocumentProvider,
  DocumentsTab,
  GroupProvider,
  IssuancesTab,
  OverviewTab,
  ProjectActivitiesTab,
  ProjectAuditHistoryPage,
  ProjectDocumentHistoryPage,
  ProjectOverviewManageProjectGroupPage,
  ProjectProvider,
} from "../route/shared/projects";
import { useRouteGuards } from "./guards";

export const DeveloperRoutes = (): JSX.Element => {
  const { RequireUserPermission, RequireProjectPermission, RequireDeveloperAuth } = useRouteGuards();

  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <RequireDeveloperAuth>
            <DashboardPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="activities"
        element={
          <RequireDeveloperAuth>
            <ActivitiesPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/edit`}
        element={
          <RequireDeveloperAuth>
            <ActivityWizardProvider>
              <ActivityEditPage />
            </ActivityWizardProvider>
          </RequireDeveloperAuth>
        }
      />
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/view`}
        element={
          <RequireDeveloperAuth>
            <ActivityWizardProvider>
              <ActivityViewPage />
            </ActivityWizardProvider>
          </RequireDeveloperAuth>
        }
      />

      <Route
        path={`activity/:${ActivityPageParams.activityUuid}/document/:${PrivateActivityDocumentsParams.activityDocumentUuid}`}
        element={
          <RequireDeveloperAuth>
            <ActivityDashboardProvider>
              <ActivityDocumentHistoryPage />
            </ActivityDashboardProvider>
          </RequireDeveloperAuth>
        }
      />

      <Route
        path={`activity/:${ActivityPageParams.activityUuid}/*`}
        element={
          <ActivityDashboardProvider>
            <ActivityPage />
          </ActivityDashboardProvider>
        }
      >
        <Route
          path="discussions"
          element={
            <ActivityWizardProvider>
              <RequireDeveloperAuth>
                <ActivityDiscussionProvider>
                  <ActivityDiscussionTab />
                </ActivityDiscussionProvider>
              </RequireDeveloperAuth>
            </ActivityWizardProvider>
          }
        />
        <Route
          path="documents"
          element={
            <RequireDeveloperAuth>
              <ActivityDocumentsTab />
            </RequireDeveloperAuth>
          }
        />
        <Route
          path="history"
          element={
            <ActivityWizardProvider>
              <RequireDeveloperAuth>
                <VersionHistoryTab />
              </RequireDeveloperAuth>
            </ActivityWizardProvider>
          }
        />
      </Route>

      <Route
        path="projects"
        element={
          <RequireDeveloperAuth>
            <ProjectsPage />
          </RequireDeveloperAuth>
        }
      />

      <Route
        path="projects/:projectUuid/*"
        element={
          <ProjectProvider>
            <ProjectPage />
          </ProjectProvider>
        }
      >
        <Route
          path="overview"
          element={
            <RequireDeveloperAuth>
              <OverviewTab />
            </RequireDeveloperAuth>
          }
        />
        <Route
          path="issuances"
          element={
            <RequireDeveloperAuth>
              <IssuancesTab />
            </RequireDeveloperAuth>
          }
        />
        <Route
          path="activities"
          element={
            <RequireDeveloperAuth>
              <ProjectActivitiesTab />
            </RequireDeveloperAuth>
          }
        />
        <Route
          path="documents"
          element={
            <RequireDeveloperAuth>
              <DocumentsTab />
            </RequireDeveloperAuth>
          }
        />
        <Route
          path="marketing-settings"
          element={
            <RequireDeveloperAuth>
              <MarketingSettingsTab />
            </RequireDeveloperAuth>
          }
        />
        <Route
          path="access"
          element={
            <RequireDeveloperAuth>
              <RequireProjectPermission>
                <ProjectAccessTab />
              </RequireProjectPermission>
            </RequireDeveloperAuth>
          }
        />
      </Route>
      <Route
        path={`:${DownloadActivityZipPageParams.ownerType}/:${DownloadActivityZipPageParams.ownerUuid}/download-zip/:${DownloadActivityZipPageParams.activityZipUuid}`}
        element={
          <RequireDeveloperAuth>
            <DownloadZipPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="projects/:projectUuid/audit-history"
        element={
          <RequireDeveloperAuth>
            <ProjectProvider>
              <ProjectAuditHistoryPage />
            </ProjectProvider>
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="projects/:groupUuid/manage-project-group"
        element={
          <RequireDeveloperAuth>
            <GroupProvider>
              <ProjectOverviewManageProjectGroupPage />
            </GroupProvider>
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="project/access-details/:projectOrganisationInvitationUuid"
        element={
          <RequireDeveloperAuth>
            <ProjectProvider>
              <ProjectAccessDetailsPage />
            </ProjectProvider>
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="projects/:projectUuid/access/invite"
        element={
          <RequireDeveloperAuth>
            <ProjectProvider>
              <ProjectAccessInvitePage />
            </ProjectProvider>
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="project/access/invite/accept/:projectOrganisationInvitationUuid"
        element={
          <RequireDeveloperAuth>
            <ProjectProvider>
              <ProjectAccessAcceptInvitationPage />
            </ProjectProvider>
          </RequireDeveloperAuth>
        }
      />
      <Route
        path={`projects/:${PrivateProjectDocumentsParams.projectUuid}/documents/:${PrivateProjectDocumentsParams.projectDocumentUuid}`}
      >
        <Route
          path=""
          element={
            <RequireDeveloperAuth>
              <ProjectProvider>
                <DocumentProvider>
                  <ProjectDocumentHistoryPage />
                </DocumentProvider>
              </ProjectProvider>
            </RequireDeveloperAuth>
          }
        />
      </Route>
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/view?:location`}
        element={
          <RequireDeveloperAuth>
            <ActivityViewPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="settings/*"
        element={
          <RequireDeveloperAuth>
            <SettingsPage />
          </RequireDeveloperAuth>
        }
      >
        <Route
          path="users"
          element={
            <RequireUserPermission
              userPermission={OrganisationPermissionConstants.READ_ORGANISATION_USERS}
              redirectRoute="/d/settings/organisation"
            >
              <UsersListPage />
            </RequireUserPermission>
          }
        />
        <Route path="organisation" element={<OrganisationTab />} />
        <Route path="marketing-assets" element={<DeveloperMarketingAssetsTab />} />
        <Route path="marketing-assets/:previewUuid" element={<DeveloperMarketingAssetsTab />} />
        <Route path="embedding" element={<EmbeddingTab />} />
        <Route path="project-access" element={<SettingsProjectAccessTab />} />
        <Route path="document-types" element={<DocumentTypesTab />} />
        <Route path="audit-history" element={<AuditHistoryTab />} />
        <Route path="" element={<Navigate to="users" replace />} />
      </Route>
      <Route
        path="settings/users/send-invitation"
        element={
          <RequireDeveloperAuth>
            <UsersSendInvitationPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="settings/users/:userUuid/edit"
        element={
          <RequireDeveloperAuth>
            <UsersEditPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="settings/document-types/:typeUuid/view"
        element={
          <RequireDeveloperAuth>
            <DocumentTypeViewPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="settings/project-access/invite"
        element={
          <RequireDeveloperAuth>
            <SettingsProjectAccessInvitePage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="marketing/marketing-stats"
        element={
          <RequireDeveloperAuth>
            <MarketingStatsPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="profile/change-password"
        element={
          <RequireDeveloperAuth>
            <ChangePasswordPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="profile/change-personal-details"
        element={
          <RequireDeveloperAuth>
            <ChangePersonalDetailsPage />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path={`developer/details/preview/:${PublicDeveloperDetailsPreviewParams.previewUuid}`}
        element={
          <RequireDeveloperAuth>
            <PublicDeveloperDetailsPreviewPage />
          </RequireDeveloperAuth>
        }
      />
      <Route path="project/*" element={<PublicPage />}>
        <Route
          path={`:${PublicProjectOverviewPreviewParams.uuid}/preview/:${PublicProjectOverviewPreviewParams.previewUuid}`}
          element={
            <RequireDeveloperAuth>
              <PublicProjectPreviewContainer />
            </RequireDeveloperAuth>
          }
        >
          <Route path="overview" element={<PublicProjectOverviewPreviewPage />} />
        </Route>
      </Route>
      <Route
        path="403/access-denied"
        element={
          <RequireDeveloperAuth>
            <DeveloperError403 />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="500/application-error"
        element={
          <RequireDeveloperAuth>
            <DeveloperError500 />
          </RequireDeveloperAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireDeveloperAuth>
            <DeveloperError404 />
          </RequireDeveloperAuth>
        }
      />
    </Routes>
  );
};
