import "./TableRepeater.css";

import { ReactElement, ReactNode, useId } from "react";

import { CopyBlueIcon, PlusIcon, TrashDark } from "../../../../assets";
import { CreateRowArgs, RepeaterData } from "../../../../models";
import { Button } from "../../Button";
import { useRepeater } from "../hooks";

export interface TableRepeaterProps {
  maxRows: number;
  initialValue?: RepeaterData[];
  tableHeaders: string[];
  onChange: (value: RepeaterData[]) => void;
  children: ReactNode[];
  addButtonText?: string;
  removeButtonText?: string;
  isFixedSize?: boolean;
  expressionContext?: boolean;
  repeaterIndex?: number;
  keyProp?: string;
}

const TableRepeaterCell: (child: ReactElement) => ReactNode = (child: ReactNode) => <td>{child}</td>;

export const TableRepeater = ({
  maxRows,
  initialValue = [],
  tableHeaders,
  onChange,
  children,
  addButtonText,
  removeButtonText,
  isFixedSize = false,
  expressionContext,
  repeaterIndex,
  keyProp,
}: TableRepeaterProps): JSX.Element => {
  const id = useId();

  const createRow: (args: CreateRowArgs) => ReactNode = ({
    template,
    currentData,
    idx,
    handleRemoveRow,
    createChildrenRecursive,
  }) => {
    return (
      <tr className="TableRepeaterRow" key={`TableRepeaterRow_${id}_${idx}`}>
        {createChildrenRecursive({
          children: template,
          fn: TableRepeaterCell,
          currentData,
          idx,
          currentDiscussions: undefined,
        })}
        {!isFixedSize && maxRows > 1 && (
          <td
            className="TableRepeaterRowRemoveButton"
            style={{ visibility: currentData.length === 1 ? "hidden" : "visible", verticalAlign: "middle" }}
          >
            <Button
              text={removeButtonText || "Remove"}
              variant="secondary"
              size="small"
              iconPosition="left"
              icon={<TrashDark />}
              onClick={() => handleRemoveRow(idx)}
            />
          </td>
        )}
      </tr>
    );
  };

  // If the tableRepeater is inside a normal Repeater we need to change the initial value to point to the correct data
  const getValueAtRepeaterIndex = (idx: number, key: string): RepeaterData[] =>
    initialValue[idx][key] ? (Object.values(initialValue[idx][key]) as unknown as RepeaterData[]) : [];

  const formattedInitialValue =
    repeaterIndex !== undefined && keyProp !== undefined
      ? getValueAtRepeaterIndex(repeaterIndex, keyProp)
      : initialValue;

  const { rows, handleAddRow, handlePasteFromClipboard } = useRepeater({
    initialValue: formattedInitialValue,
    template: children,
    maxRows,
    expressionContext,
    repeaterIndex,
    createRow,
    onChange,
    discussions: undefined,
  });

  return (
    <div className="TableRepeaterContainer">
      <table className="TableRepeater">
        <thead>
          <tr className="TableRepeaterRow">
            {tableHeaders.map((th) => (
              <th key={th}>{th}</th>
            ))}
          </tr>
        </thead>
        <tbody>{rows}</tbody>
      </table>
      {!isFixedSize && (
        <div className="TableRepeaterFooter">
          {rows.length < maxRows && (
            <div>
              <Button
                text={addButtonText || "Add another"}
                variant="secondary"
                size="small"
                iconPosition="left"
                icon={<PlusIcon />}
                onClick={handleAddRow}
              />
            </div>
          )}
          {maxRows > 1 && (
            <div>
              <Button
                text="Paste from clipboard"
                icon={<CopyBlueIcon />}
                iconPosition="left"
                variant="tertiary"
                onClick={handlePasteFromClipboard}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
