import { Route, Routes } from "react-router-dom";

import {
  AssetManagerRoutes,
  AuthenticationRoutes,
  DeveloperRoutes,
  DevelopmentManagerRoutes,
  PublicRoutes,
  VerifierRoutes,
} from "./navigators";
import { useAppRouter } from "./useAppRouter";
import { RouterContextProvider } from "./useRouterContext";

export const AppRouter = (): JSX.Element => {
  useAppRouter();

  return (
    <RouterContextProvider>
      <Routes>
        <Route path="*" element={<AuthenticationRoutes />} />

        <Route path="/d/*" element={<DeveloperRoutes />} />

        <Route path="/v/*" element={<VerifierRoutes />} />

        <Route path="/dm/*" element={<DevelopmentManagerRoutes />} />

        <Route path="/p/*" element={<PublicRoutes />} />

        <Route path="/am/*" element={<AssetManagerRoutes />} />
      </Routes>
    </RouterContextProvider>
  );
};
