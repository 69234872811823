import { Column, CursorDataGrid, Row } from "../../../../widget";
import { DetailsPanel } from "../../../developer/activities/activity/pages/detail/components";
import { useActivityVersionHistoryTab } from "./useVersionHistory";

export const VersionHistory = (): JSX.Element => {
  const {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    selectedActivityHistoryUuid,
    showDetailsPanel,
    onCloseDetailsPanel,
    canManageActivity,
  } = useActivityVersionHistoryTab();

  return (
    <>
      <div className="DeveloperProjectTabSection">
        <Row spacingV="ll">
          <Column span={12}>
            <h2>Version History</h2>
          </Column>
        </Row>
        <CursorDataGrid
          columns={columns}
          clientSide={false}
          filterable
          onChange={onChange}
          data={[]}
          noDataMessage="No activity history to display"
          dataIsLoading={dataIsLoading}
          defaultSortingCriteria={defaultSortingCriteria}
        />
      </div>
      {selectedActivityHistoryUuid && (
        <DetailsPanel
          show={showDetailsPanel}
          onClose={onCloseDetailsPanel}
          activityHistoryUuid={selectedActivityHistoryUuid}
          canManageActivity={canManageActivity}
        />
      )}
    </>
  );
};
