import "./AboutDeveloper.css";

import { Link } from "react-router-dom";

import { getPublicDeveloperDetailsRoute, getPublicProjectsRoute } from "../../../../utils/routes";
import { Column, DeveloperIcon, Row } from "../../../../widget";
import { DeveloperProject } from "../../usePublicProjectTemplate";
import { AboutDeveloperProject } from "./AboutDeveloperProject/AboutDeveloperProject";

interface AboutDeveloperProps {
  projectUuid: string;
  developerUuid: string;
  developerName: string;
  isOurValuesEnabled?: boolean;
  developerDescription?: string;
  developerIcon?: string;
  projects: DeveloperProject[] | undefined;
  isPreview?: boolean;
}

export const AboutDeveloper = ({
  projectUuid,
  developerUuid,
  developerName,
  isOurValuesEnabled,
  developerDescription,
  developerIcon,
  projects,
  isPreview = false,
}: AboutDeveloperProps): JSX.Element => {
  return (
    <div className="AboutDeveloperContainer">
      {developerIcon && (
        <Row spacingV="m">
          <Column span={12}>
            <DeveloperIcon developerIcon={developerIcon} />
          </Column>
        </Row>
      )}
      <Row spacingV="m">
        <Column span={12}>
          <h3>{`About ${developerName}`}</h3>
        </Column>
      </Row>
      {isOurValuesEnabled && (
        <Row spacingV="ml">
          <Column span={12}>
            <p className="body2 AboutDeveloperProjectDescription">
              {developerDescription && developerDescription !== ""
                ? developerDescription
                : `No description available for ${developerName}`}
            </p>
          </Column>
        </Row>
      )}
      {!isPreview && (
        <Row spacingV={projects && projects.length ? "2xl" : "s"}>
          <Column span={12}>
            <Link
              to={{
                pathname: getPublicDeveloperDetailsRoute(developerUuid),
                search: `?projectUuid=${projectUuid}`,
              }}
            >
              Find out more
            </Link>
          </Column>
        </Row>
      )}
      {projects && projects.length ? (
        <div>
          <Row spacingV="ml">
            <Column span={12}>
              <h3>Some of their other projects</h3>
            </Column>
          </Row>
          <Row spacingV="ml">
            <Column span={12}>
              <div className="AboutDeveloperProjects">
                {projects.map((p) => (
                  <AboutDeveloperProject key={p.uuid} project={p} />
                ))}
              </div>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <Link
                to={{
                  pathname: getPublicProjectsRoute(),
                  search: `?developer=${developerUuid}`,
                }}
              >
                View all their projects
              </Link>
            </Column>
          </Row>
        </div>
      ) : undefined}
    </div>
  );
};
