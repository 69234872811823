import { useCallback, useContext, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";

import { ProjectActivitiesConstants, ProjectPermissionConstants } from "../../../../constants";
import { CursorChangeProps, ResultType } from "../../../../models";
import {
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  getGroupDetails,
  searchActivityHistory,
  SearchActivityHistoryResponse,
} from "../../../../service/query";
import { ResultData, Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { checkCanManageActivity, flattenObject } from "../../../../utils";
import { getActivityViewRoute, getDashboardRoute } from "../../../../utils/routes";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../widget";
import { resumeActivity } from "../../utils";
import { ActivityDashboardContext } from "../ActivityDashboardContext";
import { VersionHistoryDetailsCellFormatter, VersionHistoryOpenActivityCellFormatter } from "./components";

interface UseActivityVersionHistoryTabReturnData {
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  selectedActivityHistoryUuid?: string;
  showDetailsPanel: boolean;
  onCloseDetailsPanel: () => void;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  canManageActivity: boolean;
}

export const useActivityVersionHistoryTab = (): UseActivityVersionHistoryTabReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    activityUuid,
    activityDetails,
    setShowVersionConflictModal,
    setNewVersionActivityHistory,
    setDraftActivityHistoryUuid,
    setShowDetailsPanel,
    onCloseDetailsPanel,
    showDetailsPanel,
  } = useContext(ActivityDashboardContext);

  const { currentUserType } = useAuth();

  const { hasManageProjectActivityPermission } = useContext(ActivityDashboardContext);

  const canManageActivity = checkCanManageActivity(hasManageProjectActivityPermission, activityDetails?.status);

  const [dataIsLoading, setDataIsLoading] = useState(true);

  const [selectedActivityHistoryUuid, setSelectedActivityHistoryUuid] = useState<string>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    { key: "versionNumber", name: "Version", dataType: "number" },
    { key: "changeSummary", name: "Change", dataType: "string" },
    {
      name: "Completion %",
      key: "completionPercentage",
      dataType: "number",
      formatter: "progress",
      alignment: "center",
    },
    { key: "createdByUser.fullName", name: "Author", dataType: "string" },
    {
      key: "createdAt",
      name: "Created at",
      dataType: "Date",
      formatter: "dateOnly",
      minWidth: 150,
      filterable: false,
    },
    {
      key: "detailsLink",
      name: "Details",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: VersionHistoryDetailsCellFormatter,
    },
    {
      key: "viewOrEditLink",
      name: "Open activity",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: VersionHistoryOpenActivityCellFormatter,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "versionNumber", direction: "DESC" }];

  const formatData = useCallback(
    (
      responseData: SearchActivityHistoryResponse | undefined,
      permissions: GetCurrentUserProjectPermissionsResponse[]
    ): ResultData[] => {
      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);

          const hasEditPermission =
            !!permissions.length &&
            permissions.every((p) =>
              p.currentUserPermissions.includes(ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY)
            );

          result.detailsLink = {
            action: () => {
              setSelectedActivityHistoryUuid(d.uuid);
              setShowDetailsPanel(true);
            },
          } as DataGridButtonLinkCellFormatterData;

          result.viewOrEditLink =
            d.isCurrent &&
            activityDetails?.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS &&
            hasEditPermission
              ? ({
                  text: "Edit",
                  action: () => {
                    resumeActivity(
                      navigate,
                      setDraftActivityHistoryUuid,
                      setNewVersionActivityHistory,
                      setShowVersionConflictModal,
                      d.activity.uuid,
                      d.isDraft ? d.uuid : undefined,
                      d.isDraft ? d.versionNumber : undefined
                    );
                  },
                } as DataGridButtonLinkCellFormatterData)
              : ({
                  action: () => {
                    navigate(
                      getActivityViewRoute(
                        d.uuid,
                        currentUserType,
                        searchParams.get("projectUuid") ? `projectUuid=${searchParams.get("projectUuid")}` : ""
                      )
                    );
                  },
                  text: "View",
                } as DataGridButtonLinkCellFormatterData);

          return result;
        }) || []
      );
    },
    [activityDetails]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!activityUuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activity = {
      uuid: {
        operator: "eq",
        value: activityUuid,
      },
    };

    filterCriteria.isDraft = {
      operator: "eq",
      value: false,
    };

    if (filterCriteria.completionPercentage) {
      filterCriteria.completionPercentage = {
        ...filterCriteria.completionPercentage,
        value: filterCriteria.completionPercentage.value / 100,
      };
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchActivityHistory({
      activityUuid,
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        let projectUuids = response.data?.results?.flatMap((el) => el.activity.project?.uuid || []) || [];

        if (response.data?.results?.length && response.data?.results[0]?.activity?.group?.uuid) {
          const groupDetailsRes = await getGroupDetails({
            projectGroupUuid: response.data?.results[0]?.activity?.group?.uuid,
          });

          if (groupDetailsRes.status === Status.Error && groupDetailsRes.errors) {
            if (groupDetailsRes.errors.find((e) => e.code === "VALIDATION_INVALID_USER_PERMISSION")) {
              if (window?.history?.state?.idx === 0) {
                navigate(getDashboardRoute(currentUserType), { replace: true });
              } else {
                navigate(-1);
              }

              Toast.error({ message: getErrorMessageFromCode("VALIDATION_INVALID_USER_PERMISSION") });
            }
          }

          if (groupDetailsRes.status === Status.Success && groupDetailsRes.data) {
            projectUuids = groupDetailsRes.data?.projects?.map((p) => p.uuid);
          }
        }

        const uniqueProjectUuids = Array.from(new Set(projectUuids));

        await getCurrentUserProjectPermissions({ projectUuids: uniqueProjectUuids }).then((permissions) => {
          data = {
            resultData: formatData(response.data, permissions.data || []),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              pageSize: paging.pageSize || 10,
              totalCount: response.data?.paging?.total || 0,
              hasNextPage: response.data?.paging?.hasNextPage || false,
              hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
            },
          };
        });
      })
      .finally(() => {
        setDataIsLoading(false);
      });
    return data;
  };

  return {
    showDetailsPanel,
    onCloseDetailsPanel,
    selectedActivityHistoryUuid,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    canManageActivity,
  };
};
