import "./ProgressIndicator.css";

import { useMemo } from "react";

import {
  AdditionalActivity1CoralIcon,
  AdditionalActivity2CoralIcon,
  DownwardArrowLightIcon,
} from "../../../../../assets";
import { ProjectActivitiesConstants, ProjectPermissionConstants } from "../../../../../constants";
import { WorkflowActivity } from "../../../../../models/shared/workflowActivityType";
import {
  CompleteActivityModal,
  ConfirmSubmissionModal,
  DownloadActivityFilesModal,
} from "../../../../../route/developer/components";
import { Button } from "../../../../forms";
import { ProfileMenu, ProfileMenuItem } from "../../../../navigation";
import { useProgressIndicator } from "./useProgressIndicator";

interface ProgressIndicatorProps {
  activity: WorkflowActivity;
  hasNoSubmitButtonPadding: boolean;
  hasNoCompleteButtonPadding: boolean;
  hasProjectPermission: (permission: string) => boolean;
  nrOfActivities?: number;
  isDisabled?: boolean;
}

export const ProgressIndicator = ({
  activity,
  hasNoSubmitButtonPadding,
  hasNoCompleteButtonPadding,
  hasProjectPermission,
  isDisabled = false,
  nrOfActivities,
}: ProgressIndicatorProps): JSX.Element => {
  const {
    showConfirmSubmissionModal,
    setShowConfirmSubmissionModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    isDocumentDownloading,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    downloadDropdownOptions,
    submitActivity,
    completeActivity,
  } = useProgressIndicator({
    activityHistoryUuid: activity?.activityHistoryUuid,
    currentOnly: activity?.isCurrentVersion,
    isVersionConflict: activity?.isVersionConflict,
  });

  const renderAdditionalActivityIcon = useMemo(() => {
    if (isDisabled) {
      if (nrOfActivities === 3) {
        return <AdditionalActivity2CoralIcon className="AdditionalActivityCoralIcon Enabled" />;
      }
      return <AdditionalActivity1CoralIcon className="AdditionalActivityCoralIcon Enabled" />;
    }
    if (nrOfActivities === 3) {
      return <AdditionalActivity2CoralIcon className="AdditionalActivityCoralIcon Disabled" />;
    }
    return <AdditionalActivity1CoralIcon className="AdditionalActivityCoralIcon Disabled" />;
  }, [nrOfActivities, isDisabled]);

  const displayWithOrWithoutSaveForSubmitButton = (): JSX.Element => {
    return activity.isDraftVersion ? (
      <Button
        hasNoPadding={hasNoSubmitButtonPadding}
        size="small"
        text="Save and Submit"
        onClick={() => submitActivity()}
      />
    ) : (
      <Button size="small" text="Submit" onClick={() => setShowConfirmSubmissionModal(true)} />
    );
  };

  const displayWithOrWithoutSaveForCompleteButton = (): JSX.Element => {
    return activity.isDraftVersion ? (
      <Button
        hasNoPadding={hasNoCompleteButtonPadding}
        size="small"
        text="Save and Complete"
        onClick={() => completeActivity()}
      />
    ) : (
      <Button size="small" text="Complete" onClick={() => setShowCompleteActivityModal(true)} />
    );
  };

  const displayCompleteOrSubmitButton = (): JSX.Element => {
    return activity.isManuallyApproved
      ? displayWithOrWithoutSaveForCompleteButton()
      : displayWithOrWithoutSaveForSubmitButton();
  };

  const displayButtonsForActivityStatus = (): JSX.Element => {
    return activity.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ? (
      displayCompleteOrSubmitButton()
    ) : (
      <Button size="small" text="View" onClick={activity.onView} />
    );
  };

  const displayButtonsForUsersWithManageAccess = (): JSX.Element => {
    return activity.completionPercentage === 100 && activity.isActivityDataValid ? (
      <>
        {displayButtonsForActivityStatus()}
        <div
          ref={(el) => {
            if (!el) return;
            setDropdownOffsetX(el.getBoundingClientRect().left);
            setDropdownOffsetY(el.getBoundingClientRect().top);
          }}
        >
          <Button
            size="small"
            text="Download"
            loadingText="Downloading"
            isLoading={isDocumentDownloading}
            icon={<DownwardArrowLightIcon />}
            onClick={() => setShowDownloadDropdown(true)}
          />
        </div>
        {activity.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS && (
          <button className="BtnLink body1" onClick={activity.onEdit}>
            Edit
          </button>
        )}
      </>
    ) : (
      <Button size="small" text="Resume" onClick={activity.onResume} />
    );
  };

  // TODO: KAN-5060 This may change when we introduce new permissions for different user types
  const displayButtonsForUsersWithReadAccess = (): JSX.Element | undefined => {
    return activity.isCurrentVersion || activity.isDraftVersion ? (
      <>
        <Button size="small" text="View" onClick={activity.onView} />
        <div
          ref={(el) => {
            if (!el) return;
            setDropdownOffsetX(el.getBoundingClientRect().left);
            setDropdownOffsetY(el.getBoundingClientRect().top);
          }}
        >
          <Button
            size="small"
            text="Download"
            loadingText="Downloading"
            isLoading={isDocumentDownloading}
            icon={<DownwardArrowLightIcon />}
            onClick={() => setShowDownloadDropdown(true)}
          />
        </div>
      </>
    ) : undefined;
  };
  return activity ? (
    <>
      <div className="ProgressIndicator">
        {renderAdditionalActivityIcon}
        <div className={isDisabled ? "Disabled" : "Enabled"}>
          <div className="ActivityStatusContainer">
            <p>{`Activity: ${activity.displayName}`}</p>
            <div className="ActivityStatusBar">
              <div className="ActivityStatusBarFilled" style={{ flex: activity.completionPercentage }} />
              <div className="ActivityStatusBarUnfilled" style={{ flex: 100 - activity.completionPercentage }} />
            </div>
            <p className="body2">{activity.completionPercentage}% complete</p>
          </div>
          <div className="ButtonContainer">
            {hasProjectPermission(ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY)
              ? displayButtonsForUsersWithManageAccess()
              : displayButtonsForUsersWithReadAccess()}
          </div>
        </div>
      </div>
      {showDownloadDropdown && (
        <div style={{ position: "fixed", left: dropdownOffsetX + 250, top: dropdownOffsetY + 22 }}>
          <ProfileMenu show={showDownloadDropdown} onClose={() => setShowDownloadDropdown(false)}>
            {downloadDropdownOptions.map((el) => (
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              <ProfileMenuItem key={el.id} profileMenuItem={el} onClick={el.action!} />
            ))}
          </ProfileMenu>
        </div>
      )}
      {showCompleteActivityModal && activity?.activityHistoryUuid && (
        <CompleteActivityModal
          show={showCompleteActivityModal}
          onClose={() => setShowCompleteActivityModal(false)}
          activityUuid={activity.uuid}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
        />
      )}
      {showConfirmSubmissionModal && activity?.activityHistoryUuid && (
        <ConfirmSubmissionModal
          show={showConfirmSubmissionModal}
          onClose={() => setShowConfirmSubmissionModal(false)}
          activityUuid={activity.uuid}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
        />
      )}
      {showDownloadActivityFilesModal && (
        <DownloadActivityFilesModal
          show={showDownloadActivityFilesModal}
          onClose={() => {
            setShowDownloadActivityFilesModal(false);
          }}
        />
      )}
    </>
  ) : (
    <div className="Empty ProgressIndicator">
      <p>There are currently no activities associated with this project</p>
    </div>
  );
};
