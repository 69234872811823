import { Message, Thread } from "../../../models";

interface useDiscussionThreadProps {
  internalThreads: Thread[];
}

interface useDiscussionThreadReturnData {
  firstMessagesInEachThread: Message[] | undefined;
  replies: Message[][];
}

export const useDiscussionThread = ({ internalThreads }: useDiscussionThreadProps): useDiscussionThreadReturnData => {
  return {
    firstMessagesInEachThread: internalThreads.map((subThread) => subThread.messages[0]),
    replies: internalThreads.map((subThread) => subThread.messages.slice(1)),
  };
};
