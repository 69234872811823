import "./ActivityProgressIndicator.css";

import { ProjectActivitiesConstants } from "../../../../../../../../constants";
import { WorkflowActivity } from "../../../../../../../../models/shared/workflowActivityType";
import { Button } from "../../../../../../../../widget";
import { CompleteActivityModal, ConfirmSubmissionModal } from "../../../../../../../developer/components";
import { useActivityProgressIndicator } from "./useActivityProgressIndicator";

interface ProgressIndicatorProps {
  activity: WorkflowActivity;
  hasNoSubmitButtonPadding: boolean;
  hasNoCompleteButtonPadding: boolean;
  canManageActivity: boolean;
  isDisabled?: boolean;
  noActivitiesMessage: string;
}

export const ActivityProgressIndicator = ({
  activity,
  hasNoSubmitButtonPadding,
  hasNoCompleteButtonPadding,
  canManageActivity,
  isDisabled = false,
  noActivitiesMessage,
}: ProgressIndicatorProps): JSX.Element => {
  const {
    showConfirmSubmissionModal,
    setShowConfirmSubmissionModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    submitActivity,
    completeActivity,
  } = useActivityProgressIndicator({
    isVersionConflict: activity?.isVersionConflict,
  });

  const displayWithOrWithoutSaveForSubmitButton = (): JSX.Element => {
    return activity.isDraftVersion ? (
      <Button
        hasNoPadding={hasNoSubmitButtonPadding}
        size="small"
        text="Save and Submit"
        onClick={() => submitActivity()}
      />
    ) : (
      <Button size="small" text="Submit" onClick={() => setShowConfirmSubmissionModal(true)} />
    );
  };

  const displayWithOrWithoutSaveForCompleteButton = (): JSX.Element => {
    return activity.isDraftVersion ? (
      <Button
        hasNoPadding={hasNoCompleteButtonPadding}
        size="small"
        text="Save and Complete"
        onClick={() => completeActivity()}
      />
    ) : (
      <Button size="small" text="Complete" onClick={() => setShowCompleteActivityModal(true)} />
    );
  };

  const displayCompleteOrSubmitButton = (): JSX.Element => {
    return activity.isManuallyApproved
      ? displayWithOrWithoutSaveForCompleteButton()
      : displayWithOrWithoutSaveForSubmitButton();
  };

  const displayButtonsForActivityStatus = (): JSX.Element => {
    return activity.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS ? (
      <>
        {displayCompleteOrSubmitButton()}
        <button className="BtnLink body1" onClick={activity.onEdit}>
          Edit
        </button>
      </>
    ) : (
      <Button size="small" text="View" onClick={activity.onView} />
    );
  };

  const displayButtonsForUsersWithManageAccess = (): JSX.Element => {
    return activity.completionPercentage === 100 && activity?.isActivityDataValid ? (
      displayButtonsForActivityStatus()
    ) : (
      <Button size="small" text="Resume" onClick={activity.onResume} />
    );
  };

  // TODO: KAN-5060 This may change when we introduce new permissions for different user types
  const conditionalViewButton = (): JSX.Element | undefined => {
    return activity.isCurrentVersion || activity.isDraftVersion ? (
      <Button size="small" text="View" onClick={activity.onView} />
    ) : undefined;
  };

  return activity ? (
    <>
      <div className="ActivityProgressIndicator">
        <div className={isDisabled ? "Disabled" : "Enabled"}>
          <div className="ActivityStatusContainer">
            <p>{`Activity: ${activity.displayName}`}</p>
            <div className="ActivityStatusBar">
              <div className="ActivityStatusBarFilled" style={{ flex: activity.completionPercentage }} />
              <div className="ActivityStatusBarUnfilled" style={{ flex: 100 - activity.completionPercentage }} />
            </div>
            <p className="body2">{activity.completionPercentage}% complete</p>
          </div>
          <div className="ButtonContainer">
            {canManageActivity ? displayButtonsForUsersWithManageAccess() : conditionalViewButton()}
          </div>
        </div>
      </div>
      {showCompleteActivityModal && activity?.activityHistoryUuid && (
        <CompleteActivityModal
          show={showCompleteActivityModal}
          onClose={() => setShowCompleteActivityModal(false)}
          activityUuid={activity.uuid}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
        />
      )}
      {showConfirmSubmissionModal && activity?.activityHistoryUuid && (
        <ConfirmSubmissionModal
          show={showConfirmSubmissionModal}
          onClose={() => setShowConfirmSubmissionModal(false)}
          activityUuid={activity.uuid}
          activityHistoryUuid={activity?.activityHistoryUuid}
          activityHistoryRowVersion={activity?.activityHistoryRowVersion}
        />
      )}
    </>
  ) : (
    <div className="Empty ProgressIndicator">
      <p>{noActivitiesMessage}</p>
    </div>
  );
};
