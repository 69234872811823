import { ErrorTemplate } from "../../../templates";

const Error404 = (): JSX.Element => {
  return (
    <ErrorTemplate
      errorText="We can’t seem to find the page you were looking for. It may no longer exist, have changed location or be temporarily unavailable."
      errorCode="404"
    />
  );
};

export default Error404;
