import { FileType } from "../../../../models";
import { DeveloperProjectCardWithTabsTemplate } from "../../../../templates/DeveloperProjectCardWithTabsTemplate";
import { useProject } from "./useProject";

const Project = (): JSX.Element => {
  const { projectDetails, handleBackClick, tabsList } = useProject();

  return (
    <DeveloperProjectCardWithTabsTemplate
      activeElement="Projects"
      pageTitle={projectDetails?.displayName}
      projectCode={projectDetails?.standard.displayName}
      projectDeveloper={projectDetails?.developer.displayName}
      projectDeveloperIcon={projectDetails?.developer.files.find((f) => f.type === FileType.SmallLogo)?.file.url}
      projectStatus={projectDetails?.status}
      projectUuid={projectDetails?.uuid}
      chartData={{
        "Pending issuance units (PIUs)": projectDetails?.cachedPiuQuantity ?? 0,
        "Verified carbon units (VCUs)": projectDetails?.cachedVcuQuantity ?? 0,
      }}
      tabsList={tabsList}
      backButtonText="Back to projects"
      handleBackClick={handleBackClick}
    />
  );
};

export default Project;
