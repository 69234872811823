import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants, ProjectActivitiesConstants, ProjectPermissionConstants } from "../../constants";
import { WorkflowActivity } from "../../models/shared/workflowActivityType";
import { isActivityDataValid } from "../../route/developer/activities";
import { ProjectContext } from "../../route/shared/projects/project/ProjectContext";
import { createActivityDraft, resumeActivity, versionConflictModalGotDisplayed } from "../../route/shared/utils";
import {
  getActivityHistoryDetails,
  searchActivities,
  searchActivityHistory,
  SearchActivityHistoryResponse,
} from "../../service/query";
import { Status } from "../../service/Shared";
import { useAuth } from "../../useAuth";
import { getActivityViewRoute, getProjectAuditHistoryRoute, getPublicProjectOverviewRoute } from "../../utils/routes";
import { dataGridMapFilterCriteria, ProfileMenuItemType } from "../../widget";
import toast from "../../widget/general/Toast/Toast";

interface UseDeveloperProjectCardWithTabsTemplateReturnData {
  quickActionsMenuOptionsList: ProfileMenuItemType[];
  setShowQuickActionMenu: Dispatch<SetStateAction<boolean>>;
  showQuickActionMenu: boolean;
  setShowMergeProjectsModal: Dispatch<SetStateAction<boolean>>;
  showMergeProjectsModal: boolean;
  setShowPublishProjectModal: Dispatch<SetStateAction<boolean>>;
  showPublishProjectModal: boolean;
  setShowDeleteProjectModal: Dispatch<SetStateAction<boolean>>;
  showDeleteProjectModal: boolean;
  setShowMultiDraftModal: Dispatch<SetStateAction<boolean>>;
  showMultiDraftModal: boolean;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  draftActivityHistory?: SearchActivityHistoryResponse;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  isProjectAssociatedWithCode: boolean;
  workflowActivities?: WorkflowActivity[];
  navigateToDraftActivity: () => void;
  resumeDraft: (uuid: string, draftActivityHistory: SearchActivityHistoryResponse) => void;
  hasProjectPermission: (permission: string) => boolean;
  currentUserType: OrganisationTypeConstants;
}

export const useDeveloperProjectCardWithTabsTemplate = (
  projectStatus: string | undefined,
  projectUuid: string | undefined
): UseDeveloperProjectCardWithTabsTemplateReturnData => {
  const navigate = useNavigate();

  const { currentUserType } = useAuth();
  const { hasProjectPermission, refreshActivities } = useContext(ProjectContext);

  const [showQuickActionMenu, setShowQuickActionMenu] = useState(false);
  const [showMergeProjectsModal, setShowMergeProjectsModal] = useState(false);
  const [showDeleteProjectModal, setShowDeleteProjectModal] = useState(false);
  const [showPublishProjectModal, setShowPublishProjectModal] = useState(false);
  const [workflowActivities, setWorkflowActivities] = useState<WorkflowActivity[]>();
  const [showMultiDraftModal, setShowMultiDraftModal] = useState(false);
  const [draftActivityHistory, setDraftActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [hasFetchedActivityHistory, setHasFetchedActivityHistory] = useState(false);
  // Version conflict modal
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();

  const quickActionsMenuOptionsList: ProfileMenuItemType[] = [];

  if (currentUserType === OrganisationTypeConstants.DEVELOPER) {
    quickActionsMenuOptionsList.push(
      {
        id: 1,
        value: "Match with existing project",
        action: () => setShowMergeProjectsModal(true),
      },
      {
        id: 2,
        value: "Delete project",
        action: () => setShowDeleteProjectModal(true),
      }
    );
  }

  if (currentUserType === OrganisationTypeConstants.DEVELOPER && projectStatus === "Draft") {
    quickActionsMenuOptionsList.push({
      id: 3,
      value: "Publish project",
      action: () => setShowPublishProjectModal(true),
    });
  }

  if (
    currentUserType === OrganisationTypeConstants.DEVELOPER &&
    hasProjectPermission(ProjectPermissionConstants.READ_PROJECT_AUDIT)
  ) {
    quickActionsMenuOptionsList.push({
      id: quickActionsMenuOptionsList.length + 1,
      value: "View audit history",
      action: () => navigate(getProjectAuditHistoryRoute(projectUuid || "", currentUserType)),
    });
  }

  if (
    currentUserType === OrganisationTypeConstants.ASSET_MANAGER ||
    currentUserType === OrganisationTypeConstants.DEVELOPMENT_MANAGER
  ) {
    quickActionsMenuOptionsList.push({
      id: quickActionsMenuOptionsList.length + 1,
      value: "View project in directory",
      action: () => navigate(getPublicProjectOverviewRoute(projectUuid || "")),
    });
  }

  const isProjectAssociatedWithCode = useMemo(() => {
    return !(projectStatus === "Draft" || projectStatus === "Pre-development");
  }, [projectStatus]);

  const resumeDraft = async (
    _draftActivityHistoryUuid: string,
    localDraftActivityHistory: SearchActivityHistoryResponse
  ): Promise<void> => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const activityHistory = localDraftActivityHistory.results.find((x) => x.uuid === _draftActivityHistoryUuid)!;

    await resumeActivity(
      navigate,
      setDraftActivityHistoryUuid,
      setNewVersionActivityHistory,
      setShowVersionConflictModal,
      activityHistory.activity.uuid,
      activityHistory.uuid,
      activityHistory.versionNumber
    );
  };

  const navigateToDraftActivity = async (): Promise<void> => {
    if (draftActivityHistory?.results) {
      if (draftActivityHistory.results.length > 1) {
        setShowMultiDraftModal(true);
      } else {
        await resumeActivity(
          navigate,
          setDraftActivityHistoryUuid,
          setNewVersionActivityHistory,
          setShowVersionConflictModal,
          draftActivityHistory.results[0].activity.uuid,
          draftActivityHistory.results[0].uuid,
          draftActivityHistory.results[0].versionNumber
        );
      }
    }
  };

  const fetchDraftActivities = useCallback(async () => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.activity = {
      project: {
        uuid: {
          operator: "eq",
          value: projectUuid,
        },
      },
    };

    filterCriteria.isDraft = {
      operator: "eq",
      value: true,
    };

    const response = await searchActivityHistory({
      activityUuid: null,
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 10,
      },

      filter: { results: filterCriteria },
    });

    if (response.status === Status.Success && response.data && response.data.results.length) {
      setDraftActivityHistory(response.data);
    }
  }, [refreshActivities]);

  const fetchWorkflowData = useCallback(async (): Promise<void> => {
    if (!projectUuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria([]);

    filterCriteria.project = {
      uuid: {
        operator: "eq",
        value: projectUuid,
      },
    };

    filterCriteria.status = {
      operator: "eq",
      value: ProjectActivitiesConstants.STATUS_IN_PROGRESS,
    };
    const response = await searchActivities({
      paging: {
        limit: 3,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "results.currentVersion.createdAt",
          direction: "desc",
        },
      ],
      filter: { results: filterCriteria },
    });

    if (response.status === Status.Success && response.data && response.data.results) {
      const mappedActivities = response.data.results.map((activity) => ({
        uuid: activity.uuid,
        activityHistoryUuid: activity?.draftVersion?.uuid || activity?.currentVersion?.uuid,
        activityHistoryRowVersion: activity?.draftVersion ? activity?.draftVersion.rowVersion : undefined,
        isDraftVersion: !!activity?.draftVersion,
        isCurrentVersion: activity?.draftVersion ? false : !!activity?.currentVersion,
        rowVersion: activity.rowVersion,
        status: activity.status,
        displayName: activity?.draftVersion
          ? `${activity.activityDefinition.displayName} (Draft)`
          : activity.activityDefinition.displayName,
        completionPercentage: Math.round(
          // eslint-disable-next-line no-nested-ternary
          activity.draftVersion?.completionPercentage
            ? activity.draftVersion.completionPercentage * 100
            : activity.currentVersion?.completionPercentage
            ? activity.currentVersion.completionPercentage * 100
            : 0
        ),
        isManuallyApproved: activity.currentVersion
          ? activity.currentVersion?.activityDefinitionVersion?.isManuallyApproved
          : activity.draftVersion?.activityDefinitionVersion?.isManuallyApproved,
        isActivityDataValid: false,
        isVersionConflict: async () => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const activityHistory = draftActivityHistory!.results.find((x) => x.uuid === activity?.draftVersion?.uuid)!;

          return versionConflictModalGotDisplayed(
            setDraftActivityHistoryUuid,
            setNewVersionActivityHistory,
            setShowVersionConflictModal,
            activityHistory.activity.uuid,
            activityHistory.uuid,
            activityHistory.versionNumber
          );
        },
        onEdit: () =>
          // eslint-disable-next-line no-nested-ternary
          activity?.draftVersion && draftActivityHistory
            ? resumeDraft(activity.draftVersion.uuid, draftActivityHistory)
            : activity?.currentVersion
            ? createActivityDraft(activity.uuid, navigate)
            : null,
        onView: () =>
          activity?.currentVersion
            ? navigate(
                getActivityViewRoute(activity.currentVersion.uuid, currentUserType, `projectUuid=${projectUuid}`)
              )
            : null,
        onResume: () =>
          // eslint-disable-next-line no-nested-ternary
          activity?.draftVersion && draftActivityHistory
            ? resumeDraft(activity.draftVersion.uuid, draftActivityHistory)
            : activity?.currentVersion
            ? createActivityDraft(activity.uuid, navigate)
            : toast.error({
                message: `This activity has not yet been published. Once ${activity.createdByUser.fullName} has published the activity, you can begin editing.`,
              }),
        onDownload: () => null,
      }));

      // Refactor opportunity
      // We need to fetch the activity data for each activity in order to compute the isActivityDataValid
      // would be better to return it from the BE
      for (let index = 0; index < response.data.results.length; index++) {
        const activityHistoryUuid =
          response.data.results[index]?.draftVersion?.uuid || response.data.results[index]?.currentVersion?.uuid;

        if (activityHistoryUuid) {
          // eslint-disable-next-line no-await-in-loop
          const historyDetailsRes = await getActivityHistoryDetails({ activityHistoryUuid });

          if (historyDetailsRes.status === Status.Success && historyDetailsRes.data) {
            mappedActivities[index].isActivityDataValid = isActivityDataValid(historyDetailsRes.data.data.steps);
          }
        }
      }

      setWorkflowActivities(mappedActivities);
    }
  }, [draftActivityHistory]);

  useEffect(() => {
    fetchDraftActivities().finally(() => setHasFetchedActivityHistory(true));
  }, [fetchDraftActivities, refreshActivities]);

  useEffect(() => {
    if (hasFetchedActivityHistory) {
      fetchWorkflowData().finally(() => setHasFetchedActivityHistory(false));
    }
  }, [fetchWorkflowData, hasFetchedActivityHistory]);

  return {
    quickActionsMenuOptionsList,
    setShowQuickActionMenu,
    showQuickActionMenu,
    setShowMergeProjectsModal,
    showMergeProjectsModal,
    setShowPublishProjectModal,
    showPublishProjectModal,
    setShowDeleteProjectModal,
    showDeleteProjectModal,
    setShowMultiDraftModal,
    showMultiDraftModal,
    setShowVersionConflictModal,
    showVersionConflictModal,
    draftActivityHistory,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    isProjectAssociatedWithCode,
    navigateToDraftActivity,
    workflowActivities,
    resumeDraft,
    hasProjectPermission,
    currentUserType,
  };
};
