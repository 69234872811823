import {
  ChartsLightIcon,
  DashboardLightIcon,
  DocumentsLightIcon,
  EditLightIcon,
  KanaLightLogo,
  KanaSeedLogo,
  PoweredByKanaLogo,
  SettingsLightIcon,
  WatchlistGreyIcon,
  WorkflowGreyIcon,
} from "../../../assets";
import { OrganisationRoleConstants, OrganisationTypeConstants, UserType } from "../../../constants";
import { useAuth } from "../../../useAuth";
import {
  getActivitiesRoute,
  getDashboardRoute,
  getInsightsRoute,
  getProjectsRoute,
  getSettingsRoute,
  getWatchlistRoute,
  getWorkflowsRoute,
} from "../../../utils/routes";
import { NavigationActions } from "./models";

interface useLeftNavigationReturnData {
  navigationLogo: JSX.Element;
  navigationFooter?: JSX.Element;
  navigationActions: NavigationActions;
  currentUserType: UserType;
}

export const useLeftNavigation = (): useLeftNavigationReturnData => {
  const { currentUserType, currentUserRole } = useAuth();

  const navigationLogo = {
    Developer: <KanaLightLogo className="NavigationLogo" />,
    Verifier: <KanaLightLogo className="NavigationLogo" />,
    DevelopmentManager: <KanaLightLogo className="NavigationLogo" />,
    AssetManager: <KanaSeedLogo className="SeedLogo" />,
  };

  const navigationActions = {
    Developer: [
      {
        icon: <DashboardLightIcon />,
        route: getDashboardRoute(OrganisationTypeConstants.DEVELOPER),
        tooltipText: "Dashboard",
      },
      {
        icon: <DocumentsLightIcon />,
        route: getProjectsRoute(OrganisationTypeConstants.DEVELOPER),
        tooltipText: "Projects",
      },
      // {
      //   icon: <ChartsLightIcon />,
      //   route: "/d/marketing/marketing-stats",
      //   tooltipText: "Stats",
      // },
      {
        icon: <EditLightIcon />,
        route: getActivitiesRoute(OrganisationTypeConstants.DEVELOPER),
        tooltipText: "Activities",
      },
    ],
    Verifier: [
      {
        icon: <SettingsLightIcon />,
        route: getSettingsRoute(OrganisationTypeConstants.VERIFIER),
        tooltipText: "Settings",
      },
    ],
    DevelopmentManager: [
      {
        icon: <DashboardLightIcon />,
        route: getDashboardRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER),
        tooltipText: "Dashboard",
      },
      {
        icon: <DocumentsLightIcon />,
        route: getProjectsRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER),
        tooltipText: "Projects",
      },
      {
        icon: <EditLightIcon />,
        route: getActivitiesRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER),
        tooltipText: "Activities",
      },
      {
        icon: <SettingsLightIcon />,
        route: getSettingsRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER),
        tooltipText: "Settings",
      },
    ],
    AssetManager: [
      {
        icon: <DashboardLightIcon className="DashboardLightIcon" />,
        route: getDashboardRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Dashboard",
      },
      {
        icon: <DocumentsLightIcon />,
        route: getProjectsRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Projects",
      },
      {
        icon: <WorkflowGreyIcon className="WorkflowGreyIcon" />,
        route: getWorkflowsRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Workflows",
        isDisabled: true,
      },
      {
        icon: <EditLightIcon />,
        route: getActivitiesRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Activities",
      },
      {
        icon: <ChartsLightIcon />,
        route: getInsightsRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Insights",
        isDisabled: true,
      },
      {
        icon: <WatchlistGreyIcon className="WatchlistGreyIcon" />,
        route: getWatchlistRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Watchlist",
        isDisabled: true,
      },
      {
        icon: <SettingsLightIcon />,
        route: getSettingsRoute(OrganisationTypeConstants.ASSET_MANAGER),
        tooltipText: "Settings",
      },
    ],
  };

  if (currentUserRole === OrganisationRoleConstants.OWNER || currentUserRole === OrganisationRoleConstants.ADMIN) {
    navigationActions.Developer.push({
      icon: <SettingsLightIcon />,
      route: getSettingsRoute(OrganisationTypeConstants.DEVELOPER),
      tooltipText: "Settings",
    });
  }

  return {
    navigationLogo: navigationLogo[currentUserType],
    navigationActions: navigationActions[currentUserType],
    currentUserType,
    navigationFooter:
      currentUserType === OrganisationTypeConstants.ASSET_MANAGER ? (
        <PoweredByKanaLogo className="NavigationFooter" />
      ) : undefined,
  };
};
