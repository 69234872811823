import { RefObject, useEffect, useRef, useState } from "react";

import { useClickOutside } from "../../../utils";
import { Thread } from "../models";

interface useDiscussionProps {
  internalThreads: Thread[];
  externalThreads: Thread[];
  closeDiscussion: () => void;
}

interface useDiscussionReturnData {
  discussionRef: RefObject<HTMLDivElement>;
  showNewDiscussion: boolean;
  internalTeamSelected: boolean;
  developerSelected: boolean;
  handleInternalTeamSelect: () => void;
  handleDeveloperSelect: () => void;
  handleCancel: () => void;
}

export const useDiscussion = ({
  internalThreads,
  externalThreads,
  closeDiscussion,
}: useDiscussionProps): useDiscussionReturnData => {
  const [newDiscussionFromThread, setNewDiscussionFromThread] = useState<boolean>(false);
  const [internalTeamSelected, setInternalTeamSelected] = useState<boolean>(false);
  const [developerSelected, setDeveloperSelected] = useState<boolean>(false);

  const discussionRef = useRef<HTMLDivElement>(null);

  useClickOutside(discussionRef, () => {
    closeDiscussion();
  });

  const handleInternalTeamSelect = (): void => {
    setInternalTeamSelected(true);
    setDeveloperSelected(false);
  };

  const handleDeveloperSelect = (): void => {
    setDeveloperSelected(true);
    setInternalTeamSelected(false);
  };

  const handleCancel = (): void => {
    if (newDiscussionFromThread) {
      setNewDiscussionFromThread(false);
    } else {
      setInternalTeamSelected(false);
      setDeveloperSelected(false);
    }
  };

  useEffect(() => {
    if (externalThreads.length && !internalThreads.length) {
      handleDeveloperSelect();
    } else {
      handleInternalTeamSelect();
    }
  }, []);

  return {
    discussionRef,
    showNewDiscussion: newDiscussionFromThread || (!internalThreads.length && !externalThreads.length),
    internalTeamSelected,
    developerSelected,
    handleInternalTeamSelect,
    handleDeveloperSelect,
    handleCancel,
  };
};
