import { Button, Column, ErrorBox, Modal, Row, TextArea } from "../../../../../../widget";
import { useNewDiscussionModal } from "./useNewDiscussionModal";

interface NewDiscussionModalProps {
  show: boolean;
  closeModal: () => void;
}

export const NewDiscussionModal = ({ show, closeModal }: NewDiscussionModalProps): JSX.Element => {
  const { message, setMessage, errors, handleCloseModal, handleCreateThread, isHandleCreateThreadLoading } =
    useNewDiscussionModal(closeModal);

  return (
    <Modal show={show} title="New internal discussion" onClose={handleCloseModal} hasFullWidthTitle>
      <Row spacingV="xl" justify="center">
        <Column span={12}>
          <p>Create a New discussion which is not linked to a specific field</p>
        </Column>
      </Row>
      <Row spacingV="xl" justify="center">
        <Column span={9}>
          {errors && errors.length > 0 && (
            <Row spacingV="ml">
              <Column span={12}>
                <ErrorBox
                  title="Please solve the below issues before creating the thread"
                  text={errors.map((error) => error.message)}
                />
              </Column>
            </Row>
          )}
          <TextArea
            name="message"
            displayCharacterCount
            maxLength={500}
            label="Message"
            value={message}
            onChange={setMessage}
          />
        </Column>
      </Row>
      <Row justify="center">
        <Column span={6}>
          <Button fullWidth text="Send" onClick={handleCreateThread} isLoading={isHandleCreateThreadLoading} />
        </Column>
      </Row>
    </Modal>
  );
};
