import { ProjectActivitiesConstants } from "../../../../../constants";
import { downloadActivityDocument } from "../../../../../service/activity";
import { searchActivityDocuments } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { initiateDocumentDownloadViaBrowser } from "../../../../../utils";
import { DataGridButtonLinkCellFormatterData, DataGridIconCellFormatterData } from "../../../../../widget";

interface FormatActivityDocumentHistoryDownloadLinkProps {
  activityDocumentHistoryUuid: string;
  isGeneratedDocument?: boolean;
}

export const downloadActivityDocumentToDesktop = async (
  activityDocumentHistoryUuid: string,
  mimeType?: string
): Promise<void> => {
  const response = await downloadActivityDocument({ activityDocumentHistoryUuid, mimeType });
  initiateDocumentDownloadViaBrowser(response);
};

export const previewActivityDocument = async (
  activityDocumentHistoryUuid: string,
  mimeType?: string
): Promise<void> => {
  const response = await downloadActivityDocument({ activityDocumentHistoryUuid, mimeType });
  initiateDocumentDownloadViaBrowser(response, true);
};

export const formatActivityDocumentHistoryDownloadLink = ({
  activityDocumentHistoryUuid,
  isGeneratedDocument = false,
}: FormatActivityDocumentHistoryDownloadLinkProps): DataGridIconCellFormatterData =>
  ({
    action: () => {
      downloadActivityDocumentToDesktop(
        activityDocumentHistoryUuid,
        isGeneratedDocument ? ProjectActivitiesConstants.GENERATED_DOCUMENT_MIME_TYPE : undefined
      );
    },
  }) as DataGridButtonLinkCellFormatterData;

interface DownloadGeneratedDocumentProps {
  activityHistoryUuid: string;
  currentOnly?: boolean;
}

export const downloadGeneratedDocument = async ({
  activityHistoryUuid,
  currentOnly = false,
}: DownloadGeneratedDocumentProps): Promise<void> => {
  await searchActivityDocuments({
    filter: {
      results: {
        activityDefinitionDocumentType: {
          isGenerated: {
            operator: "eq",
            value: true,
          },
        },
      },
    },
    currentOnly,
    activityDocumentHistoryUuid: null,
    activityHistoryUuid,
    activityUuid: null,
    paging: {
      afterCursor: null,
      beforeCursor: null,
      limit: 10,
    },
  }).then(async (searchRes) => {
    if (searchRes.status === Status.Success && searchRes.data && searchRes.data.results.length > 0) {
      await downloadActivityDocumentToDesktop(
        searchRes.data.results[0].activityDocument.uuid,
        ProjectActivitiesConstants.GENERATED_DOCUMENT_MIME_TYPE
      );
    } else {
      throw new Error(`Could not find a generated document for activity history with uuid '${activityHistoryUuid}'`);
    }
  });
};
