import { DeveloperTemplate } from "../../../templates";
import { Error500Page } from "../../shared/errors";

export const DeveloperError500 = (): JSX.Element => {
  return (
    <DeveloperTemplate>
      <Error500Page />
    </DeveloperTemplate>
  );
};
