import { Column, CursorDataGrid, Row } from "../../../../widget";
import { useDocuments } from "./useDocuments";

export const Documents = (): JSX.Element => {
  const { columns, defaultSortingCriteria, dataIsLoading, onChange, refresh } = useDocuments();

  return (
    <div className="DeveloperProjectTabSection">
      <Row spacingV="ll">
        <Column span={12}>
          <h2>Activity Documents</h2>
        </Column>
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        refresh={refresh}
        noDataMessage="No documents to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
      />
    </div>
  );
};
