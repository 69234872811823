import "./DeveloperIcon.css";

export interface DeveloperIconProps {
  developerIcon: string;
}

export const DeveloperIcon = ({ developerIcon }: DeveloperIconProps): JSX.Element => {
  return (
    <img key={developerIcon} aria-labelledby="icon" className="DeveloperIcon" src={developerIcon} alt="DeveloperIcon" />
  );
};
