import "../styles/DataGrid.css";

import ReactDataGrid, { SortColumn } from "react-data-grid";

import { ResultData } from "../../../../service/Shared";
import { Button, Toggle } from "../../../forms";
import { Column, Row } from "../../../layout";
import { CursorPagination } from "../../../wayfinding";
import { DataGridConstants } from "../constants";
import { FilterContext } from "../context";
import { ColumnDefinition, OnCursorChangeEventHandler } from "../models";
import { useComputeDataGridHeight } from "../utils";
import { useCursorDataGrid } from "./useCursorDataGrid";

export interface CursorDataGridProps {
  data: ResultData[];
  columns: ColumnDefinition[];
  // client-side filtering & sorting
  clientSide: boolean;
  // grid-level sorting enabled?
  sortable?: boolean;
  // grid-level filtering enabled?
  filterable?: boolean;
  // paging enabled?
  pageable?: boolean;
  // show total count enabled?
  showTotalCount?: boolean;
  /*
   Used for server-side sorting/filtering/paging.
   Only raised if `clientSide = false`.
   */
  onChange?: OnCursorChangeEventHandler;
  /*
    Configuration for the pagination
   */
  pagination?: {
    // mandatory for server-side
    totalCount?: number;
    pageSize?: number;
    startCursor?: string;
    endCursor?: string;
  };
  noDataMessage?: string;
  dataIsLoading?: boolean;
  defaultSortingCriteria?: SortColumn[];
  refresh?: boolean;
  triggerPagePrevious?: boolean;
  onRowClick?: (e: ResultData) => void;
  showFilterToggle?: boolean;
  // show export button
  exportable?: boolean;
  exportFileName?: string;
}

export const CursorDataGrid = ({
  data,
  columns,
  filterable = false,
  sortable = true,
  clientSide = false,
  pageable = true,
  showTotalCount = true,
  defaultSortingCriteria,
  onChange,
  pagination = { pageSize: 10 },
  noDataMessage,
  dataIsLoading,
  refresh,
  onRowClick,
  showFilterToggle,
  triggerPagePrevious,
  exportable = false,
  exportFileName,
}: CursorDataGridProps): JSX.Element => {
  const {
    resultData,
    filterCriteria,
    setFilterCriteria,
    rdgColumns,
    sortColumns,
    onSort,
    onPrevious,
    onNext,
    hasNext,
    hasPrevious,
    totalCount,
    filtersEnabled,
    setFiltersEnabled,
    showFilterToggleSwitch,
    onExport,
  } = useCursorDataGrid({
    data,
    columns,
    filterable,
    sortable,
    clientSide,
    pageable,
    defaultSortingCriteria,
    onChange,
    pagination,
    refresh,
    showFilterToggle,
    triggerPagePrevious,
    exportFileName,
  });

  const { computedHeight } = useComputeDataGridHeight(resultData.length, DataGridConstants.rowHeight);

  const exportButtons = (): JSX.Element => {
    const colCount = showFilterToggleSwitch ? 4 : 3;
    return (
      <Row spacingV="s" className="ExportButtonsContainer">
        <Column span={colCount}>
          <Button text="Export all" onClick={onExport} />
        </Column>
      </Row>
    );
  };

  const filterButtons = (): JSX.Element => {
    const colCount = showFilterToggleSwitch ? 4 : 3;
    return (
      <Row spacingV="s">
        <Column span={colCount} className="FilterButtonsContainer">
          {showFilterToggleSwitch && (
            <Toggle
              label={`${filtersEnabled ? "Hide filters" : "Show filters"}`}
              value={filtersEnabled}
              onChange={() => {
                if (filtersEnabled) {
                  setFilterCriteria([]);
                }
                setFiltersEnabled(!filtersEnabled);
              }}
            />
          )}
          {filterCriteria.length > 0 && (
            <button
              className="BtnLink"
              onClick={() => {
                setFilterCriteria([]);
              }}
            >
              Clear all filters
            </button>
          )}
        </Column>
      </Row>
    );
  };

  return (
    <div className={`DataGridContainer${onRowClick ? " ClickableRows" : ""}`}>
      {resultData && (!!resultData.length || (!resultData.length && !!filterCriteria.length)) ? (
        <>
          {exportable && exportButtons()}
          {filterable && filterButtons()}
          <div className={`DataGrid ${filtersEnabled ? "FiltersApplied" : ""}`}>
            <Row spacingV={pageable ? "ml" : undefined}>
              <Column span={12}>
                <FilterContext.Provider value={filterCriteria}>
                  <ReactDataGrid
                    style={{
                      height: computedHeight,
                    }}
                    rows={resultData}
                    columns={rdgColumns}
                    defaultColumnOptions={{
                      sortable,
                    }}
                    className="rdg-light"
                    rowHeight={DataGridConstants.rowHeight}
                    headerRowHeight={filterable && filtersEnabled ? 2 * DataGridConstants.rowHeight : undefined}
                    sortColumns={sortColumns}
                    onSortColumnsChange={onSort}
                    onRowClick={onRowClick}
                  />
                </FilterContext.Provider>
              </Column>
            </Row>
            {showTotalCount && (
              <Row spacingV={pageable ? "ml" : undefined}>
                <div className="DataGridResultCount">
                  <span className="body2">{totalCount} results</span>
                </div>
              </Row>
            )}
            <Row>
              <Column span={12}>
                {pageable && (
                  <CursorPagination
                    onPrevious={onPrevious}
                    onNext={onNext}
                    hasNext={hasNext}
                    hasPrevious={hasPrevious}
                  />
                )}
              </Column>
            </Row>
          </div>
        </>
      ) : (
        <p className="body2 NoData">{dataIsLoading ? "Loading..." : noDataMessage}</p>
      )}
    </div>
  );
};
