import { OrganisationPermissionConstants } from "../../../constants";
import { VerifierSingleCardWithTabsTemplate } from "../../../templates";
import { useAuth } from "../../../useAuth";

const Settings = (): JSX.Element => {
  const { hasPermission } = useAuth();

  return (
    <div>
      <VerifierSingleCardWithTabsTemplate
        activeElement="Settings"
        pageTitle="Settings"
        tabsList={
          hasPermission(OrganisationPermissionConstants.READ_ORGANISATION_USERS)
            ? [
                { route: "/v/settings/users", label: "Users" },
                { route: "/v/settings/organisation", label: "Organisation" },
                {
                  route: "/v/settings/marketing-assets",
                  label: "Marketing Assets",
                },
              ]
            : [
                { route: "/v/settings/organisation", label: "Organisation" },
                {
                  route: "/v/settings/marketing-assets",
                  label: "Marketing Assets",
                },
              ]
        }
      />
    </div>
  );
};

export default Settings;
