import "./ChartLegend.css";

import { thousandsFormatter } from "../../../utils";
import { ChartProps } from "../models";

type ChartLegendVariant = "Primary" | "Secondary";

interface ChartLegendProps extends ChartProps {
  colours: string[];
  hasData: boolean;
  variant?: ChartLegendVariant;
}

export const ChartLegend = ({ data, colours, hasData, variant = "Primary" }: ChartLegendProps): JSX.Element => {
  return (
    <div className="ChartLegend">
      <table>
        <tbody>
          {Object.keys(data).map((key, idx) => {
            return (
              <tr key={key}>
                {variant === "Primary" && (
                  <td>
                    <div className="ChartLegendBox" style={{ backgroundColor: colours[idx] }} />
                  </td>
                )}
                <td
                  className={hasData ? "ChartLegendValue" : "ChartLegendNoValue"}
                  style={variant === "Secondary" ? { color: colours[idx] } : undefined}
                >
                  {hasData ? thousandsFormatter.format(data[key]).toLowerCase() : null}
                </td>
                <td>{key}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
