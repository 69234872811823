import "./Discussion.css";

import { Dispatch, SetStateAction } from "react";

import { Thread } from "../models";
import { DiscussionThread, NewDiscussion } from "./components";
import { NewThreadInformation } from "./components/NewDiscussion/useNewDiscussion";
import { useDiscussion } from "./useDiscussion";

interface DiscussionProps {
  internalThreads: Thread[];
  externalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  usedInDeveloperPortal: boolean;
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  isForViewMode?: boolean;
}

export const Discussion = ({
  internalThreads,
  externalThreads,
  setInternalThreads,
  usedInDeveloperPortal = false,
  closeDiscussion,
  newThreadInformation,
  isForViewMode = false,
}: DiscussionProps): JSX.Element => {
  const {
    discussionRef,
    showNewDiscussion,
    internalTeamSelected,
    developerSelected,

    handleInternalTeamSelect,
    handleDeveloperSelect,
    handleCancel,
  } = useDiscussion({ internalThreads, externalThreads, closeDiscussion });

  return (
    <div ref={discussionRef} className="DiscussionContainer">
      <div className={`DiscussionCard ${showNewDiscussion ? "VisibleOverflow" : ""}`}>
        {showNewDiscussion ? (
          <NewDiscussion
            internalThreads={internalThreads}
            setInternalThreads={setInternalThreads}
            internalTeamSelected={internalTeamSelected}
            developerSelected={developerSelected}
            handleInternalTeamSelect={handleInternalTeamSelect}
            handleDeveloperSelect={handleDeveloperSelect}
            handleCancel={handleCancel}
            usedInDeveloperPortal={usedInDeveloperPortal}
            closeDiscussion={closeDiscussion}
            newThreadInformation={newThreadInformation}
          />
        ) : (
          <DiscussionThread
            usedInDeveloperPortal
            internalThreads={internalThreads}
            externalThreads={externalThreads}
            setInternalThreads={setInternalThreads}
            internalTeamSelected={internalTeamSelected}
            developerSelected={developerSelected}
            handleInternalTeamSelect={handleInternalTeamSelect}
            handleDeveloperSelect={handleDeveloperSelect}
            closeDiscussion={closeDiscussion}
            newThreadInformation={newThreadInformation}
            isForViewMode={isForViewMode}
          />
        )}
      </div>
    </div>
  );
};
