import { ErrorTemplate } from "../../../templates";

const Error500 = (): JSX.Element => {
  return (
    <ErrorTemplate
      errorText="Something went wrong. The server encountered an error and could not complete your request. Refresh the page or try again later."
      errorCode="500"
    />
  );
};

export default Error500;
