import "./GenericModal.css";

import { Button, Column, Modal, Row } from "../../../../widget";

export interface GenericModalData {
  title: string;
  text: string[];
  confirmText: string;
  confirmOptionalText?: string;
  cancelText: string;
}

const defaultData: GenericModalData = {
  title: "Confirmation",
  text: ["Are you sure you want to proceed?"],
  confirmText: "Yes",
  cancelText: "Cancel",
};

interface GenericModalProps {
  show: boolean;
  onConfirm: () => void;
  onConfirmOptional?: () => void;
  closeModal: () => void;
  data?: GenericModalData;
}

export const GenericModal = ({
  show,
  onConfirm,
  onConfirmOptional,
  closeModal,
  data = defaultData,
}: GenericModalProps): JSX.Element => {
  return (
    <Modal show={show} title={data.title} onClose={closeModal}>
      <Row justify="center" spacingV="2xl">
        <Column span={12}>
          {data.text.map((textPart, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={idx} className="body1">
              {textPart}
            </p>
          ))}
        </Column>
      </Row>
      <Row justify="center">
        <Column span={12}>
          <div className="GenericModalActions">
            <div className="GenericModalConfirmActions">
              <Button text={data.confirmText} onClick={onConfirm} />
              {onConfirmOptional && data.confirmOptionalText && (
                <Button text={data.confirmOptionalText} variant="tertiary" onClick={onConfirmOptional} />
              )}
            </div>
            <Button variant="secondary" text={data.cancelText} onClick={closeModal} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
