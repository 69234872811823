import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ActivityPageParams } from "../../../../../models";
import { ActivityWizardContext } from "../../../../../route/developer/activities";
import { createDiscussionThread } from "../../../../../service/discussion";
import { createDiscussionMessage } from "../../../../../service/discussion/DiscussionService.full";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getUserFullName, useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../general";
import { Thread } from "../../../models";
import { NewThreadInformation } from "../NewDiscussion/useNewDiscussion";

interface UseDiscussionThreadBodyReturnData {
  currentUserUuid: string | undefined;

  newDiscussionErrors: ServiceError[] | undefined;
  showNewDiscussionTextArea: boolean;
  setShowNewDiscussionTextArea: Dispatch<SetStateAction<boolean>>;
  newDiscussion: string;
  setNewDiscussion: Dispatch<SetStateAction<string>>;
  handleCloseNewDiscussionContainer: () => void;
  handleSendDiscussion: () => void;

  replyErrors: ServiceError[] | undefined;
  showReplyTextArea: boolean;
  newReply: string;
  setNewReply: Dispatch<SetStateAction<string>>;
  setShowReplyTextArea: Dispatch<SetStateAction<boolean>>;
  handleSendReply: (threadUuid: string) => void;
  handleCloseReplyContainer: () => void;
  isHandleSendDiscussionLoading: boolean;
  isHandleSendReplyLoading: boolean;
}

type LatestMessageAndThreadUuids = {
  latestMessageUuid: string;
  threadUuid: string;
};

export const useDiscussionThreadBody = (
  internalThreads: Thread[],
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>,
  newThreadInformation: NewThreadInformation
): UseDiscussionThreadBodyReturnData => {
  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();

  const { activityHistoryRowVersion, setActivityHistoryRowVersion } = useContext(ActivityWizardContext);

  const { user, currentOrganisationUuid } = useAuth();

  const [newDiscussionErrors, setNewDiscussionErrors] = useState<ServiceError[] | undefined>();
  const [showNewDiscussionTextArea, setShowNewDiscussionTextArea] = useState<boolean>(false);
  const [newDiscussion, setNewDiscussion] = useState<string>("");

  const [replyErrors, setReplyErrors] = useState<ServiceError[] | undefined>();
  const [showReplyTextArea, setShowReplyTextArea] = useState<boolean>(false);
  const [newReply, setNewReply] = useState<string>("");
  const [isHandleSendDiscussionLoading, setIsHandleSendDiscussionLoading] = useState(false);
  const [isHandleSendReplyLoading, setIsHandleSendReplyLoading] = useState(false);

  const [latestMessageAndThreadUuids, setLatestMessageAndThreadUuids] = useState<LatestMessageAndThreadUuids[]>([]);

  useEffect(() => {
    const tempArr: LatestMessageAndThreadUuids[] = [];

    internalThreads.forEach((t) => {
      tempArr.push({
        latestMessageUuid: t.messages[t.messages.length - 1].uuid,
        threadUuid: t.uuid,
      } as LatestMessageAndThreadUuids);
    });

    setLatestMessageAndThreadUuids(tempArr);
  }, [internalThreads]);

  const handleCloseNewDiscussionContainer = (): void => {
    setNewDiscussion("");
    setShowNewDiscussionTextArea(false);
    setNewDiscussionErrors([]);
  };

  const handleSendDiscussion = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await createDiscussionThread({
      componentKey: newThreadInformation.componentKey,
      dataPath: newThreadInformation.dataPath,
      messageContent: newDiscussion,
      messageObjectType: newThreadInformation.messageObjectType,
      messageObjectUuid: newThreadInformation.messageObjectUuid,
      messageSourceOrganisationUuid: newThreadInformation.messageSourceOrganisationUuid,
      messageType: newThreadInformation.messageType,
      messageStatus: "Draft",
      threadObjectType: "Activity",
      threadObjectUuid: newThreadInformation.threadObjectUuid,
      threadType: "Discussion",
      visibility: newThreadInformation.visibility,
      messageTargetOrganisationRoles: newThreadInformation.messageTargetOrganisationRoles,
    });

    if (res.status === Status.Success && res.data && user) {
      const tempInternalThreads = internalThreads.concat([
        {
          resolved: false,
          uuid: res.data.threadUuid,
          hasNonDeletedMessagesCreatedByOtherUsers: false,
          messages: [
            {
              uuid: res.data.messageUuid,
              content: newDiscussion,
              createdAt: new Date(),
              isRead: false,
              status: "Draft",
              createdByUserUuid: user.userUuid,
              createdByUserFullName: getUserFullName(user.fullName, user.firstName, user.lastName),
              createdByUserAvatarUrl: user.avatar?.url,
              rowVersion: 1,
            },
          ],
        },
      ]);

      tempInternalThreads.sort((a, b) => {
        return Number(a.resolved) - Number(b.resolved);
      });

      setInternalThreads(tempInternalThreads);

      setActivityHistoryRowVersion(activityHistoryRowVersion + 1);

      handleCloseNewDiscussionContainer();

      Toast.success({ message: "Discussion added" });
    }

    if (res.status === Status.Error) {
      setNewDiscussionErrors(res.errors);
    }
  }, setIsHandleSendDiscussionLoading);

  const handleCloseReplyContainer = (): void => {
    setReplyErrors([]);
    setShowReplyTextArea(false);
    setNewReply("");
  };

  const handleSendReply = useIsLoadingWrapper(async (threadUuid: string): Promise<void> => {
    if (activityHistoryUuid && currentOrganisationUuid) {
      const res = await createDiscussionMessage({
        content: newReply,
        messageObjectType: "Activity History",
        messageObjectUuid: activityHistoryUuid,
        messageType: "Comment",
        messageStatus: "Draft",
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        parentMessageUuid: latestMessageAndThreadUuids.find((lmtu) => lmtu.threadUuid === threadUuid)!
          .latestMessageUuid,
        sourceOrganisationUuid: currentOrganisationUuid,
        threadUuid,
        targetOrganisationRoles: ["Developer", "Guest - Read", "Guest - Write"],
      });

      if (res.status === Status.Success && res.data && user) {
        setInternalThreads(
          internalThreads.map((t) => {
            if (t.uuid === threadUuid) {
              return {
                ...t,
                messages: t.messages.concat([
                  {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    uuid: res.data!.messageUuid,
                    content: newReply,
                    createdAt: new Date(),
                    isRead: false,
                    status: "Draft",
                    createdByUserUuid: user.userUuid,
                    createdByUserFullName: getUserFullName(user.fullName, user.firstName, user.lastName),
                    createdByUserAvatarUrl: user.avatar?.url,
                    rowVersion: 1,
                  },
                ]),
              };
            }

            return { ...t };
          })
        );

        setLatestMessageAndThreadUuids(
          latestMessageAndThreadUuids.map((lmtu) => {
            if (lmtu.threadUuid === threadUuid) {
              return {
                ...lmtu,
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                latestMessageUuid: res.data!.messageUuid,
              };
            }

            return {
              ...lmtu,
            };
          })
        );

        handleCloseReplyContainer();
      }

      if (res.status === Status.Error) {
        setReplyErrors(res.errors);
      }
    }
  }, setIsHandleSendReplyLoading);

  return {
    currentUserUuid: user?.userUuid,
    newDiscussionErrors,
    showNewDiscussionTextArea,
    setShowNewDiscussionTextArea,
    newDiscussion,
    setNewDiscussion,
    handleCloseNewDiscussionContainer,
    handleSendDiscussion,
    replyErrors,
    showReplyTextArea,
    setShowReplyTextArea,
    newReply,
    setNewReply,
    handleCloseReplyContainer,
    handleSendReply,
    isHandleSendDiscussionLoading,
    isHandleSendReplyLoading,
  };
};
