import { Dispatch, SetStateAction, useContext, useState } from "react";

import { CursorChangeProps } from "../../../../../models";
import { ResultData } from "../../../../../service/Shared";
import { DataGridColumnDefinition } from "../../../../../widget";
import { ActivityDashboardContext } from "../../ActivityDashboardContext";
import { ActivityDiscussionContext } from "../ActivityDiscussionContext";

type DiscussionTabData = {
  columns: DataGridColumnDefinition[];
  showNewDiscussionModal: boolean;
  setShowNewDiscussionModal: Dispatch<SetStateAction<boolean>>;
  discussionTableVisible: boolean;
  handleShowTableHideDetails: () => void;
  confirmDelete?: () => void;
  isConfirmDeleteLoading: boolean;
  cancelDelete?: () => void;
  selectTableRowAndHideDetails: (data: ResultData) => void;
  resolveViewOptions: { key: string; value: string }[];
  selectedResolveViewOption: string;
  changeSelectedResolveViewOption: (value: string) => void;
  showDeleteDiscussionModal: boolean;
  onRequestFetchDiscussions: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  canWriteDiscussion: boolean;
};

const resolveViewOptions = [
  { key: "Unresolved", value: "Unresolved" },
  { key: "Resolved", value: "Resolved" },
  { key: "All", value: "All" },
];

export const useDiscussionTab = (): DiscussionTabData => {
  const {
    columns,
    showTableHideDetails,
    setSelectedThreadDetails,
    discussionTableVisible,
    setSelectedResolveViewOption,
    selectTableRowAndHideDetails,
    refreshDiscussionTables,
    onRequestFetchDiscussions,
    selectedResolveViewOption,
    showDeleteDiscussionModal,
    confirmDeleteDiscussionCB,
    isConfirmDeleteDiscussionLoading,
    hideDeleteDiscussionModal,
  } = useContext(ActivityDiscussionContext);

  const { hasManageProjectActivityPermission } = useContext(ActivityDashboardContext);

  const [showNewDiscussionModal, setShowNewDiscussionModal] = useState<boolean>(false);

  const changeSelectedResolveViewOption = (value: string): void => {
    setSelectedResolveViewOption(value);
    refreshDiscussionTables();
    showTableHideDetails();
  };

  const handleShowTableHideDetails = (): void => {
    setSelectedThreadDetails(undefined); // Needed for the useEffects in useDiscussionThreadWithReply and useDiscussionMessage, so that reply and edit text areas are closed
    showTableHideDetails();
  };

  return {
    columns,
    showNewDiscussionModal,
    setShowNewDiscussionModal,
    discussionTableVisible,
    handleShowTableHideDetails,
    selectTableRowAndHideDetails,
    resolveViewOptions,
    selectedResolveViewOption,
    changeSelectedResolveViewOption,
    onRequestFetchDiscussions,
    showDeleteDiscussionModal,
    confirmDelete: confirmDeleteDiscussionCB,
    isConfirmDeleteLoading: isConfirmDeleteDiscussionLoading,
    cancelDelete: hideDeleteDiscussionModal,
    canWriteDiscussion: hasManageProjectActivityPermission,
  };
};
