import { AuditChangesPanelTemplate } from "../../../../templates";
import { Column, CursorDataGrid, Row } from "../../../../widget";
import { useAuditHistory } from "./useAuditHistory";

const AuditHistory = (): JSX.Element => {
  const {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    auditDataUuid,
    auditDataChange,
    showAuditChangesPanel,
    setShowAuditChangesPanel,
    onChange,
  } = useAuditHistory();
  return (
    <div>
      <Row spacingV="ll">
        <Column span={9}>
          <h2>Audit history</h2>
        </Column>
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        noDataMessage="No audit data to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
        exportable
        exportFileName="Audit_History_"
      />
      {auditDataUuid && (
        <AuditChangesPanelTemplate
          auditDataUuid={auditDataUuid}
          auditDataChange={auditDataChange}
          show={showAuditChangesPanel}
          onClose={() => setShowAuditChangesPanel(false)}
        />
      )}
    </div>
  );
};

export default AuditHistory;
