import { Navigate, Route, Routes } from "react-router-dom";

import { OrganisationPermissionConstants } from "../constants";
import { PublicVerifierDetailsPreviewParams } from "../models";
import { PublicVerifierDetailsPreviewPage } from "../route/public/verifier";
import { VerifierChangePasswordPage, VerifierChangePersonalDetailsPage } from "../route/verifier/profile";
import { VerifierSettingsPage } from "../route/verifier/settings";
import { VerifierMarketingAssetsTab } from "../route/verifier/settings/marketing-assets";
import { VerifierOrganisationTab } from "../route/verifier/settings/organisation";
import {
  VerifierUsersEditPage,
  VerifierUsersListPage,
  VerifierUsersSendInvitationPage,
} from "../route/verifier/settings/users";
import { useRouteGuards } from "./guards";

export const VerifierRoutes = (): JSX.Element => {
  const { RequireUserPermission, RequireVerifierAuth } = useRouteGuards();

  return (
    <Routes>
      <Route
        path="profile/change-password"
        element={
          <RequireVerifierAuth>
            <VerifierChangePasswordPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="profile/change-personal-details"
        element={
          <RequireVerifierAuth>
            <VerifierChangePersonalDetailsPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="settings/*"
        element={
          <RequireVerifierAuth>
            <VerifierSettingsPage />
          </RequireVerifierAuth>
        }
      >
        <Route
          path="users"
          element={
            <RequireUserPermission
              userPermission={OrganisationPermissionConstants.READ_ORGANISATION_USERS}
              redirectRoute="/v/settings/organisation"
            >
              <VerifierUsersListPage />
            </RequireUserPermission>
          }
        />
        <Route path="organisation" element={<VerifierOrganisationTab />} />
        <Route path="marketing-assets" element={<VerifierMarketingAssetsTab />} />
        <Route path="marketing-assets/:previewUuid" element={<VerifierMarketingAssetsTab />} />
        <Route path="" element={<Navigate to="users" replace />} />
      </Route>
      <Route
        path="settings/users/send-invitation"
        element={
          <RequireVerifierAuth>
            <VerifierUsersSendInvitationPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path="settings/users/:userUuid/edit"
        element={
          <RequireVerifierAuth>
            <VerifierUsersEditPage />
          </RequireVerifierAuth>
        }
      />
      <Route
        path={`verifier/details/preview/:${PublicVerifierDetailsPreviewParams.previewUuid}`}
        element={
          <RequireVerifierAuth>
            <PublicVerifierDetailsPreviewPage />
          </RequireVerifierAuth>
        }
      />
    </Routes>
  );
};
