import "./AuditChangesPanelTemplate.css";

import { ClosePanelArrowRightBlueIcon } from "../../assets";
import { Column, Divider, FlyoutPanel, Row } from "../../widget";
import { AuditMetaDataFieldType, AuditMetaDataNotesType } from "./types";
import { useAuditChangesPanelTemplate } from "./useAuditChangesPanelTemplate";
import { formatFieldName } from "./utils";

interface AuditChangesPanelTemplateProps {
  auditDataUuid: string;
  auditDataChange?: string;
  show: boolean;
  onClose: () => void;
}

const content = (
  auditMetaDataNotes?: AuditMetaDataNotesType,
  auditMetaDataFields?: AuditMetaDataFieldType[],
  auditDataChange?: string
): JSX.Element => {
  return (
    <div className="AuditChangesPanelContent">
      <Row spacingV="ll">
        <Column span={12}>
          <Divider type="horizontal" />
        </Column>
      </Row>
      {auditMetaDataFields?.map(
        (field) =>
          field.fieldName && (
            <div key={field.fieldName} className="ChangedField">
              <ClosePanelArrowRightBlueIcon />

              <Row spacingV="s">
                <Column span={12}>
                  <h3>{formatFieldName(field.fieldName, auditDataChange)}</h3>
                </Column>
              </Row>
              <Row spacingV="ll">
                <Column span={6}>
                  <Row spacingV="s">
                    <Column span={12}>
                      <p className="body1 PreviousVersion">Previously</p>
                    </Column>
                  </Row>
                  <Row spacingV="s">
                    <Column span={12}>
                      <p className="body1 PreviousVersion">{field.beforeValue}</p>
                    </Column>
                  </Row>
                </Column>
                <Column span={6}>
                  <Row spacingV="s">
                    <Column span={12}>
                      <p className="body1 NewVersion">Current</p>
                    </Column>
                  </Row>
                  <Row spacingV="s">
                    <Column span={12}>
                      <p className="body1 NewVersion Light">{field.afterValue}</p>
                    </Column>
                  </Row>
                </Column>
              </Row>
              <Row spacingV="ll">
                <Column span={12}>
                  <Divider type="horizontal" />
                </Column>
              </Row>
            </div>
          )
      )}
      {auditMetaDataNotes &&
        Object.keys(auditMetaDataNotes).length > 0 &&
        auditMetaDataFields &&
        auditMetaDataFields.length > 0 && (
          <>
            <Row spacingV="ll">
              <Column span={12}>
                <h1>Notes</h1>
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <Divider type="horizontal" />
              </Column>
            </Row>
          </>
        )}
      {auditMetaDataNotes &&
        Object.keys(auditMetaDataNotes).length > 0 &&
        Object.keys(auditMetaDataNotes).map((key) => (
          <div key={key}>
            <Row spacingV="s">
              <Column span={12}>
                <h3>{formatFieldName(key)}</h3>
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={12}>
                <p className="body1">{auditMetaDataNotes[key]}</p>
              </Column>
            </Row>
          </div>
        ))}
      {auditMetaDataNotes && Object.keys(auditMetaDataNotes).length > 0 && (
        <Row spacingV="ll">
          <Column span={12}>
            <Divider type="horizontal" />
          </Column>
        </Row>
      )}
    </div>
  );
};

export const AuditChangesPanelTemplate = ({
  auditDataUuid,
  auditDataChange,
  show,
  onClose,
}: AuditChangesPanelTemplateProps): JSX.Element => {
  const { auditMetaDataNotes, auditMetaDataFields } = useAuditChangesPanelTemplate({ auditDataUuid });

  return (
    <FlyoutPanel
      show={show}
      onClose={onClose}
      size="Large"
      header={auditMetaDataFields && auditMetaDataFields.length > 0 ? "Changes" : "Notes"}
      buttonsChildren={undefined}
      contentChildren={content(auditMetaDataNotes, auditMetaDataFields, auditDataChange)}
    />
  );
};
