import "./DiscussionMessage.css";

import { Dispatch, SetStateAction } from "react";

import { CheckCircleGreenIcon, CheckCircleGreyIcon, EllipseGroupDarkIcon, EyeOffGrayIcon } from "../../../../../assets";
import { ErrorBox } from "../../../../cards";
import { Avatar } from "../../../../data";
import { Button, TextArea } from "../../../../forms";
import { DeleteDiscussionModal } from "../../../../general";
import { Column, Row } from "../../../../layout";
import { ProfileMenu, ProfileMenuItem } from "../../../../navigation";
import { TabNotificationIndicator } from "../../../../wayfinding/Tabs/components";
import { Message, Thread } from "../../../models";
import { useDiscussionMessage } from "./useDiscussionMessage";

interface DiscussionMessageProps {
  internalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  message: Message;
  threadUuid: string;
  createdByCurrentUser: boolean;
  showGreyResolveButton?: boolean;
  showGreenResolveButton?: boolean;
  closeDiscussion: () => void;
  isForViewMode?: boolean;
  isFirstMessageInThread?: boolean;
}

export const DiscussionMessage = ({
  internalThreads,
  setInternalThreads,
  message,
  threadUuid,
  createdByCurrentUser,
  showGreyResolveButton,
  showGreenResolveButton,
  closeDiscussion,
  isForViewMode = false,
  isFirstMessageInThread,
}: DiscussionMessageProps): JSX.Element => {
  const {
    formatDate,
    showResolve,
    showMisc,
    editedMessage,
    editable,
    editableErrors,
    setEditableErrors,
    setShowResolve,
    setShowMisc,
    setEditable,
    setEditedMessage,
    handleEditMessage,
    resolveMenuItem,
    otherMessagesMenuItems,
    resolveRef,
    miscRef,
    firstMessageMenuItems,
    showDeleteDiscussionModal,
    confirmDelete,
    cancelDelete,
    showUnreadIndicator,
    showMarkAsRead,
    isHandleEditMessageLoading,
  } = useDiscussionMessage({
    internalThreads,
    setInternalThreads,
    message,
    closeDiscussion,
    threadUuid,
  });

  const messageEditBody = (): JSX.Element => (
    <div className="EditMessageContainer">
      {editableErrors && editableErrors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={editableErrors.map((error) => error.message)}
            />
          </Column>
        </Row>
      )}
      <TextArea
        name="EditMessage"
        displayCharacterCount
        maxLength={500}
        value={editedMessage}
        onChange={setEditedMessage}
      />
      <div className="EditMessageActions">
        <Button text="Save" onClick={() => handleEditMessage(message.uuid)} isLoading={isHandleEditMessageLoading} />
        <Button
          text="Cancel"
          variant="secondary"
          onClick={() => {
            setEditable(false);
            setEditedMessage(message.content);
            setEditableErrors(undefined);
          }}
        />
      </div>
    </div>
  );

  const actionsMenu = (): JSX.Element => (
    <>
      <div
        className="MiscButton"
        ref={miscRef}
        role="button"
        onClick={() => setShowMisc(true)}
        onKeyDown={(e) => (e.key === "Enter" ? setShowMisc(true) : undefined)}
        tabIndex={0}
      >
        <EllipseGroupDarkIcon />
      </div>
      <ProfileMenu
        show={showMisc}
        onClose={() => setShowMisc(false)}
        position={{
          top: `${(miscRef.current?.getBoundingClientRect().y || 0) + 32}px`,
          left: `${(miscRef.current?.getBoundingClientRect().left || 0) - 22}px`,
        }}
        closeOnScroll
      >
        {isFirstMessageInThread
          ? firstMessageMenuItems?.map((el) => (
              <ProfileMenuItem
                key={el.id}
                profileMenuItem={el}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  el.action!({ threadUuid });
                }}
              />
            ))
          : otherMessagesMenuItems?.map((el) => (
              <ProfileMenuItem
                key={el.id}
                profileMenuItem={el}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  el.action!({ messageUuid: message.uuid });
                }}
              />
            ))}
      </ProfileMenu>
    </>
  );

  return (
    <>
      <div className="DiscussionMessageContainer">
        <div className="DiscussionMessageHeader">
          <div>{showUnreadIndicator && <TabNotificationIndicator />}</div>
          <div className="AvatarAndDate">
            <Avatar
              userUuid={message.createdByUserUuid}
              imageSource={message.createdByUserAvatarUrl}
              name={message.createdByUserFullName}
              withName
              imageFirst
            />
            <p className="body2 DateCreated">{formatDate(message.createdAt)}</p>
          </div>
          <div className="DiscussionSelectActions">
            {message.status === "Draft" && (
              <div className="DiscussionUnpublishedMessage">
                <EyeOffGrayIcon />
                <p>Unpublished</p>
              </div>
            )}
            {showGreyResolveButton &&
              createdByCurrentUser &&
              !isForViewMode &&
              !!internalThreads.find((t) => t.uuid === threadUuid)?.messages.every((m) => m.isRead) && (
                <>
                  <div
                    className="ResolveButton"
                    ref={resolveRef}
                    role="button"
                    onClick={() => setShowResolve(true)}
                    onKeyDown={(e) => (e.key === "Enter" ? setShowResolve(true) : undefined)}
                    tabIndex={0}
                  >
                    <CheckCircleGreyIcon />
                  </div>
                  <ProfileMenu
                    show={showResolve}
                    onClose={() => setShowResolve(false)}
                    position={{
                      top: `${(resolveRef.current?.getBoundingClientRect().y || 0) + 32}px`,
                      left: `${(resolveRef.current?.getBoundingClientRect().left || 0) - 70}px`,
                    }}
                    closeOnScroll
                  >
                    <ProfileMenuItem
                      key={resolveMenuItem.id}
                      profileMenuItem={resolveMenuItem}
                      onClick={() => {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        resolveMenuItem.action!({ threadUuid });
                      }}
                    />
                  </ProfileMenu>
                </>
              )}
            {showGreyResolveButton && !createdByCurrentUser && (
              <div>
                <CheckCircleGreyIcon />
              </div>
            )}
            {showGreenResolveButton && <CheckCircleGreenIcon />}
            {(createdByCurrentUser || showMarkAsRead) &&
              !isForViewMode &&
              (firstMessageMenuItems !== undefined || otherMessagesMenuItems !== undefined) &&
              actionsMenu()}
          </div>
        </div>
        <div className="DiscussionMessageBody">
          {!editable ? <p className="body1">{message.content}</p> : messageEditBody()}
        </div>
      </div>
      {showDeleteDiscussionModal && <DeleteDiscussionModal onConfirm={confirmDelete} onCancel={cancelDelete} />}
    </>
  );
};
