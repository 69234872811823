import "../../../../wizards/Discussion/components/DiscussionMessage/DiscussionMessage.css";
import "./DiscussionMessage.css";

import { EllipsisDarkIcon, EyeOffGrayIcon } from "../../../../../assets";
import { formatDateWithRecency } from "../../../../../utils";
import { ErrorBox } from "../../../../cards";
import { Avatar } from "../../../../data";
import { Button, DropdownButton, DropdownItem, TextArea } from "../../../../forms";
import { Column, Row } from "../../../../layout";
import { TabNotificationIndicator } from "../../../../wayfinding/Tabs/components";
import { Message, MessageThread } from "../../models/Message";
import { useDiscussionMessage } from "./useDiscussionMessage";

export type DiscussionMessageProps = {
  message?: Message;
  onDeleteMessage?: () => void;
  onResolveThread?: () => void;
  setThreadData: (data: MessageThread) => void;
  threadData?: MessageThread;
  userUuid?: string;
  isThreadMessage?: boolean;
  isThreadResolved?: boolean;
};

export const DiscussionMessage = ({
  message,
  onDeleteMessage,
  onResolveThread,
  setThreadData,
  threadData,
  userUuid,
  isThreadMessage,
  isThreadResolved,
}: DiscussionMessageProps): JSX.Element => {
  const {
    editable,
    editedMessage,
    editableErrors,
    showEditMessage,
    showDeleteMessage,
    showEditThread,
    showDeleteThread,
    showResolveThread,
    showMarkAsRead,
    showUnreadIndicator,
    isHandleEditMessageLoading,
    editMessage,
    setEditable,
    setEditedMessage,
    setEditableErrors,
    handleEditMessage,
    handleMarkAsRead,
  } = useDiscussionMessage({ message, setThreadData, threadData, userUuid, isThreadResolved });

  const messageActionsDropdown = (onDelete?: () => void): JSX.Element => (
    <DropdownButton component={<EllipsisDarkIcon />}>
      {showMarkAsRead && (
        <DropdownItem onClick={handleMarkAsRead}>
          <span>Mark as read</span>
        </DropdownItem>
      )}
      {showEditMessage && (
        <DropdownItem onClick={editMessage}>
          <span>Edit message</span>
        </DropdownItem>
      )}
      {showDeleteMessage && (
        <DropdownItem onClick={onDelete}>
          <span>Delete message</span>
        </DropdownItem>
      )}
    </DropdownButton>
  );

  const threadActionsDropdown = (): JSX.Element => (
    <DropdownButton component={<EllipsisDarkIcon />}>
      {showMarkAsRead && (
        <DropdownItem onClick={handleMarkAsRead}>
          <span>Mark as read</span>
        </DropdownItem>
      )}
      {showResolveThread && (
        <DropdownItem onClick={onResolveThread}>
          <span>Resolve thread</span>
        </DropdownItem>
      )}
      {showDeleteThread && (
        <DropdownItem onClick={onDeleteMessage}>
          <span>Delete thread</span>
        </DropdownItem>
      )}
      {showEditThread && (
        <DropdownItem onClick={editMessage}>
          <span>Edit message</span>
        </DropdownItem>
      )}
    </DropdownButton>
  );

  const messageEditBody = (): JSX.Element => (
    <div className="EditMessageContainer">
      {editableErrors && editableErrors.length > 0 && (
        <Row spacingV="ml">
          <Column span={12}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={editableErrors.map((error) => error.message)}
            />
          </Column>
        </Row>
      )}
      <TextArea
        name="EditMessage"
        displayCharacterCount
        maxLength={500}
        value={editedMessage}
        onChange={setEditedMessage}
      />
      <div className="EditMessageActions">
        <Button
          text="Save"
          onClick={() => handleEditMessage(message?.uuid ?? "")}
          isLoading={isHandleEditMessageLoading}
        />
        <Button
          text="Cancel"
          variant="secondary"
          onClick={() => {
            setEditable(false);
            setEditedMessage(message?.content ?? "");
            setEditableErrors(undefined);
          }}
        />
      </div>
    </div>
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!message) return <></>;

  return (
    <div className="DiscussionMessageContainer">
      <div className="DiscussionMessageHeader">
        <div>{showUnreadIndicator && <TabNotificationIndicator />}</div>
        <div className="AvatarAndDate">
          <Avatar userUuid="" imageSource="" name={message.createdByUserFullName} withName imageFirst />
          <p className="body2 DateCreated">{formatDateWithRecency(message.createdAt)}</p>
        </div>
        {message.status === "Draft" && (
          <div className="DiscussionUnpublishedMessage">
            <EyeOffGrayIcon />
            <p>Unpublished</p>
          </div>
        )}
        {!isThreadMessage && !threadData?.resolved && (showEditMessage || showDeleteMessage || showMarkAsRead) && (
          <div className="DiscussionMessageActions">{messageActionsDropdown(onDeleteMessage)}</div>
        )}
        {isThreadMessage &&
          !threadData?.resolved &&
          (showEditThread || showDeleteThread || showResolveThread || showMarkAsRead) && (
            <div className="DiscussionMessageActions">{threadActionsDropdown()}</div>
          )}
      </div>
      <div className="DiscussionMessageBody">
        {!editable ? <p className="body1">{message.content}</p> : messageEditBody()}
      </div>
    </div>
  );
};
