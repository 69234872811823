import "./CompleteActivityModal.css";

import { Button, Column, Modal, Row } from "../../../../widget";
import { useCompleteActivityModal } from "./useCompleteActivityModal";

interface CompleteActivityModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;
  activityProjectUuid?: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
}

export const CompleteActivityModal = ({
  show,
  onClose,
  activityUuid,
  activityProjectUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
}: CompleteActivityModalProps): JSX.Element => {
  const { handleApprove, handleReject } = useCompleteActivityModal({
    activityUuid,
    activityProjectUuid,
    onClose,
    activityHistoryUuid,
    activityHistoryRowVersion,
  });
  return (
    <Modal show={show} title="Complete activity" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={8}>
          <span className="body1">
            Please confirm the outcome of this activity so that it can be accurately represented within Kana.
          </span>
        </Column>
      </Row>
      <Row justify="center">
        <Column span={8}>
          <div className="CompleteActivityModalButtons">
            <Button text="Approved" onClick={handleApprove} />
            <Button text="Rejected" onClick={handleReject} variant="secondary" />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
