import { PillVariant } from "../../widget";

export class ProjectStatusConstants {
  public static readonly DRAFT = "Draft";

  public static readonly PRE_DEVELOPMENT = "Pre-development";

  public static readonly UNDER_DEVELOPMENT = "Under Development";

  public static readonly VALIDATED = "Validated";

  public static readonly VERIFIED = "Verified";

  public static readonly NOT_DELIVERED = "Not Delivered";

  public static readonly CANCELLED = "Cancelled";

  public static readonly PROJECT_STATUS_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    Draft: "grey",
    "Pre-development": "grey",
    "Under Development": "coral",
    Verified: "green",
    Validated: "green",
    "Not Delivered": "grey",
    Cancelled: "grey",
  };
}
