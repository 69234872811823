import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../constants";
import { TabsList } from "../../../../models";
import { GetProjectDetailsResponse } from "../../../../service/query";
import {
  getProjectActivitiesRoute,
  getProjectDetailsByUuid,
  getProjectDocumentsRoute,
  getProjectIssuancesRoute,
  getProjectsRoute,
} from "../../../../utils/routes";
import { ProjectContext } from "../../../shared/projects/project/ProjectContext";

interface UseProjectReturnData {
  projectDetails: GetProjectDetailsResponse | undefined;
  handleBackClick: () => void;
  tabsList: TabsList;
}

export const useProject = (): UseProjectReturnData => {
  const navigate = useNavigate();
  const { projectDetails } = useContext(ProjectContext);

  const handleBackClick = (): void => {
    navigate(getProjectsRoute(OrganisationTypeConstants.DEVELOPMENT_MANAGER));
  };

  const getTabsList = (): TabsList => {
    const list = [
      {
        label: "Overview",
        route: getProjectDetailsByUuid(projectDetails?.uuid || "", OrganisationTypeConstants.DEVELOPMENT_MANAGER),
      },
      {
        label: "Issuances",
        route: getProjectIssuancesRoute(projectDetails?.uuid || "", OrganisationTypeConstants.DEVELOPMENT_MANAGER),
      },
      {
        label: "Activities",
        route: getProjectActivitiesRoute(projectDetails?.uuid || "", OrganisationTypeConstants.DEVELOPMENT_MANAGER),
      },
      {
        label: "Documents",
        route: getProjectDocumentsRoute(projectDetails?.uuid || "", OrganisationTypeConstants.DEVELOPMENT_MANAGER),
      },
    ];

    return list;
  };

  return {
    projectDetails,
    handleBackClick,
    tabsList: getTabsList(),
  };
};
