import { ActivityStatusConstants } from "../../constants";
import { GetCurrentUserProjectPermissionsResponse } from "../../service/query";

export const hasActivityPermissionForProject = (
  permissions: GetCurrentUserProjectPermissionsResponse[],
  projectUuid: string | undefined,
  activityPermission: string
): boolean =>
  permissions.find((p) => p.projectUuid === projectUuid)?.currentUserPermissions.includes(activityPermission) ?? false;

export const checkCanManageActivity = (
  hasManageProjectActivityPermission: boolean,
  activityStatus?: string
): boolean => {
  return (
    hasManageProjectActivityPermission &&
    activityStatus !== ActivityStatusConstants.APPROVED &&
    activityStatus !== ActivityStatusConstants.SUPERSEDED &&
    activityStatus !== ActivityStatusConstants.DELETED &&
    activityStatus !== ActivityStatusConstants.SUBMITTED
  );
};
