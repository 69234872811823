import "./VersionBanner.css";

import { OrganisationTypeConstants } from "../../../../../constants";
import { VersionBannerData } from "../../../../../models";
import { getFormattedDate } from "../../../../../utils";
import { Button, Select } from "../../../../forms";

export const VersionBanner = ({
  projectName,
  activityDefinitionName,
  updatedByUserFullName,
  updatedAt,
  activityHistoryVersionNumber,
  activityHistoryUuidData,
  isCurrentVersion,
  isDraftVersion,
  canManageActivity,
  currentUserType,
  onActivityHistoryUuidChange,
  onClose,
  onEdit,
}: VersionBannerData): JSX.Element => {
  return (
    <div className="VersionBannerWrapper">
      <div className={`${currentUserType} VersionBanner`}>
        <div className="TextArea">
          <h3>
            {projectName} {activityDefinitionName} v{activityHistoryVersionNumber}
          </h3>
          <p className="body2">
            Last updated by {updatedByUserFullName} on {updatedAt ? getFormattedDate(updatedAt) : ""}
          </p>
        </div>
        <div className="FormArea">
          <h3>View version</h3>
          <Select
            initialKey={isCurrentVersion ? `${activityHistoryVersionNumber} (Latest)` : activityHistoryVersionNumber}
            data={activityHistoryUuidData}
            onChange={onActivityHistoryUuidChange}
          />
          {(isCurrentVersion || isDraftVersion) && canManageActivity && (
            <Button text="Edit" variant="reversedPrimary" onClick={onEdit} size="small" />
          )}
          <Button
            text="Close"
            variant={currentUserType === OrganisationTypeConstants.ASSET_MANAGER ? "primary" : "reversedPrimary"}
            onClick={onClose}
            size="small"
          />
        </div>
      </div>
    </div>
  );
};
