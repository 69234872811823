import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useParams } from "react-router-dom";

import { EyeBlueIcon } from "../../../../../../assets";
import { ActivityPageParams, CursorChangeProps, ResultType } from "../../../../../../models";
import {
  getActivityDefinitionVersionDetails,
  GetActivityDefinitionVersionDetailsResponse,
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
  searchActivityDocuments,
  SearchActivityDocumentsResponse,
  SearchActivityHistoryResponse,
} from "../../../../../../service/query";
import { ResultData, Status } from "../../../../../../service/Shared";
import { canPreviewFile, flattenObject } from "../../../../../../utils";
import {
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
  Toast,
} from "../../../../../../widget";
import { IconCellFormatterData } from "../../../../../../widget/data/DataGrid/models";
import { getNewVersion } from "../../../../../shared/utils";
import { ActivityDefinition } from "../../types";
import {
  formatActivityDocumentHistoryDownloadLink,
  getStepKeysFromDocument,
  previewActivityDocument,
} from "../../utils";
import { downloadDocumentCellFormatter } from "../DownloadDocumentCellFormatter";

interface useReviewContentReturnData {
  activityData?: GetActivityHistoryDetailsResponse;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
  definitionData?: GetActivityDefinitionVersionDetailsResponse;
  showConfirmSubmissionModal: boolean;
  setShowConfirmSubmissionModal: Dispatch<SetStateAction<boolean>>;
  showCompleteActivityModal: boolean;
  setShowCompleteActivityModal: Dispatch<SetStateAction<boolean>>;
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
  submitActivity: () => Promise<void>;
  completeActivity: () => Promise<void>;
}

export const useReviewContent = (
  activityDefinition: ActivityDefinition,
  handleMoveToStep: (targetStepKeys: string[]) => void,
  setShowVersionConflictModal?: Dispatch<SetStateAction<boolean>>,
  setDraftActivityHistoryUuid?: Dispatch<SetStateAction<string | undefined>>,
  setNewVersionActivityHistory?: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>
): useReviewContentReturnData => {
  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse>();

  const [definitionData, setDefinitionData] = useState<GetActivityDefinitionVersionDetailsResponse>();
  const [showCompleteActivityModal, setShowCompleteActivityModal] = useState(false);
  const [showConfirmSubmissionModal, setShowConfirmSubmissionModal] = useState(false);
  const [showDownloadActivityFilesModal, setShowDownloadActivityFilesModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "activityDefinitionDocumentType.displayName",
      name: "Type",
      dataType: "string",
      filterable: true,
      sortable: true,
      minWidth: 200,
    },
    {
      key: "file.filename",
      name: "Filename",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "activityDocument.variant",
      name: "Variant",
      dataType: "string",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "activityDefinitionVersionDocumentType.isKey",
      name: "Key doc",
      dataType: "boolean",
      formatter: "yesNo",
      alignment: "center",
      // TODO: Add filtering support for booleans
      filterable: false,
      sortable: true,
    },
    {
      key: "activityDocument.versionNumber",
      name: "Version",
      dataType: "number",
      formatter: "align",
      alignment: "center",
      filterable: true,
      sortable: true,
    },
    {
      key: "activityDocument.createdAt",
      name: "Date uploaded",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "findLink",
      name: "",
      dataType: "string",
      formatter: "buttonLink",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
    {
      key: "previewIcon",
      name: "Preview",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
    {
      key: "downloadIcon",
      name: "Download",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: downloadDocumentCellFormatter,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [
    { columnKey: "activityDefinitionDocumentType.displayName", direction: "ASC" },
  ];

  const formatData = useCallback(
    (responseData: SearchActivityDocumentsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);
        result.findLink = {
          action: () => {
            const documentStepKeys = getStepKeysFromDocument(
              activityDefinition,
              el.activityDefinitionVersionDocumentType.uuid
            );
            if (documentStepKeys.length > 0) {
              handleMoveToStep(documentStepKeys);
            } else {
              Toast.error({ message: "Could not find document" });
            }
          },
          text: "Find",
        } as DataGridButtonLinkCellFormatterData;

        result.downloadIcon = formatActivityDocumentHistoryDownloadLink({
          activityDocumentHistoryUuid: el.activityDocument.uuid,
        });

        result.previewIcon = {
          action: () => {
            previewActivityDocument(el.activityDocument.uuid);
          },
          icon:
            el.file.mimeType && canPreviewFile(el.file.mimeType) ? (
              <EyeBlueIcon width={20} height={20} strokeWidth={1.5} />
            ) : undefined,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!activityHistoryUuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activityDefinitionDocumentType = {
      ...filterCriteria.activityDefinitionDocumentType,
      isGenerated: {
        operator: "eq",
        value: false,
      },
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchActivityDocuments({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
      currentOnly: true,
      activityDocumentHistoryUuid: null,
      activityHistoryUuid,
      activityUuid: null,
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  const getAndSetData = async (uuid: string): Promise<void> => {
    const historyDetailsRes = await getActivityHistoryDetails({ activityHistoryUuid: uuid });

    if (historyDetailsRes.status === Status.Success && historyDetailsRes.data) {
      setActivityData(historyDetailsRes.data);

      const definitionVersionDetailsRes = await getActivityDefinitionVersionDetails({
        activityDefinitionVersionUuid: historyDetailsRes.data.activity.activityDefinitionVersion.uuid,
        activityHistoryUuid: uuid,
      });

      if (definitionVersionDetailsRes.status === Status.Success && definitionVersionDetailsRes.data) {
        setDefinitionData(definitionVersionDetailsRes.data);
      }
    }
  };

  useEffect(() => {
    if (activityHistoryUuid) {
      getAndSetData(activityHistoryUuid);
    }
    return () => {
      setActivityData(undefined);
    };
  }, [activityHistoryUuid]);

  const isVersionConflict = async (): Promise<boolean> => {
    if (activityData && setShowVersionConflictModal && setDraftActivityHistoryUuid && setNewVersionActivityHistory) {
      const newVersion = await getNewVersion(activityData.activity.uuid, activityData.versionNumber);

      if (newVersion) {
        setShowVersionConflictModal(true);
        setDraftActivityHistoryUuid(activityHistoryUuid);
        setNewVersionActivityHistory(newVersion);

        return true;
      }
    }
    return false;
  };

  const submitActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowConfirmSubmissionModal(true);
  };

  const completeActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowCompleteActivityModal(true);
  };

  return {
    activityData,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    definitionData,
    showConfirmSubmissionModal,
    setShowConfirmSubmissionModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    submitActivity,
    completeActivity,
  };
};
