import "./AddGroupProjectModal.css";

import { ServiceError } from "../../../../../../../../service/Shared";
import { Autocomplete, Button, Column, ErrorBox, Modal, Row } from "../../../../../../../../widget";
import { useAddGroupProjectModal } from "./useAddGroupProjectModal";

interface AddGroupProjectModalProps {
  showAddProjectModal: boolean;
  closeModal: () => void;
}

export const AddGroupProjectModal = ({ showAddProjectModal, closeModal }: AddGroupProjectModalProps): JSX.Element => {
  const { projects, errors, addProject, onCancel, fetchProjects, setProjectUuid, isAddProjectLoading } =
    useAddGroupProjectModal({
      closeModal,
    });

  return (
    <Modal show={showAddProjectModal} title="Add project" onClose={onCancel}>
      {errors && errors.length > 0 && (
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <ErrorBox
              title="Please solve the below issues before saving"
              text={errors.map((error: ServiceError) => error.message)}
            />
          </Column>
        </Row>
      )}
      <Row spacingV="l" justify="center">
        <Column span={10}>
          <p className="body1">
            By editing this group, you agree to oversee the group&apos;s membership manually from this point forward.
            The group&apos;s project membership should continue to align with external registry to guarantee the
            successful completion of activities.
          </p>
        </Column>
      </Row>
      <Row spacingV="ll" justify="center">
        <Column span={6} justify="start">
          <div className="AddGroupProjectAutocomplete">
            <h3>Search projects</h3>
            <div>
              <Autocomplete
                placeholder="Please search for a project"
                data={projects}
                onTypeSearch={(value) => fetchProjects(value)}
                onSelect={(value) => setProjectUuid(value)}
              />
            </div>
          </div>
        </Column>
      </Row>
      <Row justify="center">
        <div className="AddGroupProjectModalButtons">
          <Button onClick={addProject} text="Add project" isLoading={isAddProjectLoading} />
          <Button onClick={onCancel} text="Cancel" variant="secondary" />
        </div>
      </Row>
    </Modal>
  );
};
