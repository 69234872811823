import { DeveloperTemplate } from "../../../templates";
import { Error403Page } from "../../shared/errors";

export const DeveloperError403 = (): JSX.Element => {
  return (
    <DeveloperTemplate>
      <Error403Page />
    </DeveloperTemplate>
  );
};
