import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { Origin, SelectData } from "../../../../../models";
import { getOrganisationUserDetails, GetOrganisationUserDetailsResponse, lookUp } from "../../../../../service/query";
import { Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { getSettingsAuditHistoryRoute, getUserSettingsRoute } from "../../../../../utils/routes";

interface UseEditReturnData {
  pageTitle: string | undefined;
  dataIsLoading: boolean;
  userDetails: GetOrganisationUserDetailsResponse | undefined;
  userUuid: string | undefined;
  roles: SelectData | undefined;
  rolesInfo: string[];
  userSettingsRoute: string;
  backButtonText: string;
  handleBackClick: () => void;
}

type EditPageRouteParams = "userUuid";

export const useEdit = (): UseEditReturnData => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const { userUuid } = useParams<EditPageRouteParams>();

  const { currentUserType } = useAuth();

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState<GetOrganisationUserDetailsResponse | undefined>(undefined);
  const [roles, setRoles] = useState<SelectData | undefined>(undefined);

  const backButtonText =
    searchParams.get("origin") === Origin.OrganisationAuditPage ? "Back to audit" : "Back to projects";

  const handleBackClick = (): void => {
    if (searchParams.get("origin") === Origin.OrganisationAuditPage)
      navigate(getSettingsAuditHistoryRoute(currentUserType));
    else navigate(getUserSettingsRoute(currentUserType));
  };

  const fetchData = useCallback(async () => {
    if (userUuid) {
      const organisationRes = await getOrganisationUserDetails({ userUuid });
      if (organisationRes.status === Status.Success) {
        setUserDetails(organisationRes.data);
      }
    }
    const rolesRes = await lookUp({ type: "Role" });
    if (rolesRes.status === Status.Success) {
      setRoles(
        rolesRes.data?.map((role) => {
          return {
            key: role.key,
            value: role.valueString,
          };
        })
      );
    }
  }, []);

  useEffect(() => {
    fetchData().then(() => setDataIsLoading(false));
  }, [fetchData]);

  return {
    pageTitle:
      userDetails?.user.firstName && userDetails?.user.lastName
        ? `${userDetails?.user.firstName} ${userDetails?.user.lastName}`
        : undefined,
    dataIsLoading,
    userDetails,
    userUuid,
    roles,
    rolesInfo: [
      "Owner: Can update all projects and organisation details. Owners can invite admin & staff members & edit all organisation users. There must always be one owner.",
      "Admin: Can update all projects and organisation details. Admin can invite staff members & edit staff users.",
      "Staff: Can update all projects but cannot update organisation details. Cannot invite other staff members",
    ],
    userSettingsRoute: getUserSettingsRoute(currentUserType),
    backButtonText,
    handleBackClick,
  };
};
