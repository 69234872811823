import "./NewDiscussion.css";

import { Dispatch, SetStateAction } from "react";

import { InformationIcon } from "../../../../../assets";
import { Button, Column, ErrorBox, ProfileMenu, ProfileMenuItem, Row, TextArea } from "../../../..";
import { Thread } from "../../../models";
import { DiscussionThread } from "../DiscussionThread";
import { NewThreadInformation, useNewDiscussion } from "./useNewDiscussion";

interface NewDiscussionProps {
  internalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  internalTeamSelected: boolean;
  developerSelected: boolean;
  handleInternalTeamSelect: () => void;
  handleDeveloperSelect: () => void;
  handleCancel: () => void;
  usedInDeveloperPortal: boolean;
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
}

export const NewDiscussion = ({
  internalThreads,
  setInternalThreads,
  // TODO these props will be used when the component will be used in Verifier portal
  // eslint-disable-next-line unused-imports/no-unused-vars
  internalTeamSelected,
  // eslint-disable-next-line unused-imports/no-unused-vars
  developerSelected,
  // eslint-disable-next-line unused-imports/no-unused-vars
  handleCancel,
  handleInternalTeamSelect,
  handleDeveloperSelect,
  usedInDeveloperPortal,
  closeDiscussion,
  newThreadInformation,
}: NewDiscussionProps): JSX.Element => {
  const {
    errors,
    showMessageDropdown,
    messageDropdownOptions,
    setShowMessageDropdown,
    newMessage,
    setNewMessage,
    handleSendDiscussion,
    firstStepCompleted,
    setFirstStepCompleted,
    secondStepCompleted,
    isHandleSendDiscussionLoading,
  } = useNewDiscussion({
    internalThreads,
    setInternalThreads,
    newThreadInformation,
    handleInternalTeamSelect,
    handleDeveloperSelect,
  });

  return (
    <>
      {!firstStepCompleted && !secondStepCompleted && (
        <>
          <div className="NewDiscussionHeader">
            <p className="DiscussionTitle body1">Add a comment</p>
          </div>
          {usedInDeveloperPortal ? (
            <div className="NewDiscussionButton">
              <Button text="Comment" onClick={() => setFirstStepCompleted(true)} />
            </div>
          ) : (
            <div className="NewDiscussionButton">
              <Button text="Comment" onClick={() => setShowMessageDropdown(true)} />
              <ProfileMenu
                show={showMessageDropdown}
                onClose={() => setShowMessageDropdown(false)}
                position={{ top: "2px", right: "unset" }}
              >
                {messageDropdownOptions.map((el) => (
                  <ProfileMenuItem
                    key={el.id}
                    profileMenuItem={el}
                    onClick={() => {
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      el.action!();
                    }}
                  />
                ))}
              </ProfileMenu>
            </div>
          )}
        </>
      )}

      {firstStepCompleted && !secondStepCompleted && (
        <div className="DiscussionCardBody">
          <div className="NewDiscussionHeader">
            <div>
              <p className="DiscussionTitle body1">Add a comment</p>
              {errors && errors.length > 0 && (
                <Row spacingV="ml" justify="center">
                  <Column span={12}>
                    <ErrorBox
                      title="Please solve the below issues before saving"
                      text={errors.map((error) => error.message)}
                    />
                  </Column>
                </Row>
              )}
            </div>
          </div>
          <div className="NewDiscussionMessage">
            <TextArea
              name="NewDiscussionMessage"
              displayCharacterCount
              maxLength={500}
              value={newMessage}
              onChange={setNewMessage}
            />
          </div>
          <div className="NewDiscussionInfo">
            <div>
              <InformationIcon width={16} height={16} />
            </div>
            <p>
              Your comment won’t be visible to other users in your organisation until you have published this activity
              draft
            </p>
          </div>
          <div className="NewDiscussionMessageActions">
            <Button text="Send discussion" onClick={handleSendDiscussion} isLoading={isHandleSendDiscussionLoading} />
            <Button
              text="Cancel"
              variant="secondary"
              onClick={() => {
                setFirstStepCompleted(false);
                closeDiscussion();
              }}
            />
          </div>
        </div>
      )}

      {secondStepCompleted && (
        <DiscussionThread
          internalThreads={internalThreads}
          externalThreads={[]}
          setInternalThreads={setInternalThreads}
          internalTeamSelected
          developerSelected={false}
          handleInternalTeamSelect={handleInternalTeamSelect}
          handleDeveloperSelect={handleDeveloperSelect}
          closeDiscussion={closeDiscussion}
          usedInDeveloperPortal
          newThreadInformation={newThreadInformation}
        />
      )}
    </>
  );
};
