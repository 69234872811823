import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate } from "react-router-dom";

import { ArrowheadRightDarkIcon } from "../../../assets";
import { OrganisationTypeConstants, ProjectActivitiesConstants, ProjectPermissionConstants } from "../../../constants";
import { CursorChangeProps, ResultType } from "../../../models";
import {
  getActivityAggregate,
  getCurrentUserProjectPermissions,
  GetCurrentUserProjectPermissionsResponse,
  searchActivities,
  SearchActivitiesResponse,
  SearchActivityHistoryResponse,
} from "../../../service/query";
import { ResultData, Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { flattenObject, hasActivityPermissionForProject } from "../../../utils";
import { getActivityDashboardTabRoute, getActivityViewRoute, getProjectDetailsByUuid } from "../../../utils/routes";
import {
  ChartData,
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridLinkCellFormatterData,
  dataGridMapFilterCriteria,
} from "../../../widget";
import { IconCellFormatterData } from "../../../widget/data/DataGrid/models";
import { resumeActivity } from "../../shared/utils";

interface UseActivitiesReturnData {
  activityTypeChartData: ChartData;
  activityStatusChartData: ChartData;
  activityCodeChartData: ChartData;
  columns: DataGridColumnDefinition[];
  isLoading: boolean;
  defaultSortingCriteria: SortColumn[];
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useActivities = (): UseActivitiesReturnData => {
  const navigate = useNavigate();

  const { currentUserType, currentDeveloperUuid } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [activityTypeChartData, setActivityTypeChartData] = useState<ChartData>({});
  const [activityStatusChartData, setActivityStatusChartData] = useState<ChartData>({});
  const [activityCodeChartData, setActivityCodeChartData] = useState<ChartData>({});
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();

  const columns: DataGridColumnDefinition[] = [
    {
      name: "Project",
      key: "projectLink",
      dataType: "string",
      formatter: "link",
      filterable: true,
      sortable: true,
    },
    {
      name: "Activity",
      key: "activityLink",
      dataType: "string",
      formatter: "buttonLink",
      filterable: true,
      sortable: true,
    },
    {
      name: "Variant",
      key: "variant",
      dataType: "string",
      alignment: "center",
      formatter: "align",
      filterable: true,
      sortable: true,
    },
    {
      name: "Status",
      key: "status",
      dataType: "string",
      formatter: "projectActivitiesStatusPill",
      minWidth: 150,
      alignment: "center",
      filterable: true,
      sortable: true,
    },
    {
      name: "Last updated",
      key: "currentVersion.createdAt",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
      sortable: true,
    },
    {
      name: "Updated by",
      key: "currentVersion.createdByUser.fullName",
      dataType: "string",
      alignment: "center",
      formatter: "align",
      filterable: true,
      sortable: true,
    },
    {
      name: "More details",
      key: "activityHistoryLink",
      dataType: "string",
      formatter: "icon",
      alignment: "center",
      filterable: false,
      sortable: false,
    },
  ];
  const pageSize = 10;

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "currentVersion.createdAt", direction: "DESC" }];

  const formatData = useCallback(
    (
      responseData: SearchActivitiesResponse | undefined,
      permissions: GetCurrentUserProjectPermissionsResponse[]
    ): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.project.displayName,
          to: getProjectDetailsByUuid(el.project.uuid, OrganisationTypeConstants.DEVELOPER),
        } as DataGridLinkCellFormatterData;

        if (
          el.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS &&
          hasActivityPermissionForProject(
            permissions,
            el.project.uuid,
            ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY
          )
        ) {
          result.activityLink = {
            text: el.activityDefinition.displayName,
            action: () => {
              resumeActivity(
                navigate,
                setDraftActivityHistoryUuid,
                setNewVersionActivityHistory,
                setShowVersionConflictModal,
                el.uuid,
                el.draftVersion?.uuid,
                el.draftVersion?.versionNumber
              );
            },
          } as DataGridButtonLinkCellFormatterData;
        } else {
          result.activityLink = {
            text: el.activityDefinition.displayName,
            action: () => {
              if (el.currentVersion) {
                navigate(
                  getActivityViewRoute(el.currentVersion.uuid, currentUserType, `projectUuid=${el.project.uuid}`)
                );
              }
            },
          } as DataGridButtonLinkCellFormatterData;
        }

        result.activityHistoryLink = {
          action: () =>
            navigate(
              getActivityDashboardTabRoute(el.uuid, "documents", currentUserType, `projectUuid=${el.project.uuid}`)
            ),
          icon: <ArrowheadRightDarkIcon width={12} height={12} />,
        } as IconCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    const filterCriteria = dataGridMapFilterCriteria(filtering);
    if (filterCriteria.projectLink) {
      filterCriteria.project = {
        displayName: filterCriteria.projectLink,
      };
      filterCriteria.projectLink = undefined;
    }
    if (filterCriteria.activityLink) {
      filterCriteria.activityDefinition = {
        displayName: filterCriteria.activityLink,
      };
      filterCriteria.activityLink = undefined;
    }

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const sortKeyMap: Record<string, string> = {
      projectLink: "project.displayName",
      activityLink: "activityDefinition.displayName",
    };

    await searchActivities({
      paging: {
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
        limit: pageSize,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: sortKeyMap[s.key] || s.key,
        direction: s.direction,
      })),
      /* eslint-enable */
      filter: { results: filterCriteria },
    })
      .then(async (response) => {
        const projectUuids = response.data?.results?.map((el) => el.project.uuid) || [];
        await getCurrentUserProjectPermissions({ projectUuids }).then((permissions) => {
          data = {
            resultData: formatData(response.data, permissions.data || []),
            paging: {
              startCursor: response.data?.paging?.startCursor || "",
              endCursor: response.data?.paging?.endCursor || "",
              pageSize,
              totalCount: response.data?.paging?.total || 0,
              hasNextPage: response.data?.paging?.hasNextPage || false,
              hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
            },
          };
        });
      })
      .finally(() => {
        setIsLoading(false);
      });

    return data;
  };

  const fetchData = useCallback(async (): Promise<void> => {
    if (currentDeveloperUuid) {
      const [activityTypeRes, activityStatusRes, activityCodeRes] = await Promise.all([
        getActivityAggregate({
          groupBy: "activityDefinition.displayName",
          filterBy: "developer",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDeveloperUuid,
        }),
        getActivityAggregate({
          groupBy: "activity.status",
          filterBy: "developer",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDeveloperUuid,
        }),
        getActivityAggregate({
          groupBy: "activityDefinition.standard.displayName",
          filterBy: "developer",
          filterOperator: "eq",
          aggregate: "activity.id",
          aggregation: "count",
          filterValue: currentDeveloperUuid,
        }),
      ]);

      if (activityTypeRes.status === Status.Success && activityTypeRes.data) {
        setActivityTypeChartData(
          activityTypeRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }

      if (activityStatusRes.status === Status.Success && activityStatusRes.data) {
        setActivityStatusChartData(
          activityStatusRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }

      if (activityCodeRes.status === Status.Success && activityCodeRes.data) {
        setActivityCodeChartData(
          activityCodeRes.data.reduce<ChartData>((previous, current) => {
            // eslint-disable-next-line no-param-reassign
            previous[current.key] = +current.value;
            return previous;
          }, {})
        );
      }
    }
  }, []);

  useEffect(() => {
    fetchData().finally(() => setIsLoading(false));
  }, [fetchData]);

  return {
    activityTypeChartData,
    activityStatusChartData,
    activityCodeChartData,
    columns,
    isLoading,
    defaultSortingCriteria,
    setShowVersionConflictModal,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    onChange,
  };
};
