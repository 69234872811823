import { createContext, Dispatch, SetStateAction, useMemo, useState } from "react";

interface ActivityWizardProviderProps {
  children?: JSX.Element;
}

interface ActivityWizardContextType {
  activityHistoryRowVersion: number;
  setActivityHistoryRowVersion: Dispatch<SetStateAction<number>>;
  refreshActivityWizard: boolean;
  setRefreshActivityWizard: Dispatch<SetStateAction<boolean>>;
}

export const ActivityWizardContext = createContext<ActivityWizardContextType>({} as ActivityWizardContextType);

export const ActivityWizardProvider = ({ children }: ActivityWizardProviderProps): JSX.Element => {
  const [activityHistoryRowVersion, setActivityHistoryRowVersion] = useState<number>(1);
  const [refreshActivityWizard, setRefreshActivityWizard] = useState(false);

  const memoisedValue = useMemo(
    () => ({
      activityHistoryRowVersion,
      setActivityHistoryRowVersion,
      refreshActivityWizard,
      setRefreshActivityWizard,
    }),
    [activityHistoryRowVersion, refreshActivityWizard]
  );

  return <ActivityWizardContext.Provider value={memoisedValue}>{children}</ActivityWizardContext.Provider>;
};
