import "./CreateProjectModal.css";

import { Button, Column, ErrorBox, InformationBox, Modal, Row, Select, TextInput } from "../../../../../widget";
import { useCreateProjectModal } from "./useCreateProjectModal";

interface CreateProjectModalProps {
  showCreateProjectModal: boolean;
  closeModal: () => void;
}

export const CreateProjectModal = ({ showCreateProjectModal, closeModal }: CreateProjectModalProps): JSX.Element => {
  const {
    errors,
    codeData,
    projectTypeData,
    projectTypeValue,
    formData,
    displayProjectTypeSelect,
    isHandleSubmitLoading,
    onProjectNameChange,
    onCodeChange,
    onProjectTypeChange,
    handleClose,
    handleSubmit,
  } = useCreateProjectModal(closeModal);

  return (
    <Modal show={showCreateProjectModal} title="New project" onClose={handleClose}>
      <form onSubmit={handleSubmit} className="CreateProjectModalContent">
        {errors && errors.length > 0 && (
          <Row spacingV="ml" justify="center">
            <Column span={6}>
              <ErrorBox
                title="Please solve the below issues before saving"
                text={errors.map((error) => error.message)}
              />
            </Column>
          </Row>
        )}
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <InformationBox variant="info" title="Project Creation Reminder">
              <p className="body2">
                Please note that project registration is still to be completed through your external registry, creating
                a project through Kana allows you to list a project as seeking funding or start creating marketing
                materials, but is not the official registration of the project.
              </p>
            </InformationBox>
          </Column>
        </Row>
        <Row spacingV="ml" justify="center">
          <Column span={6}>
            <TextInput
              label="Project name"
              name="projectName"
              value={formData.projectName}
              onChange={onProjectNameChange}
            />
          </Column>
        </Row>
        {codeData && (
          <Row spacingV={displayProjectTypeSelect ? "ml" : "ll"} justify="center">
            <Column span={6}>
              <Select label="Code" data={codeData} onChange={onCodeChange} />
            </Column>
          </Row>
        )}
        {displayProjectTypeSelect && (
          <Row spacingV="ll" justify="center">
            <Column span={6}>
              <Select
                label="Project type"
                data={projectTypeData}
                value={projectTypeValue}
                onChange={onProjectTypeChange}
              />
            </Column>
          </Row>
        )}
        <Row spacingV="xl" justify="center">
          <Column span={6}>
            <Button type="submit" text="Save as Draft" fullWidth isLoading={isHandleSubmitLoading} />
          </Column>
        </Row>
      </form>
    </Modal>
  );
};
