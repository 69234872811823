import "./DiscussionThreadBody.css";

import { Dispatch, SetStateAction } from "react";

import { InformationIcon } from "../../../../../assets";
import { ErrorBox } from "../../../../cards";
import { Button, TextArea } from "../../../../forms";
import { Divider } from "../../../../general";
import { Column, Row } from "../../../../layout";
import { Message, Thread } from "../../../models";
import { DiscussionMessage } from "../DiscussionMessage";
import { NewThreadInformation } from "../NewDiscussion/useNewDiscussion";
import { useDiscussionThreadBody } from "./useDiscussionThreadBody";

interface DiscussionThreadBodyProps {
  internalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  firstMessagesInEachThread: Message[] | undefined;
  replies: Message[][];
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  isForViewMode?: boolean;
}

export const DiscussionThreadBody = ({
  internalThreads,
  setInternalThreads,
  firstMessagesInEachThread,
  replies,
  closeDiscussion,
  newThreadInformation,
  isForViewMode = false,
}: DiscussionThreadBodyProps): JSX.Element => {
  const {
    currentUserUuid,
    showNewDiscussionTextArea,
    setShowNewDiscussionTextArea,
    newDiscussionErrors,
    newDiscussion,
    setNewDiscussion,
    handleCloseNewDiscussionContainer,
    handleSendDiscussion,
    replyErrors,
    showReplyTextArea,
    newReply,
    setNewReply,
    setShowReplyTextArea,
    handleCloseReplyContainer,
    handleSendReply,
    isHandleSendDiscussionLoading,
    isHandleSendReplyLoading,
  } = useDiscussionThreadBody(internalThreads, setInternalThreads, newThreadInformation);

  return (
    <div className="DiscussionThreadBody">
      {internalThreads[0]?.resolved &&
        !isForViewMode &&
        (showNewDiscussionTextArea ? (
          <div className="NewDiscussionContainer">
            {newDiscussionErrors && newDiscussionErrors.length > 0 && (
              <Row spacingV="ml" justify="center">
                <Column span={12}>
                  <ErrorBox
                    title="Please solve the below issues before saving"
                    text={newDiscussionErrors.map((error) => error.message)}
                  />
                </Column>
              </Row>
            )}
            <TextArea
              name="NewDiscussion"
              displayCharacterCount
              maxLength={500}
              value={newDiscussion}
              onChange={setNewDiscussion}
            />
            <div className="NewDiscussionInfo">
              <div>
                <InformationIcon width={16} height={16} />
              </div>
              <p>
                Your comment won’t be visible to other users in your organisation until you have published this activity
                draft
              </p>
            </div>
            <div className="NewDiscussionMessageActions">
              <Button text="Send discussion" onClick={handleSendDiscussion} isLoading={isHandleSendDiscussionLoading} />
              <Button text="Cancel" variant="secondary" onClick={handleCloseNewDiscussionContainer} />
            </div>
          </div>
        ) : (
          <div>
            <Button text="Start new discussion" onClick={() => setShowNewDiscussionTextArea(true)} />
          </div>
        ))}
      {firstMessagesInEachThread &&
        !!firstMessagesInEachThread.length &&
        firstMessagesInEachThread.map((firstMessageInThread, idx) => (
          <div
            key={firstMessageInThread.uuid}
            className={`${internalThreads[idx].resolved ? "ResolvedContainer" : ""}`}
          >
            {internalThreads[idx].resolved && (
              <div className="ResolvedHeader">
                <p className="body1">Resolved thread</p>
                <Divider type="horizontal" />
              </div>
            )}
            <div className="DiscussionFirstMessageContainer">
              <DiscussionMessage
                internalThreads={internalThreads}
                setInternalThreads={setInternalThreads}
                closeDiscussion={closeDiscussion}
                threadUuid={internalThreads[idx].uuid}
                message={firstMessageInThread}
                showGreenResolveButton={internalThreads[idx].resolved}
                showGreyResolveButton={!internalThreads[idx].resolved}
                createdByCurrentUser={
                  !internalThreads[idx].resolved && currentUserUuid === firstMessageInThread.createdByUserUuid
                }
                isForViewMode={isForViewMode}
                isFirstMessageInThread
              />
            </div>
            {!!replies[idx].length && (
              <div className="ReplyContainer">
                <Divider type="vertical" thickness={1} />
                <div className="DiscussionMessageReplies">
                  {replies[idx].map((message) => (
                    <DiscussionMessage
                      internalThreads={internalThreads}
                      setInternalThreads={setInternalThreads}
                      closeDiscussion={closeDiscussion}
                      threadUuid={internalThreads[idx].uuid}
                      message={message}
                      key={message.uuid}
                      createdByCurrentUser={
                        !internalThreads[idx].resolved && currentUserUuid === message.createdByUserUuid
                      }
                      isForViewMode={isForViewMode}
                    />
                  ))}
                </div>
              </div>
            )}
            {idx === 0 &&
              !!internalThreads.length &&
              (showReplyTextArea ? (
                <div className="NewReplyContainer">
                  {replyErrors && replyErrors.length > 0 && (
                    <Row spacingV="ml" justify="center">
                      <Column span={12}>
                        <ErrorBox
                          title="Please solve the below issues before saving"
                          text={replyErrors.map((error) => error.message)}
                        />
                      </Column>
                    </Row>
                  )}
                  <TextArea
                    name="NewReply"
                    displayCharacterCount
                    maxLength={500}
                    value={newReply}
                    onChange={setNewReply}
                  />
                  <div className="NewReplyInfo">
                    <div>
                      <InformationIcon width={16} height={16} />
                    </div>
                    <p>Reply won’t be visible to others until you publish activity draft</p>
                  </div>
                  <div className="NewReplyMessageActions">
                    <Button
                      text="Send reply"
                      onClick={() => handleSendReply(internalThreads[idx].uuid)}
                      isLoading={isHandleSendReplyLoading}
                    />
                    <Button text="Cancel" variant="secondary" onClick={() => handleCloseReplyContainer()} />
                  </div>
                </div>
              ) : (
                !isForViewMode &&
                internalThreads.find((t) => t.resolved === false) && (
                  <div>
                    <Button text="Reply" onClick={() => setShowReplyTextArea(true)} />
                  </div>
                )
              ))}
          </div>
        ))}
    </div>
  );
};
