import "./DashboardPieCard.css";

import { ReactNode } from "react";

import { Card, PieChart } from "../../../../../widget";

export interface DashboardPieCardProps {
  data: { [key: string]: number };
  title: string;
  noDataMessage?: string;
  tooltip?: ReactNode;
}

export const DashboardPieCard = ({ data, title, noDataMessage, tooltip }: DashboardPieCardProps): JSX.Element => {
  return (
    <Card height="100%">
      <div className="DashboardPieCardContainer">
        <div className="DashboardPieCardHeader">
          <h3>{title}</h3>
          <div>{tooltip}</div>
        </div>
        <div className="DashboardPieCardContent">
          <PieChart size={120} data={data} noDataMessage={noDataMessage} />
        </div>
      </div>
    </Card>
  );
};
