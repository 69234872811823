import "./DiscussionThread.css";

import { Dispatch, SetStateAction } from "react";

import { Divider } from "../../../../general";
import { Thread } from "../../../models";
import { DiscussionThreadBody } from "../DiscussionThreadBody";
import { NewThreadInformation } from "../NewDiscussion/useNewDiscussion";
import { useDiscussionThread } from "./useDiscussionThread";

interface DiscussionThreadProps {
  internalThreads: Thread[];
  externalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
  internalTeamSelected: boolean;
  developerSelected: boolean;
  handleInternalTeamSelect: () => void;
  handleDeveloperSelect: () => void;
  closeDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  usedInDeveloperPortal?: boolean;
  isForViewMode?: boolean;
}

export const DiscussionThread = ({
  internalThreads,
  // eslint-disable-next-line unused-imports/no-unused-vars
  externalThreads,
  setInternalThreads,
  internalTeamSelected,
  developerSelected,
  handleInternalTeamSelect,
  handleDeveloperSelect,
  usedInDeveloperPortal = false,
  closeDiscussion,
  newThreadInformation,
  isForViewMode = false,
}: DiscussionThreadProps): JSX.Element => {
  const { firstMessagesInEachThread, replies } = useDiscussionThread({
    internalThreads,
  });

  return (
    <>
      <div className="DiscussionThreadHeader">
        <div className="DiscussionSelectContainer">
          {usedInDeveloperPortal ? (
            <div className="DiscussionInternalTeamOnly">
              <p className="body1">Internal Discussion</p>
            </div>
          ) : (
            <>
              <div
                role="button"
                tabIndex={0}
                className={`DiscussionSelectItem ${developerSelected ? "Selected" : ""}`}
                onClick={handleDeveloperSelect}
                onKeyDown={(e) => (e.key === "Enter" ? handleDeveloperSelect() : undefined)}
              >
                <p className="DiscussionTitle body1">External Discussion</p>
              </div>
              <div
                role="button"
                tabIndex={0}
                className={`DiscussionSelectItem ${internalTeamSelected ? "Selected" : ""}`}
                onClick={handleInternalTeamSelect}
                onKeyDown={(e) => (e.key === "Enter" ? handleInternalTeamSelect() : undefined)}
              >
                <p className="DiscussionTitle body1">Internal Discussion</p>
              </div>
            </>
          )}
        </div>
      </div>
      {!(isForViewMode && !internalThreads.find((t) => t.resolved === false)) && (
        <div>
          <Divider type="horizontal" />
        </div>
      )}
      <DiscussionThreadBody
        internalThreads={internalThreads}
        setInternalThreads={setInternalThreads}
        firstMessagesInEachThread={firstMessagesInEachThread}
        replies={replies}
        closeDiscussion={closeDiscussion}
        newThreadInformation={newThreadInformation}
        isForViewMode={isForViewMode}
      />
    </>
  );
};
