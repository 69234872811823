import { useContext, useEffect, useState } from "react";

import { ResultType } from "../../../../../models";
import { ResultData } from "../../../../../service/Shared";
import { DataGridColumnDefinition } from "../../../../../widget";
import { OnCursorChangeEventHandler } from "../../../../../widget/data/DataGrid/models";
import { MessageThread } from "../../../../../widget/general/Discussions/models/Message";
import { ActivityDiscussionContext } from "../ActivityDiscussionContext";

export type useDiscussionDetailsReturn = {
  thread: MessageThread | undefined;
  setThreadData: (data: MessageThread) => void;
  showTableHideDetails: () => void;
  onChange: OnCursorChangeEventHandler;
  columns: DataGridColumnDefinition[];
  selectedThreadDetails?: ResultData;
  refresh?: boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseThread = (threadData: any): MessageThread | undefined => {
  if (!threadData?.messages) {
    return undefined;
  }
  return {
    resolved: threadData.actionsData.isResolved,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messages: threadData.messages.map((message: any) => ({
      uuid: message.uuid,
      content: message.content,
      createdAt: message.createdAt,
      createdByUserFullName: message.createdByUser.fullName,
      createdByUserUuid: message.createdByUser.uuid,
      isRead: message.isRead,
      status: message.status,
      rowVersion: message.rowVersion,
    })),
  } as MessageThread;
};

export const useDiscussionDetails = (): useDiscussionDetailsReturn => {
  const { selectedThreadDetails, columns, showTableHideDetails } = useContext(ActivityDiscussionContext);

  const [thisThreadData, setThisThreadData] = useState(parseThread(selectedThreadDetails));
  const [refresh, setRefresh] = useState<boolean>(false);

  const onChange: OnCursorChangeEventHandler = async (): Promise<ResultType> => {
    return {
      resultData: [selectedThreadDetails ?? {}],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
  };

  useEffect(() => {
    setThisThreadData(parseThread(selectedThreadDetails));
    setRefresh(!refresh);
  }, [selectedThreadDetails]);

  return {
    columns: columns.map((c) => {
      if (c.key === "threadType") {
        return { ...c, formatter: "textWithHiddenIcon" };
      }

      return c;
    }),
    refresh,
    thread: thisThreadData,
    selectedThreadDetails,
    setThreadData: setThisThreadData,
    onChange,
    showTableHideDetails,
  };
};
