import "./RadioButtonGroup.css";

import { ReactNode, useEffect, useState } from "react";

import { RadioButtonGroupData, RadioButtonGroupKey } from "../../../../models";
import { FormLabel } from "../../FormLabel";
import { RadioChangeEvent } from "./components";
import AntdRadioGroup from "./components/Group";
import AntdRadio from "./components/Radio";
import { useRadioButtonGroup } from "./useRadioButtonGroup";

export interface RadioButtonGroupProps {
  data: RadioButtonGroupData;
  onChange: (e: RadioChangeEvent) => void;
  value?: RadioButtonGroupKey;
  label?: string;
  allowLabelDangerousHtml?: boolean;
  tooltip?: ReactNode;
  disabled?: boolean;
  direction?: "vertical" | "horizontal";
}

export const RadioButtonGroup = ({
  data,
  onChange,
  value,
  label,
  allowLabelDangerousHtml,
  tooltip,
  disabled = false,
  direction,
}: RadioButtonGroupProps): JSX.Element => {
  const { alignment } = useRadioButtonGroup(data, { direction });
  const className = alignment.direction === "vertical" ? "Radio-group-vertical" : "";
  const [internalValue, setInternalValue] = useState(value);

  const onChangeInternal: (e: RadioChangeEvent) => void = (e) => {
    setInternalValue(e.target.value);
    onChange(e);
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  return (
    <div className={`RadioButtonGroup ${disabled ? `RadioButtonGroup_disabled` : ""}`}>
      {label && (
        <FormLabel labelAlignment="left" label={label} tooltip={tooltip} allowDangerousHtml={allowLabelDangerousHtml} />
      )}
      <AntdRadioGroup value={internalValue} onChange={onChangeInternal} className={className} disabled={disabled}>
        {data.map((radioButton) => (
          <AntdRadio key={radioButton.value} value={radioButton.key}>
            <span className="body1">{radioButton.value}</span>
          </AntdRadio>
        ))}
      </AntdRadioGroup>
    </div>
  );
};
