import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UserType } from "../../../constants";
import { CursorChangeProps, ResultType } from "../../../models";
import {
  getProjectAggregate,
  searchActivities,
  SearchActivitiesResponse,
  searchProjects,
  SearchProjectsResponse,
} from "../../../service/query";
import { ResultData, Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { flattenObject } from "../../../utils";
import { getActivityViewRoute, getProjectDetailsByUuid } from "../../../utils/routes";
import {
  ChartData,
  DataGridButtonLinkCellFormatterData,
  DataGridColumnDefinition,
  DataGridCursorDataLoadEventHandler,
  DataGridLinkCellFormatterData,
  Toast,
} from "../../../widget";

interface UseDevelopmentManagerDashboardReturnData {
  statusChartData: ChartData;
  unitsChartData: ChartData;
  isLoading: boolean;
  isLoadingProjects: boolean;
  isLoadingActivities: boolean;
  tableColumnsTopProjects: DataGridColumnDefinition[];
  tableColumnsRecentActivities: DataGridColumnDefinition[];
  onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler;
  onRecentActivitiesDataLoad: DataGridCursorDataLoadEventHandler;
  currentUserType: UserType;
}

const tableColumnsTopProjects: DataGridColumnDefinition[] = [
  {
    key: "link",
    name: "Project",
    dataType: "string",
    formatter: "link",
  },
  { key: "standard.displayName", name: "Code", dataType: "string" },
  {
    name: "Status",
    key: "status",
    dataType: "string",
    formatter: "projectStatusPill",
    minWidth: 200,
  },
  {
    key: "cachedPiuQuantity",
    name: "PIUs",
    dataType: "string",
    formatter: "align",
    alignment: "right",
  },
  {
    key: "cachedVcuQuantity",
    name: "VCUs",
    dataType: "string",
    formatter: "align",
    alignment: "right",
  },
];

const tableColumnsRecentActivities: DataGridColumnDefinition[] = [
  {
    key: "projectLink",
    name: "Project",
    dataType: "string",
    formatter: "link",
  },
  { key: "project.standard.displayName", name: "Code", dataType: "string" },
  {
    key: "activityLink",
    name: "Activity",
    dataType: "string",
    formatter: "buttonLink",
  },
  {
    name: "Status",
    key: "status",
    dataType: "string",
    formatter: "projectActivitiesStatusPill",
    minWidth: 200,
  },
  {
    name: "Progress",
    key: "currentVersion.completionPercentage",
    dataType: "number",
    formatter: "progress",
  },
  {
    name: "Last updated",
    key: "currentVersion.createdAt",
    dataType: "Date",
    formatter: "dateOnly",
    filterable: false,
  },
];

export const useDashboard = (): UseDevelopmentManagerDashboardReturnData => {
  const { currentDevelopmentManagerUuid, currentUserType } = useAuth();

  const navigate = useNavigate();

  const [statusChartData, setStatusChartData] = useState<ChartData>({});
  const [unitsChartData, setUnitsChartData] = useState<ChartData>({});

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingProjects, setIsLoadingProjects] = useState(true);
  const [isLoadingActivities, setIsLoadingActivities] = useState(true);

  const fetchChartsData = useCallback(async () => {
    if (!currentDevelopmentManagerUuid) {
      throw new Error(`CurrentDevelopmentManagerUuid has no value. ${currentDevelopmentManagerUuid}`);
    }

    const [statusAggregateResponse, piuUnitsAggregateResponse, vcuUnitsAggregateResponse] = await Promise.all([
      getProjectAggregate({
        filterBy: "developmentManager",
        filterOperator: "eq",
        filterValue: currentDevelopmentManagerUuid,
        groupBy: "status",
        aggregation: "count",
        aggregate: "id",
      }),
      getProjectAggregate({
        filterBy: "developmentManager",
        filterOperator: "eq",
        filterValue: currentDevelopmentManagerUuid,
        aggregation: "sum",
        aggregate: "cachedPiuQuantity",
        groupBy: null,
      }),
      getProjectAggregate({
        filterBy: "developmentManager",
        filterOperator: "eq",
        filterValue: currentDevelopmentManagerUuid,
        aggregation: "sum",
        aggregate: "cachedVcuQuantity",
        groupBy: null,
      }),

      Promise.resolve({
        status: Status.Error,
        data: [{ key: "remove", objectName: "remove", value: 1 }],
      }),
    ]);

    if (statusAggregateResponse.status === Status.Success && statusAggregateResponse.data) {
      setStatusChartData(
        statusAggregateResponse.data.reduce<ChartData>((previous, current) => {
          // eslint-disable-next-line no-param-reassign
          previous[current.key] = current.value;

          return previous;
        }, {})
      );
    }

    if (
      piuUnitsAggregateResponse.status === Status.Success &&
      piuUnitsAggregateResponse.data &&
      vcuUnitsAggregateResponse.status === Status.Success &&
      vcuUnitsAggregateResponse.data
    ) {
      setUnitsChartData({
        "Verified carbon units (VCUs)": vcuUnitsAggregateResponse.data[0].value,
        "Pending issuance units (PIUs)": piuUnitsAggregateResponse.data[0].value,
      });
    }
  }, []);

  useEffect(() => {
    fetchChartsData().finally(() => {
      setIsLoading(false);
    });
  }, [fetchChartsData]);

  const formatData = useCallback(
    (responseData: SearchProjectsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.link = {
          text: el.displayName,
          to: getProjectDetailsByUuid(el.uuid, currentUserType),
        } as DataGridLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const formatActivitiesData = useCallback(
    (responseData: SearchActivitiesResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.projectLink = {
          text: el.project.displayName,
          to: getProjectDetailsByUuid(el.project.uuid, currentUserType),
        } as DataGridLinkCellFormatterData;

        result.activityLink = {
          text: el.activityDefinition.displayName,
          action: () => {
            if (el.currentVersion) {
              navigate(getActivityViewRoute(el.currentVersion.uuid, currentUserType, `projectUuid=${el.project.uuid}`));
            } else {
              Toast.error({
                message: `This activity has not yet been published. Once ${el.createdByUser.fullName} has published the activity, you can view the activity.`,
              });
            }
          },
        } as DataGridButtonLinkCellFormatterData;

        return result;
      }) || [],
    []
  );

  const onTopProjectsDataLoad: DataGridCursorDataLoadEventHandler = async ({ paging }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingProjects(true);

    await searchProjects({
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "cachedPiuQuantity" as "results.cachedPiuQuantity",
          direction: "desc",
        },
      ],
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingProjects(false);
      });
    return data;
  };

  const onRecentActivitiesDataLoad: DataGridCursorDataLoadEventHandler = async ({ paging }: CursorChangeProps) => {
    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 5,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };
    setIsLoadingActivities(true);

    await searchActivities({
      paging: {
        limit: 10,
        beforeCursor: null,
        afterCursor: null,
      },
      sort: [
        {
          key: "currentVersion.createdAt" as "results.currentVersion.createdAt",
          direction: "desc",
        },
      ],
    })
      .then(async (response) => {
        data = {
          resultData: formatActivitiesData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            hasNextPage: false,
            hasPreviousPage: false,
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || response.data?.results?.length || 0,
          },
        };
      })
      .finally(() => {
        setIsLoadingActivities(false);
      });
    return data;
  };

  return {
    statusChartData,
    unitsChartData,
    isLoading,
    isLoadingProjects,
    isLoadingActivities,
    tableColumnsTopProjects,
    tableColumnsRecentActivities,
    onTopProjectsDataLoad,
    onRecentActivitiesDataLoad,
    currentUserType,
  };
};
