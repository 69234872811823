import "./Issuances.css";

import { Column, KanaTooltip, Row } from "../../../../../widget";
import { IssuancesForm } from "./form";
import { useIssuances } from "./useIssuances";

const Issuances = (): JSX.Element => {
  const { showTooltip } = useIssuances();

  return (
    <div className="DeveloperProjectTabSection">
      <Row spacingV="ll">
        <Column span={12}>
          <div className="IssuancesHeader">
            <h2>Issuances</h2>
            {showTooltip && (
              <KanaTooltip
                tooltipHeader="Adding prices"
                tooltipText="These prices are to allow you to give a price indication to the viewer. They do not commit you to transacting at this price level. Please enable interested parties to contact your organisation for more information if you wish to sell units."
              />
            )}
          </div>
        </Column>
      </Row>
      <IssuancesForm />
    </div>
  );
};

export default Issuances;
