import { PillVariant } from "../../widget";

export class ProjectActivitiesConstants {
  // Generated Document MimeType
  public static readonly GENERATED_DOCUMENT_MIME_TYPE: string =
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

  // Definitions
  public static readonly DEFINITION_VALIDATION = "Validation";

  public static readonly DEFINITION_VERIFICATION = "Verification";

  public static readonly DEFINITION_LOSS_EVENT = "Loss Event";

  // Statuses
  public static readonly STATUS_IN_PROGRESS = "In progress";

  public static readonly STATUS_SUBMITTED = "Submitted";

  public static readonly STATUS_REJECTED = "Rejected";

  public static readonly STATUS_APPROVED = "Approved";

  public static readonly PROJECT_ACTIVITIES_STATUS_PILL_VARIANT_MAP: { [key: string]: PillVariant } = {
    "In progress": "cyan",
    Approved: "green",
    Deleted: "red",
    Submitted: "purple",
    Superseded: "pink",
  };
}

export enum ActivityApplicableDefinitionType {
  GENERAL = "General",
  SOLO = "Solo",
  GROUP = "Group",
}
