import "./ActivityWorkflow.css";

import { DownloadDarkIcon, TrashDark } from "../../../../../../assets";
import { ProjectActivitiesConstants } from "../../../../../../constants";
import { WorkflowActivity } from "../../../../../../models/shared/workflowActivityType";
import { StatusCard } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { Button, ProfileMenu, ProfileMenuItem } from "../../../../../../widget";
// TODO: import from assem manager / shared
import { DownloadActivityFilesModal } from "../../../../../developer/components";
import { ActivityProgressIndicator, DeleteActivityModal } from "./components";
import { useActivityWorkflow } from "./useActivityWorkflow";

interface ActivityWorkflowProps {
  activity?: WorkflowActivity;
  projectUuid?: string;
  canManageActivity: boolean;
  hasManageProjectActivityPermission: boolean;
  noActivitiesMessage: string;
}

export const ActivityWorkflow = ({
  activity,
  projectUuid,
  canManageActivity,
  hasManageProjectActivityPermission,
  noActivitiesMessage,
}: ActivityWorkflowProps): JSX.Element => {
  const {
    hasNoSubmitButtonPadding,
    hasNoCompleteButtonPadding,
    workflowRef,
    showDeleteActivityModal,
    setShowDeleteActivityModal,
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    isDocumentDownloading,
    downloadDropdownOptions,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
  } = useActivityWorkflow(activity);
  const downloadActivityButton = (): JSX.Element => {
    return (
      <div
        ref={(el) => {
          if (!el) return;
          setDropdownOffsetX(el.getBoundingClientRect().left);
          setDropdownOffsetY(el.getBoundingClientRect().top);
        }}
      >
        <Button
          size="small"
          text="Download"
          variant="tertiary"
          loadingText="Downloading"
          isLoading={isDocumentDownloading}
          icon={<DownloadDarkIcon />}
          onClick={() => setShowDownloadDropdown(true)}
        />
      </div>
    );
  };

  // TODO: KAN-5060 This may change when we introduce new permissions for different user types
  const conditionalDownloadButton = (): JSX.Element | undefined => {
    return activity?.isDraftVersion || activity?.isCurrentVersion ? downloadActivityButton() : undefined;
  };

  const displayWorkflowButtonsForUsersWithManageAccess = (): JSX.Element => {
    return (
      <>
        {activity?.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS && hasManageProjectActivityPermission && (
          <Button
            onClick={() => setShowDeleteActivityModal(true)}
            text="Delete activity"
            icon={<TrashDark />}
            iconPosition="left"
            variant="reversedPrimary"
            textUnderline
            textBold
            hasNoPadding
            size="small"
          />
        )}
        {activity?.completionPercentage === 100 && activity?.isActivityDataValid && downloadActivityButton()}
      </>
    );
  };

  return (
    <div className="ActivityWorkflowWrapper">
      <div className="ActivityWorkflow" ref={workflowRef}>
        {activity?.status && (
          <div className="ActivityWorkflowStatus">
            <StatusCard status={activity.status} isFixed />
          </div>
        )}
        <div
          className={`ActivitiesWrapper ${
            !canManageActivity && !activity?.isDraftVersion && !activity?.isCurrentVersion ? "Full" : ""
          }`}
        >
          <h3 className="ActivitiesHeader">Activity Progress</h3>
          <div className="Activities">
            {activity && (
              <ActivityProgressIndicator
                activity={activity}
                canManageActivity={canManageActivity}
                hasNoSubmitButtonPadding={hasNoSubmitButtonPadding}
                hasNoCompleteButtonPadding={hasNoCompleteButtonPadding}
                noActivitiesMessage={noActivitiesMessage}
              />
            )}
          </div>
        </div>
        <div className="ActivityWorkflowButtons">
          {canManageActivity ? displayWorkflowButtonsForUsersWithManageAccess() : conditionalDownloadButton()}
        </div>
      </div>
      {showDownloadDropdown && (
        <div style={{ position: "fixed", left: dropdownOffsetX + 250, top: dropdownOffsetY + 22 }}>
          <ProfileMenu show={showDownloadDropdown} onClose={() => setShowDownloadDropdown(false)}>
            {downloadDropdownOptions.map((el) => (
              <ProfileMenuItem
                key={el.id}
                profileMenuItem={el}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  el.action!();
                }}
              />
            ))}
          </ProfileMenu>
        </div>
      )}
      {showDeleteActivityModal && activity?.uuid && projectUuid && (
        <DeleteActivityModal show={showDeleteActivityModal} onClose={() => setShowDeleteActivityModal(false)} />
      )}
      {showDownloadActivityFilesModal && (
        <DownloadActivityFilesModal
          show={showDownloadActivityFilesModal}
          onClose={() => {
            setShowDownloadActivityFilesModal(false);
          }}
        />
      )}
    </div>
  );
};
