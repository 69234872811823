import "./ReviewContent.css";

import { Dispatch, SetStateAction } from "react";

import { ProjectActivitiesConstants } from "../../../../../../constants";
import { SearchActivityHistoryResponse } from "../../../../../../service/query";
import { Button, Column, CursorDataGrid, Divider, DownloadDropdown, Row } from "../../../../../../widget";
import { CompleteActivityModal, ConfirmSubmissionModal } from "../../../../components";
import { ActivityDefinition } from "../../types";
import { isActivityDataValid } from "../../utils";
import { useReviewContent } from "./useReviewContent";

interface ReviewContentProps {
  isEdit?: boolean;
  setShowVersionConflictModal?: Dispatch<SetStateAction<boolean>>;
  setDraftActivityHistoryUuid?: Dispatch<SetStateAction<string | undefined>>;
  setNewVersionActivityHistory?: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>;
  activityDefinition: ActivityDefinition;
  handleMoveToStep: (targetStepKeys: string[]) => void;
}

export const ReviewContent = ({
  isEdit = false,
  setShowVersionConflictModal,
  setDraftActivityHistoryUuid,
  setNewVersionActivityHistory,
  activityDefinition,
  handleMoveToStep,
}: ReviewContentProps): JSX.Element => {
  const {
    activityData,
    columns,
    onChange,
    dataIsLoading,
    definitionData,
    defaultSortingCriteria,
    showConfirmSubmissionModal,
    setShowConfirmSubmissionModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    submitActivity,
    completeActivity,
  } = useReviewContent(
    activityDefinition,
    handleMoveToStep,
    setShowVersionConflictModal,
    setDraftActivityHistoryUuid,
    setNewVersionActivityHistory
  );

  const displayWithOrWithoutSaveForSubmitButton = (): JSX.Element => {
    return activityData?.isDraft ? (
      <Button size="medium" text="Save and Submit" onClick={() => submitActivity()} />
    ) : (
      <Button size="medium" text="Submit" onClick={() => setShowConfirmSubmissionModal(true)} />
    );
  };

  const displayWithOrWithoutSaveForCompleteButton = (): JSX.Element => {
    return activityData?.isDraft ? (
      <Button size="medium" text="Save and Complete" onClick={() => completeActivity()} />
    ) : (
      <Button size="medium" text="Complete" onClick={() => setShowCompleteActivityModal(true)} />
    );
  };
  return (
    <>
      <Row spacingV={isEdit ? "m" : "xl"} className="ActivityReviewHeader">
        <Column span={12}>
          <h1>Review</h1>
        </Column>
      </Row>
      {isEdit && (
        <Row spacingV="xl" className="ActivityReviewHeader">
          <Column span={12}>
            <p className="body1">An activity can only be submitted once all sections are validated and complete</p>
          </Column>
        </Row>
      )}
      {activityData && (
        <div className="ActivityReviewContent">
          <div className="ActivityReviewContentHeader">
            <Row spacingV="l">
              <Column className="ActivityReviewContentTitle" justify="center" align="start" span={6}>
                <h2>{`Version ${activityData.versionNumber} ${activityData.isCurrent ? "(Latest)" : ""}`}</h2>
              </Column>
              <Column span={4} justify="end">
                {isEdit &&
                  activityData.completionPercentage === 1 &&
                  activityData.activity.status === ProjectActivitiesConstants.STATUS_IN_PROGRESS &&
                  isActivityDataValid(activityData?.data.steps) &&
                  (definitionData?.isManuallyApproved
                    ? displayWithOrWithoutSaveForCompleteButton()
                    : displayWithOrWithoutSaveForSubmitButton())}
              </Column>
              <Column span={2}>
                <DownloadDropdown
                  setShowDownloadActivityFilesModal={setShowDownloadActivityFilesModal}
                  showDownloadActivityFilesModal={showDownloadActivityFilesModal}
                  activityHistoryUuid={activityData.uuid}
                  currentOnly={activityData.isCurrent}
                />
              </Column>
            </Row>
            <Row spacingV="ll">
              <Column span={2}>
                <h3>Author</h3>
                <p className="body2">{activityData.createdByUser.fullName}</p>
              </Column>
              <Column span={2}>
                <h3>Variant</h3>
                <p className="body2">{activityData.activity.variant ?? "N/A"}</p>
              </Column>
            </Row>
          </div>
          <Row spacingV="ll">
            <Column span={12}>
              <Divider type="horizontal" />
            </Column>
          </Row>
          <Row spacingV="ml">
            <Column span={12}>
              <h3>Activity documents</h3>
            </Column>
          </Row>
          <CursorDataGrid
            columns={columns}
            clientSide={false}
            filterable
            onChange={onChange}
            data={[]}
            noDataMessage="No activity documents to display"
            dataIsLoading={dataIsLoading}
            defaultSortingCriteria={defaultSortingCriteria}
          />
        </div>
      )}
      {activityData && showConfirmSubmissionModal && (
        <ConfirmSubmissionModal
          show={showConfirmSubmissionModal}
          onClose={() => setShowConfirmSubmissionModal(false)}
          activityUuid={activityData.activity.uuid}
          activityProjectUuid={activityData.activity.project?.uuid}
          activityHistoryUuid={activityData.uuid}
          activityHistoryRowVersion={activityData.rowVersion}
        />
      )}
      {activityData && showCompleteActivityModal && (
        <CompleteActivityModal
          show={showCompleteActivityModal}
          onClose={() => setShowCompleteActivityModal(false)}
          activityUuid={activityData.activity.uuid}
          activityProjectUuid={activityData.activity.project?.uuid}
          activityHistoryUuid={activityData.uuid}
          activityHistoryRowVersion={activityData.rowVersion}
        />
      )}
    </>
  );
};
