import { UploadLightIcon } from "../../../../../assets";
import { Button, Column, CursorDataGrid, Row } from "../../../../../widget";
import { UploadDocumentModal } from "./components/UploadDocumentModal/UploadDocumentModal";
import { useDocuments } from "./useDocuments";

const Documents = (): JSX.Element => {
  const {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    hasManageProjectDocumentPermission,
    uploadNewDocument,
    uploadDocumentUuid,
    latestDocumentVersionUuid,
    refreshDocuments,
    setRefreshDocuments,
    showUploadModal,
    setShowUploadModal,
    onChange,
  } = useDocuments();

  return (
    <div className="DeveloperProjectTabSection">
      <Row spacingV="m">
        <Column span={9}>
          <Row spacingV="xs">
            <Column span={12}>
              <h2>Documents</h2>
            </Column>
          </Row>
          <Row>
            <Column span={12}>
              <p>Project documents will be displayed here. To see the version history, click the ‘details’ icon.</p>
            </Column>
          </Row>
        </Column>
        {hasManageProjectDocumentPermission && (
          <Column span={3}>
            <Row justify="end">
              <Button
                variant="primary"
                onClick={uploadNewDocument}
                text="Upload document"
                iconPosition="left"
                icon={<UploadLightIcon />}
              />
            </Row>
          </Column>
        )}
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        refresh={refreshDocuments}
        noDataMessage="No documents to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
      />
      <UploadDocumentModal
        show={showUploadModal}
        closeModal={() => setShowUploadModal(false)}
        refreshDocuments={refreshDocuments}
        setRefreshDocuments={setRefreshDocuments}
        uploadDocumentLatestVersionUuid={latestDocumentVersionUuid}
        uploadDocumentUuid={uploadDocumentUuid}
      />
    </div>
  );
};

export default Documents;
