import "./ActivitiesTab.css";

import { DownwardArrowLightIcon } from "../../../../../../assets";
import { ProjectPermissionConstants } from "../../../../../../constants";
import { VersionConflictModal } from "../../../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { Button, Column, CursorDataGrid, ProfileMenu, ProfileMenuItem, Row } from "../../../../../../widget";
import { CreateActivityVariantModal } from "../components";
import { useActivities } from "./useActivitiesTab";

export const ActivitiesTab = (): JSX.Element => {
  const {
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    activityDropdownOptions,
    showActivityDropdown,
    setShowActivityDropdown,
    showCreateActivityVariantModal,
    setShowCreateActivityVariantModal,
    createActivityVariantModalData,
    onChange,
    setShowVersionConflictModal,
    showVersionConflictModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
    hasProjectPermission,
    refreshActivities,
  } = useActivities();
  return (
    <div className="DeveloperProjectTabSection ProjectActivitiesTab">
      <Row spacingV="ll">
        <Column span={12}>
          <div className="ActivitiesHeader">
            <h2>Activities</h2>
            {!!activityDropdownOptions.length &&
              hasProjectPermission(ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY) && (
                <div>
                  <Button
                    text="New activity"
                    onClick={() => setShowActivityDropdown(true)}
                    icon={<DownwardArrowLightIcon />}
                  />
                  <ProfileMenu
                    show={showActivityDropdown}
                    onClose={() => setShowActivityDropdown(false)}
                    position={{ top: "0" }}
                  >
                    {activityDropdownOptions.map((el) => (
                      <ProfileMenuItem
                        key={el.id}
                        profileMenuItem={el}
                        onClick={() => {
                          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                          el.action!();
                        }}
                      />
                    ))}
                  </ProfileMenu>
                </div>
              )}
          </div>
        </Column>
      </Row>
      <CursorDataGrid
        columns={columns}
        clientSide={false}
        filterable
        onChange={onChange}
        data={[]}
        noDataMessage="No activities to display"
        dataIsLoading={dataIsLoading}
        defaultSortingCriteria={defaultSortingCriteria}
        refresh={refreshActivities}
      />
      {createActivityVariantModalData && (
        <CreateActivityVariantModal
          show={showCreateActivityVariantModal}
          closeModal={() => setShowCreateActivityVariantModal(false)}
          modalData={createActivityVariantModalData}
        />
      )}
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
    </div>
  );
};
