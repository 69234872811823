import { Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { ActivityDashboardContext, ActivityDiscussionContext } from "../../../../../route/shared/activities-dashboard";
import { updateDiscussionMessage } from "../../../../../service/discussion";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../Toast";
import { Message, MessageThread } from "../../models/Message";

interface useDiscussionMessageProps {
  message?: Message;
  setThreadData: (data: MessageThread) => void;
  threadData?: MessageThread;
  userUuid?: string;
  isThreadResolved?: boolean;
}

type useDiscussionMessageReturnData = {
  editable: boolean;
  editedMessage: string;
  editableErrors: ServiceError[] | undefined;
  showEditMessage: boolean;
  showDeleteMessage: boolean;
  showEditThread: boolean;
  showDeleteThread: boolean;
  showResolveThread: boolean;
  showMarkAsRead: boolean;
  showUnreadIndicator: boolean;

  editMessage: () => void;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setEditedMessage: Dispatch<SetStateAction<string>>;
  setEditableErrors: Dispatch<SetStateAction<ServiceError[] | undefined>>;
  handleEditMessage: (messageUuid: string) => Promise<void>;
  handleMarkAsRead: () => Promise<void>;
  isHandleEditMessageLoading: boolean;
};

export const useDiscussionMessage = ({
  message,
  setThreadData,
  threadData,
  userUuid,
  isThreadResolved,
}: useDiscussionMessageProps): useDiscussionMessageReturnData => {
  const { selectedThreadDetails, refreshDiscussionTables } = useContext(ActivityDiscussionContext);
  const { refreshUnreadDiscussionsTabIndicator } = useContext(ActivityDashboardContext);
  const [editable, setEditable] = useState<boolean>(false);
  const [editedMessage, setEditedMessage] = useState<string>(message?.content || "");
  const [editableErrors, setEditableErrors] = useState<ServiceError[] | undefined>();
  const [isHandleEditMessageLoading, setIsHandleEditMessageLoading] = useState(false);

  const createdByCurrentUser = message?.createdByUserUuid === userUuid;

  const isUnread = message?.isRead === false;
  const showEditMessage = createdByCurrentUser && isUnread;
  const showDeleteMessage = createdByCurrentUser && isUnread;
  const showEditThread = createdByCurrentUser && isUnread;
  const showDeleteThread =
    createdByCurrentUser && !!threadData?.messages.every((m) => m.createdByUserUuid === message?.createdByUserUuid);
  const showMarkAsRead = !createdByCurrentUser && isUnread;
  const showUnreadIndicator = !createdByCurrentUser && isUnread;
  const showResolveThread =
    createdByCurrentUser && !isThreadResolved && !showMarkAsRead && !!threadData?.messages.every((m) => m.isRead);

  useEffect(() => {
    if (selectedThreadDetails === undefined) {
      setEditable(false);
    }
  }, [selectedThreadDetails]);

  const handleEditMessage = useIsLoadingWrapper(async (messageUuid: string): Promise<void> => {
    const res = await updateDiscussionMessage({
      acceptOrReject: null,
      isRead: message?.isRead || false,
      rejectedReason: null,
      content: editedMessage,
      messageUuid,
      rowVersion: message?.rowVersion ?? 0,
    });

    if (res.status === Status.Success) {
      const messagesCopy = [...(threadData?.messages ?? [])];

      const newMessages = messagesCopy.map((m) => {
        if (m.uuid === messageUuid) {
          return {
            ...m,
            content: editedMessage,
          };
        }

        return m;
      });

      setThreadData({
        resolved: threadData?.resolved ?? false,
        messages: newMessages,
      } as MessageThread);
      refreshDiscussionTables();
      setEditable(false);
      setEditableErrors(undefined);
      Toast.success({ message: "Message updated" });
    } else if (res.status === Status.Error && res.errors) {
      setEditableErrors(res.errors);
    }
  }, setIsHandleEditMessageLoading);

  const handleMarkAsRead = async (): Promise<void> => {
    const res = await updateDiscussionMessage({
      acceptOrReject: null,
      isRead: true,
      rejectedReason: null,
      content: message?.content || "",
      messageUuid: message?.uuid || "",
      rowVersion: message?.rowVersion ?? 0,
    });

    refreshUnreadDiscussionsTabIndicator();

    if (res.status === Status.Success) {
      threadData?.messages.map((m) => {
        if (m.uuid === message?.uuid) {
          return {
            ...m,
            isRead: true,
          };
        }

        return m;
      });
      setThreadData({
        resolved: threadData?.resolved ?? false,
        messages: threadData?.messages,
      } as MessageThread);
      refreshDiscussionTables();
      Toast.success({ message: "Message marked as read" });
    } else if (res.status === Status.Error && res.errors) {
      res.errors.forEach((e) => {
        if (e.code === "VALIDATION_MESSAGE_NOT_PUBLISHED") {
          Toast.error({ message: "Unpublished messages cannot be read" });
        } else {
          Toast.error({ message: e.message });
        }
      });
    }
  };

  const editMessage = (): void => {
    setEditable(true);
    setEditedMessage(message?.content || "");
  };

  return {
    editable,
    editedMessage,
    editableErrors,
    showEditMessage,
    showDeleteMessage,
    showEditThread,
    showDeleteThread,
    showResolveThread,
    showMarkAsRead,
    showUnreadIndicator,
    isHandleEditMessageLoading,
    editMessage,
    setEditable,
    setEditedMessage,
    setEditableErrors,
    handleEditMessage,
    handleMarkAsRead,
  };
};
