import "./StatusCard.css";

import { ActivityStatusConstants, ProjectStatusConstants } from "../../../../constants";
import { logError } from "../../../../service/error";

interface StatusCardProps {
  status: string;
  isFixed?: boolean;
  variant?: "Default" | "AssetManager";
}

export const StatusCard = ({ status, isFixed = false, variant = "Default" }: StatusCardProps): JSX.Element => {
  let classNames = isFixed ? "FixedStatusCard" : "StatusCard";
  switch (variant) {
    case "AssetManager":
      classNames += " AssetManager";
      break;
    default:
      break;
  }

  switch (status) {
    case ProjectStatusConstants.VALIDATED:
    case ProjectStatusConstants.VERIFIED:
      classNames += " StatusCard_color_green";
      break;
    case ProjectStatusConstants.UNDER_DEVELOPMENT:
      classNames += " StatusCard_color_coral";
      break;
    case ProjectStatusConstants.DRAFT:
    case ProjectStatusConstants.PRE_DEVELOPMENT:
    case ProjectStatusConstants.NOT_DELIVERED:
    case ProjectStatusConstants.CANCELLED:
      classNames += " StatusCard_color_grey";
      break;

    case ActivityStatusConstants.REJECTED:
    case ActivityStatusConstants.IN_PROGRESS:
      classNames += " StatusCard_color_coral";
      break;
    case ActivityStatusConstants.SUBMITTED:
    case ActivityStatusConstants.APPROVED:
      classNames += " StatusCard_color_green";
      break;
    case ActivityStatusConstants.SUPERSEDED:
      classNames += " StatusCard_color_pink";
      break;
    case ActivityStatusConstants.DELETED:
    default:
      classNames += " StatusCard_color_grey";
      logError({ error: `No card variant could be found for project status ${status}` });
      break;
  }

  return (
    <div className={classNames}>
      {variant === "Default" ? <h3>Status</h3> : <p className="body1">Status</p>}
      {variant === "Default" ? <h2>{status}</h2> : <p className="body01">{status}</p>}
    </div>
  );
};
