import { Dispatch, SetStateAction, useEffect, useState } from "react";

import { downloadGeneratedDocument } from "../../../../../route/developer/activities";
import { downloadActivityDocumentsZip } from "../../../../../service/activity";
import { Status } from "../../../../../service/Shared";
import toast from "../../../../general/Toast/Toast";
import { ProfileMenuItemType } from "../../../../navigation";

interface useProgressIndicatorProps {
  activityHistoryUuid?: string;
  currentOnly: boolean;
  isVersionConflict: () => Promise<boolean>;
}

interface useProgressIndicatorReturnData {
  showConfirmSubmissionModal: boolean;
  setShowConfirmSubmissionModal: Dispatch<SetStateAction<boolean>>;
  showCompleteActivityModal: boolean;
  setShowCompleteActivityModal: Dispatch<SetStateAction<boolean>>;
  showDownloadDropdown: boolean;
  isDocumentDownloading: boolean;
  setShowDownloadDropdown: Dispatch<SetStateAction<boolean>>;
  dropdownOffsetX: number;
  setDropdownOffsetX: Dispatch<SetStateAction<number>>;
  dropdownOffsetY: number;
  setDropdownOffsetY: Dispatch<SetStateAction<number>>;
  downloadDropdownOptions: ProfileMenuItemType[];
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
  submitActivity: () => Promise<void>;
  completeActivity: () => Promise<void>;
}

export const useProgressIndicator = ({
  activityHistoryUuid,
  currentOnly,
  isVersionConflict,
}: useProgressIndicatorProps): useProgressIndicatorReturnData => {
  const [showCompleteActivityModal, setShowCompleteActivityModal] = useState(false);
  const [showConfirmSubmissionModal, setShowConfirmSubmissionModal] = useState(false);
  const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);
  const [showDownloadActivityFilesModal, setShowDownloadActivityFilesModal] = useState(false);
  const [downloadDropdownOptions, setDownloadDropdownOptions] = useState<ProfileMenuItemType[]>([]);
  const [dropdownOffsetX, setDropdownOffsetX] = useState(0);
  const [dropdownOffsetY, setDropdownOffsetY] = useState(0);

  useEffect(() => {
    if (activityHistoryUuid) {
      setDownloadDropdownOptions([
        {
          id: 1,
          value: "Output",
          action: async () => {
            setIsDocumentDownloading(true);
            setShowDownloadDropdown(false);
            await downloadGeneratedDocument({ activityHistoryUuid, currentOnly });

            setIsDocumentDownloading(false);
          },
        },
        {
          id: 2,
          value: "Output + Key documents",
          action: async () => {
            const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: true });

            if (res.status === Status.Error) {
              toast.error({ message: "Could not download documents for this activity!" });

              return;
            }

            setShowDownloadDropdown(false);
            setShowDownloadActivityFilesModal(true);
          },
        },
        {
          id: 3,
          value: "Output + All documents",
          action: async () => {
            const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: false });

            if (res.status === Status.Error) {
              toast.error({ message: "Could not download documents for this activity!" });

              return;
            }

            setShowDownloadDropdown(false);
            setShowDownloadActivityFilesModal(true);
          },
        },
      ]);
    }
  }, [activityHistoryUuid, currentOnly]);

  const submitActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowConfirmSubmissionModal(true);
  };

  const completeActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowCompleteActivityModal(true);
  };

  return {
    showConfirmSubmissionModal,
    setShowConfirmSubmissionModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    isDocumentDownloading,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    downloadDropdownOptions,
    submitActivity,
    completeActivity,
  };
};
