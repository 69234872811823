import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState } from "react";

import { WorkflowActivity } from "../../../../../../models/shared/workflowActivityType";
import { downloadActivityDocumentsZip } from "../../../../../../service/activity";
import { Status } from "../../../../../../service/Shared";
import { ProfileMenuItemType } from "../../../../../../widget";
import toast from "../../../../../../widget/general/Toast/Toast";
// TODO: Import from asset manager / shared
import { downloadGeneratedDocument } from "../../../../../developer/activities";

interface useWorkflowReturnData {
  hasNoCompleteButtonPadding: boolean;
  hasNoSubmitButtonPadding: boolean;
  workflowRef: MutableRefObject<null | HTMLDivElement>;
  showDeleteActivityModal: boolean;
  setShowDeleteActivityModal: Dispatch<SetStateAction<boolean>>;
  showDownloadDropdown: boolean;
  isDocumentDownloading: boolean;
  setShowDownloadDropdown: Dispatch<SetStateAction<boolean>>;
  dropdownOffsetX: number;
  setDropdownOffsetX: Dispatch<SetStateAction<number>>;
  dropdownOffsetY: number;
  setDropdownOffsetY: Dispatch<SetStateAction<number>>;
  downloadDropdownOptions: ProfileMenuItemType[];
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
}

export const useActivityWorkflow = (activity?: WorkflowActivity): useWorkflowReturnData => {
  const workflowRef = useRef<HTMLDivElement>(null);
  const [hasNoSubmitButtonPadding, setHasNoSubmitButtonPadding] = useState(false);
  const [hasNoCompleteButtonPadding, setHasNoCompleteButtonPadding] = useState(false);
  const [showDeleteActivityModal, setShowDeleteActivityModal] = useState(false);

  const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);
  const [isDocumentDownloading, setIsDocumentDownloading] = useState(false);
  const [showDownloadActivityFilesModal, setShowDownloadActivityFilesModal] = useState(false);
  const [downloadDropdownOptions, setDownloadDropdownOptions] = useState<ProfileMenuItemType[]>([]);
  const [dropdownOffsetX, setDropdownOffsetX] = useState(0);
  const [dropdownOffsetY, setDropdownOffsetY] = useState(0);

  useEffect(
    () =>
      workflowRef.current?.offsetWidth && workflowRef.current?.offsetWidth > 786
        ? setHasNoCompleteButtonPadding(false)
        : setHasNoCompleteButtonPadding(true),
    [workflowRef.current?.offsetWidth]
  );

  useEffect(
    () =>
      workflowRef.current?.offsetWidth && workflowRef.current?.offsetWidth > 751
        ? setHasNoSubmitButtonPadding(false)
        : setHasNoSubmitButtonPadding(true),
    [workflowRef.current?.offsetWidth]
  );

  useEffect(() => {
    if (activity?.activityHistoryUuid) {
      const activityHistoryUuid = activity?.activityHistoryUuid;

      setDownloadDropdownOptions([
        {
          id: 1,
          value: "Output",
          action: async () => {
            setIsDocumentDownloading(true);
            setShowDownloadDropdown(false);
            await downloadGeneratedDocument({
              activityHistoryUuid,
              currentOnly: activity?.isCurrentVersion,
            });

            setIsDocumentDownloading(false);
          },
        },
        {
          id: 2,
          value: "Output + Key documents",
          action: async () => {
            const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: true });

            if (res.status === Status.Error) {
              toast.error({ message: "Could not download documents for this activity!" });

              return;
            }

            setShowDownloadDropdown(false);
            setShowDownloadActivityFilesModal(true);
          },
        },
        {
          id: 3,
          value: "Output + All documents",
          action: async () => {
            const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: false });

            if (res.status === Status.Error) {
              toast.error({ message: "Could not download documents for this activity!" });

              return;
            }

            setShowDownloadDropdown(false);
            setShowDownloadActivityFilesModal(true);
          },
        },
      ]);
    }
  }, [activity?.activityHistoryUuid, activity?.isCurrentVersion]);

  return {
    hasNoSubmitButtonPadding,
    hasNoCompleteButtonPadding,
    workflowRef,
    showDeleteActivityModal,
    setShowDeleteActivityModal,
    showDownloadDropdown,
    setShowDownloadDropdown,
    dropdownOffsetX,
    setDropdownOffsetX,
    dropdownOffsetY,
    setDropdownOffsetY,
    isDocumentDownloading,
    downloadDropdownOptions,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
  };
};
