import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProjectActivitiesConstants } from "../../../../constants";
import { transitionActivity } from "../../../../service/activity";
import { getActivityDetails } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { getProjectActivitiesRoute } from "../../../../utils/routes";
import { Toast } from "../../../../widget";
import { ProjectContext } from "../../../shared/projects/project/ProjectContext";
import { isSuccessfulCompleteActivityDraft } from "../../../shared/utils/activities/completeActivity";

interface useCompleteActivityModalProps {
  activityUuid: string;
  activityProjectUuid?: string;
  onClose: () => void;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
}

interface useCompleteActivityModalReturnData {
  handleApprove: () => void;
  handleReject: () => void;
}

export const useCompleteActivityModal = ({
  activityUuid,
  activityProjectUuid,
  onClose,
  activityHistoryUuid,
  activityHistoryRowVersion,
}: useCompleteActivityModalProps): useCompleteActivityModalReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const { projectUuid } = useParams<"projectUuid">();
  const { shouldRefreshActivities } = useContext(ProjectContext);

  const onCompleteClick = async (toStatus: string): Promise<void> => {
    if (activityHistoryRowVersion) {
      const successfullyCompletedDraft = await isSuccessfulCompleteActivityDraft(
        activityHistoryUuid,
        activityHistoryRowVersion
      );

      if (!successfullyCompletedDraft) {
        return;
      }
    }

    // `completeDraft` endpoint also updates the `activity` table, this call is needed to be get latest activity rowVersion
    const activityDetailsRes = await getActivityDetails({ activityUuid });

    if (activityDetailsRes.status === Status.Success && activityDetailsRes.data) {
      const res = await transitionActivity({
        uuid: activityUuid,
        rowVersion: activityDetailsRes.data.rowVersion,
        toStatus,
        fromStatus: ProjectActivitiesConstants.STATUS_IN_PROGRESS,
      });

      if (res.status === Status.Success) {
        if (shouldRefreshActivities) {
          shouldRefreshActivities();
        }

        switch (toStatus) {
          case ProjectActivitiesConstants.STATUS_APPROVED:
            Toast.success({ message: "Activity completed successfully" });
            break;
          case ProjectActivitiesConstants.STATUS_REJECTED:
            Toast.success({ message: "Activity rejected successfully" });
            break;
          default:
            break;
        }

        onClose();

        // TODO not necessary atm
        const redirectLink = getProjectActivitiesRoute(activityProjectUuid || projectUuid || "", currentUserType);
        navigate(redirectLink);
      } else if (res.errors && res.errors.length > 0) {
        Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
      }
    }
  };

  const handleApprove = async (): Promise<void> => {
    await onCompleteClick(ProjectActivitiesConstants.STATUS_APPROVED);
  };

  const handleReject = async (): Promise<void> => {
    await onCompleteClick(ProjectActivitiesConstants.STATUS_REJECTED);
  };

  return {
    handleApprove,
    handleReject,
  };
};
