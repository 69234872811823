/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";

import { ActivityPageParams, ComponentDynamicPosition } from "../../../../../../models";
import { GetDiscussionThreadsResponse } from "../../../../../../service/query";
import { useAuth } from "../../../../../../useAuth";
import { NewThreadInformation } from "../../../../../../widget/wizards/Discussion/components/NewDiscussion/useNewDiscussion";
import { Message, Thread } from "../../../../../../widget/wizards/models";

interface UseComponentRowReturnData {
  addDiscussionRef: RefObject<HTMLDivElement>;
  isAddDiscussionVisible: boolean;
  showUnreadIndicator: boolean;
  isExistingDiscussionVisible: boolean;
  handleOpenExistingDiscussion: () => void;
  handleOpenNewDiscussion: () => void;
  handleCloseDiscussion: () => void;
  newThreadInformation: NewThreadInformation;
  discussionPosition: ComponentDynamicPosition;
  internalThreads: Thread[];
  externalThreads: Thread[];
  setInternalThreads: Dispatch<SetStateAction<Thread[]>>;
}

export const useComponentRow = (
  discussions: GetDiscussionThreadsResponse[] | undefined,
  componentKey: string,
  dataPath: string,
  activityUuid?: string,
  isForRepeater?: boolean,
  repeaterIndex?: number
): UseComponentRowReturnData => {
  const { user } = useAuth();
  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);
  const { activityHistoryUuid } = useParams<ActivityPageParams.activityHistoryUuid>();

  const { currentOrganisationUuid } = useAuth();

  const [isAddDiscussionVisible, setIsAddDiscussionVisible] = useState(false);
  const [isExistingDiscussionVisible, setIsExistingDiscussionVisible] = useState(false);
  const [discussionPosition, setDiscussionPosition] = useState<ComponentDynamicPosition>({ top: 0, left: 0 });

  const [internalThreads, setInternalThreads] = useState<Thread[]>([]);
  const [externalThreads, setExternalThreads] = useState<Thread[]>([]);

  const [showUnreadIndicator, setShowUnreadIndicator] = useState(false);

  const addDiscussionRef = useRef<HTMLDivElement | null>(null);

  const newThreadInformation: NewThreadInformation = {
    componentKey: isForRepeater ? `${componentKey}[${repeaterIndex}]` : componentKey,
    dataPath,
    messageObjectType: "Activity History",
    messageObjectUuid: activityHistoryUuid!,
    messageSourceOrganisationUuid: currentOrganisationUuid!,
    messageTargetOrganisationUuid: currentOrganisationUuid!,
    messageType: "Comment",
    threadObjectUuid: activityUuid!, // `newThreadInformation` is only used for Discussions inside Edit mode of the wizard, where we will have the activityUuid
    visibility: "Internal",
    messageTargetOrganisationRoles: ["Developer", "Guest - Read", "Guest - Write"],
  };

  const updateDiscussionPosition = (): void => {
    const castedRef = addDiscussionRef as MutableRefObject<HTMLDivElement | null>;

    setDiscussionPosition({
      top: (castedRef.current?.offsetTop || 0) - 15,
      left: (castedRef.current?.offsetLeft || 0) - 540,
    });
  };

  useLayoutEffect(() => {
    updateDiscussionPosition();

    const handleResize = (): void => updateDiscussionPosition();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [addDiscussionRef, isAddDiscussionVisible, isExistingDiscussionVisible]);

  useEffect(() => {
    const hasUnreadMessages = internalThreads?.some((t) => {
      return t.messages.some((m) => {
        return m.createdByUserUuid !== userUuid && !m.isRead;
      });
    });
    setShowUnreadIndicator(hasUnreadMessages || false);
  }, [discussions, internalThreads, externalThreads]);

  useEffect(() => {
    const tempThread: Thread[] = [];
    const tempInternalThreads: Thread[] = internalThreads;
    const tempExternalThreads: Thread[] = externalThreads;

    if (discussions) {
      discussions.forEach((t) => {
        tempThread.push({
          uuid: t.uuid,
          resolved: t.isResolved,
          hasNonDeletedMessagesCreatedByOtherUsers: t.hasNonDeletedMessagesCreatedByOtherUsers,
          messages: t.messages.map((tm) => {
            return {
              uuid: tm.uuid,
              content: tm.content,
              createdAt: tm.createdAt,
              isRead: tm.isRead,
              status: tm.status,
              createdByUserUuid: tm.createdByUser.uuid,
              createdByUserFullName: tm.createdByUser.fullName,
              createdByUserAvatarUrl: tm.createdByUser.avatar?.url,
              rowVersion: tm.rowVersion,
            } as Message;
          }),
        });

        const threadToAdd = tempThread.pop()!; // tempThread.pop() will always return an element because we push to it above

        if (t.visibility === "Internal" && !tempInternalThreads.find((temp) => temp.uuid === threadToAdd.uuid)) {
          tempInternalThreads.push(threadToAdd);
        }

        if (t.visibility === "External" && !tempExternalThreads.find((temp) => temp.uuid === threadToAdd.uuid)) {
          tempExternalThreads.push(threadToAdd);
        }
      });
    }

    // Sorts the Array, so that unresolved threads are always the first
    tempInternalThreads.sort((a, b) => {
      return Number(a.resolved) - Number(b.resolved);
    });

    tempExternalThreads.sort((a, b) => {
      return Number(a.resolved) - Number(b.resolved);
    });

    // Spread required so that React will always re-render the component
    setInternalThreads([...tempInternalThreads]);
    setExternalThreads([...tempExternalThreads]);
  }, [discussions]);

  const handleOpenExistingDiscussion = (): void => {
    setIsAddDiscussionVisible(true);
  };

  const handleOpenNewDiscussion = (): void => {
    setIsAddDiscussionVisible(true);
  };

  const handleCloseDiscussion = (): void => {
    setIsAddDiscussionVisible(false);
    setIsExistingDiscussionVisible(false);
  };

  return {
    addDiscussionRef,
    isAddDiscussionVisible,
    isExistingDiscussionVisible,
    showUnreadIndicator,
    handleOpenExistingDiscussion,
    handleOpenNewDiscussion,
    handleCloseDiscussion,
    newThreadInformation,
    discussionPosition,
    internalThreads,
    externalThreads,
    setInternalThreads,
  };
};
