import { Dispatch, SetStateAction, useState } from "react";

interface useProgressIndicatorProps {
  isVersionConflict: () => Promise<boolean>;
}

interface useProgressIndicatorReturnData {
  showConfirmSubmissionModal: boolean;
  setShowConfirmSubmissionModal: Dispatch<SetStateAction<boolean>>;
  showCompleteActivityModal: boolean;
  setShowCompleteActivityModal: Dispatch<SetStateAction<boolean>>;
  submitActivity: () => Promise<void>;
  completeActivity: () => Promise<void>;
}

export const useActivityProgressIndicator = ({
  isVersionConflict,
}: useProgressIndicatorProps): useProgressIndicatorReturnData => {
  const [showCompleteActivityModal, setShowCompleteActivityModal] = useState(false);
  const [showConfirmSubmissionModal, setShowConfirmSubmissionModal] = useState(false);

  const submitActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowConfirmSubmissionModal(true);
  };

  const completeActivity = async (): Promise<void> => {
    const isConflictModalOpen = await isVersionConflict();

    if (isConflictModalOpen) {
      return;
    }

    setShowCompleteActivityModal(true);
  };

  return {
    showConfirmSubmissionModal,
    setShowConfirmSubmissionModal,
    showCompleteActivityModal,
    setShowCompleteActivityModal,
    submitActivity,
    completeActivity,
  };
};
