import { Navigate, Route, Routes } from "react-router-dom";

import { OrganisationPermissionConstants } from "../constants";
import { ActivityPageParams, PrivateActivityDocumentsParams, PrivateProjectDocumentsParams } from "../models";
import { ActivityViewPage, ActivityWizardProvider } from "../route/developer/activities";
import { DevelopmentManagerActivitiesPage } from "../route/development-manager/activities";
import { DevelopmentManagerDashboardPage } from "../route/development-manager/dashboard";
import {
  DevelopmentManagerError403,
  DevelopmentManagerError404,
  DevelopmentManagerError500,
} from "../route/development-manager/errors";
import {
  DevelopmentManagerChangePasswordPage,
  DevelopmentManagerChangePersonalDetailsPage,
} from "../route/development-manager/profile";
import { DevelopmentManagerProjectsPage, ProjectPage } from "../route/development-manager/projects";
import { DevelopmentManagerSettingsPage } from "../route/development-manager/settings";
import { DevelopmentManagerOrganisationTab } from "../route/development-manager/settings/organisation";
import {
  DevelopmentManagerUsersEditPage,
  DevelopmentManagerUsersListPage,
  DevelopmentManagerUsersSendInvitationPage,
} from "../route/development-manager/settings/users";
import {
  ActivityDashboardProvider,
  ActivityDiscussionProvider,
  ActivityDiscussionTab,
  ActivityPage,
  DocumentsTab as ActivityDocumentsTab,
  VersionHistoryTab,
} from "../route/shared/activities-dashboard";
import { ActivityDocumentHistoryPage } from "../route/shared/activities-dashboard/documents";
import {
  DocumentProvider,
  DocumentsTab,
  IssuancesTab,
  OverviewTab,
  ProjectActivitiesTab,
  ProjectDocumentHistoryPage,
  ProjectProvider,
} from "../route/shared/projects";
import { useRouteGuards } from "./guards";

export const DevelopmentManagerRoutes = (): JSX.Element => {
  const { RequireDevelopmentManagerAuth, RequireUserPermission } = useRouteGuards();

  return (
    <Routes>
      <Route
        path="dashboard"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerDashboardPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="activities"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerActivitiesPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/view`}
        element={
          <RequireDevelopmentManagerAuth>
            <ActivityWizardProvider>
              <ActivityViewPage />
            </ActivityWizardProvider>
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path={`activities/:${ActivityPageParams.activityHistoryUuid}/view?:location`}
        element={
          <RequireDevelopmentManagerAuth>
            <ActivityViewPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path={`activity/:${ActivityPageParams.activityUuid}/document/:${PrivateActivityDocumentsParams.activityDocumentUuid}`}
        element={
          <RequireDevelopmentManagerAuth>
            <ActivityDashboardProvider>
              <ActivityDocumentHistoryPage />
            </ActivityDashboardProvider>
          </RequireDevelopmentManagerAuth>
        }
      />

      <Route
        path={`activity/:${ActivityPageParams.activityUuid}/*`}
        element={
          <ActivityDashboardProvider>
            <ActivityPage />
          </ActivityDashboardProvider>
        }
      >
        <Route
          path="discussions"
          element={
            <ActivityWizardProvider>
              <RequireDevelopmentManagerAuth>
                <ActivityDiscussionProvider>
                  <ActivityDiscussionTab />
                </ActivityDiscussionProvider>
              </RequireDevelopmentManagerAuth>
            </ActivityWizardProvider>
          }
        />
        <Route
          path="documents"
          element={
            <RequireDevelopmentManagerAuth>
              <ActivityDocumentsTab />
            </RequireDevelopmentManagerAuth>
          }
        />
        <Route
          path="history"
          element={
            <ActivityWizardProvider>
              <RequireDevelopmentManagerAuth>
                <VersionHistoryTab />
              </RequireDevelopmentManagerAuth>
            </ActivityWizardProvider>
          }
        />
      </Route>
      <Route
        path="projects"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerProjectsPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="projects/:projectUuid/*"
        element={
          <ProjectProvider>
            <ProjectPage />
          </ProjectProvider>
        }
      >
        <Route
          path="overview"
          element={
            <RequireDevelopmentManagerAuth>
              <OverviewTab />
            </RequireDevelopmentManagerAuth>
          }
        />
        <Route
          path="issuances"
          element={
            <RequireDevelopmentManagerAuth>
              <IssuancesTab />
            </RequireDevelopmentManagerAuth>
          }
        />
        <Route
          path="activities"
          element={
            <RequireDevelopmentManagerAuth>
              <ProjectActivitiesTab />
            </RequireDevelopmentManagerAuth>
          }
        />
        <Route
          path="documents"
          element={
            <RequireDevelopmentManagerAuth>
              <DocumentsTab />
            </RequireDevelopmentManagerAuth>
          }
        />
      </Route>
      <Route
        path={`projects/:${PrivateProjectDocumentsParams.projectUuid}/documents/:${PrivateProjectDocumentsParams.projectDocumentUuid}`}
      >
        <Route
          path=""
          element={
            <RequireDevelopmentManagerAuth>
              <ProjectProvider>
                <DocumentProvider>
                  <ProjectDocumentHistoryPage />
                </DocumentProvider>
              </ProjectProvider>
            </RequireDevelopmentManagerAuth>
          }
        />
      </Route>
      <Route
        path="settings/*"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerSettingsPage />
          </RequireDevelopmentManagerAuth>
        }
      >
        <Route
          path="users"
          element={
            <RequireUserPermission
              userPermission={OrganisationPermissionConstants.READ_ORGANISATION_USERS}
              redirectRoute="/dm/settings/organisation"
            >
              <DevelopmentManagerUsersListPage />
            </RequireUserPermission>
          }
        />

        <Route path="organisation" element={<DevelopmentManagerOrganisationTab />} />
        <Route path="" element={<Navigate to="users" replace />} />
      </Route>
      <Route
        path="settings/users/send-invitation"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerUsersSendInvitationPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="settings/users/:userUuid/edit"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerUsersEditPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="profile/change-password"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerChangePasswordPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="profile/change-personal-details"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerChangePersonalDetailsPage />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="403/access-denied"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerError403 />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="500/application-error"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerError500 />
          </RequireDevelopmentManagerAuth>
        }
      />
      <Route
        path="*"
        element={
          <RequireDevelopmentManagerAuth>
            <DevelopmentManagerError404 />
          </RequireDevelopmentManagerAuth>
        }
      />
    </Routes>
  );
};
