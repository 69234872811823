import { useCallback, useContext, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { CursorChangeProps, PrivateActivityDocumentsParams, ResultType } from "../../../../../models";
import { searchActivityDocuments, SearchActivityDocumentsResponse } from "../../../../../service/query";
import { ResultData, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { flattenObject, useIsLoadingWrapper } from "../../../../../utils";
import { getActivityDashboardTabRoute } from "../../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../../widget";
import {
  downloadActivityDocumentToDesktop,
  downloadDocumentCellFormatter,
  formatActivityDocumentHistoryDownloadLink,
} from "../../../../developer/activities";
import { ActivityDashboardContext } from "../../ActivityDashboardContext";

interface UseDocumentHistoryReturnData {
  activityDisplayName?: string;
  documentType?: string;
  documentVariant?: string;
  onDownloadLatest: () => void;
  handleBackClick: () => void;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  isOnDownloadLatestLoading: boolean;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useDocumentHistory = (): UseDocumentHistoryReturnData => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { currentUserType } = useAuth();

  const { activityDetails, activityUuid } = useContext(ActivityDashboardContext);
  const { activityDocumentUuid } = useParams<PrivateActivityDocumentsParams>();
  const [latestVersionUuid, setLatestVersionUuid] = useState<string>();
  const [documentType, setDocumentType] = useState<string>();
  const [documentVariant, setDocumentVariant] = useState<string>();

  const [isOnDownloadLatestLoading, setIsOnDownloadLatestLoading] = useState(false);

  const handleBackClick = (): void => {
    navigate(
      getActivityDashboardTabRoute(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        activityUuid!,
        "documents",
        currentUserType,
        searchParams.get("projectUuid") ? `projectUuid=${searchParams.get("projectUuid")}` : ""
      )
    );
  };

  const fetchLatestVersionDownloadLink = async (): Promise<void> => {
    const filterCriteria = dataGridMapFilterCriteria([]);

    const res = await searchActivityDocuments({
      paging: {
        limit: 1,
        beforeCursor: null,
        afterCursor: null,
      },
      filter: { results: filterCriteria },
      currentOnly: true,
      activityDocumentHistoryUuid: activityDocumentUuid ?? null,
      activityHistoryUuid: null,
      activityUuid: null,
    });

    if (res.status === Status.Success && res.data) {
      if (res.data.results.length < 1) return;
      const firstItem = res.data.results[0];
      setLatestVersionUuid(firstItem.activityDocument.uuid ?? "");
      setDocumentType(firstItem?.activityDefinitionDocumentType?.displayName);
      setDocumentVariant(firstItem?.activityDocument?.variant ?? "");
    }
  };

  const onDownloadLatest = useIsLoadingWrapper(async (): Promise<void> => {
    await downloadActivityDocumentToDesktop(latestVersionUuid ?? "");
  }, setIsOnDownloadLatestLoading);

  useEffect(() => {
    if (!activityUuid) return;
    fetchLatestVersionDownloadLink();
  }, [activityUuid]);

  const [dataIsLoading, setDataIsLoading] = useState(true);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    {
      key: "activityDocument.versionNumber",
      name: "Version",
      dataType: "number",
      formatter: "align",
      alignment: "center",
    },
    {
      key: "file.filename",
      name: "Filename",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    {
      key: "activityDocument.createdAt",
      name: "Date uploaded",
      dataType: "Date",
      formatter: "dateOnly",
      alignment: "center",
      filterable: false,
    },
    {
      key: "activityDocument.createdByUser.fullName",
      name: "Uploaded by",
      dataType: "string",
      filterable: true,
      sortable: true,
    },
    {
      key: "downloadIcon",
      name: "Download",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: downloadDocumentCellFormatter,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [{ columnKey: "activityDocument.versionNumber", direction: "DESC" }];

  const formatData = useCallback(
    (responseData: SearchActivityDocumentsResponse | undefined): ResultData[] => {
      return (
        responseData?.results?.map((d) => {
          const result = flattenObject(d);
          result.downloadIcon = formatActivityDocumentHistoryDownloadLink({
            activityDocumentHistoryUuid: d.activityDocument.uuid,
          });

          return result;
        }) || []
      );
    },
    [activityUuid]
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!activityDocumentUuid) throw new Error("Uuid must not be empty!");

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activityDefinitionDocumentType = {
      ...filterCriteria.activityDefinitionDocumentType,
      isGenerated: {
        operator: "eq",
        value: false,
      },
    };

    await searchActivityDocuments({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
      currentOnly: false,
      activityDocumentHistoryUuid: activityDocumentUuid ?? null,
      activityHistoryUuid: null,
      activityUuid: null,
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  return {
    activityDisplayName: activityDetails?.activityDefinition.displayName,
    documentType,
    documentVariant,
    onDownloadLatest,
    handleBackClick,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    isOnDownloadLatestLoading,
    onChange,
  };
};
