import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { SortColumn } from "react-data-grid";
import { useNavigate, useSearchParams } from "react-router-dom";

import { CursorChangeProps, ResultType } from "../../../../../../../../models";
import {
  getActivityHistoryDetails,
  GetActivityHistoryDetailsResponse,
  searchActivityDocuments,
  SearchActivityDocumentsResponse,
  searchActivityHistory,
  SearchActivityHistoryResponse,
} from "../../../../../../../../service/query";
import { ResultData, Status } from "../../../../../../../../service/Shared";
import { useAuth } from "../../../../../../../../useAuth";
import { flattenObject } from "../../../../../../../../utils";
import { getActivityViewRoute } from "../../../../../../../../utils/routes";
import {
  DataGridColumnDefinition,
  DataGridColumnDefinitionWithCustomCellFormatter,
  dataGridMapFilterCriteria,
} from "../../../../../../../../widget";
import { resumeActivity } from "../../../../../../../shared/utils";
import { downloadDocumentCellFormatter } from "../../../../components";
import { formatActivityDocumentHistoryDownloadLink } from "../../../../utils";

interface useDetailsPanelReturnData {
  activityData?: GetActivityHistoryDetailsResponse;
  onView: () => void;
  onEdit: () => void;
  columns: DataGridColumnDefinition[];
  defaultSortingCriteria: SortColumn[];
  dataIsLoading: boolean;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  showVersionConflictModal: boolean;
  showDownloadActivityFilesModal: boolean;
  setShowDownloadActivityFilesModal: Dispatch<SetStateAction<boolean>>;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
  onChange: ({ filtering, paging, sorting }: CursorChangeProps) => Promise<{
    resultData: ResultData[];
    paging: {
      pageSize: number;
      totalCount: number;
      startCursor: string;
      endCursor: string;
      hasNextPage: boolean;
      hasPreviousPage: boolean;
    };
  }>;
}

export const useDetailsPanel = (activityHistoryUuid: string): useDetailsPanelReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();
  const [searchParams] = useSearchParams();

  const [activityData, setActivityData] = useState<GetActivityHistoryDetailsResponse>();
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();
  const [showDownloadActivityFilesModal, setShowDownloadActivityFilesModal] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: (DataGridColumnDefinition | DataGridColumnDefinitionWithCustomCellFormatter<any>)[] = [
    { key: "activityDefinitionDocumentType.displayName", name: "Type", dataType: "string" },
    {
      key: "file.filename",
      name: "Filename",
      dataType: "string",
      formatter: "stringWithEllipsis",
      filterable: true,
      sortable: true,
      minWidth: 150,
    },
    { key: "activityDocument.variant", name: "Variant", dataType: "string" },
    {
      key: "activityDefinitionVersionDocumentType.isKey",
      name: "Key document",
      dataType: "boolean",
      formatter: "yesNo",
      alignment: "center",
      // TODO: Add filtering support for booleans
      filterable: false,
      sortable: true,
    },
    {
      key: "activityDocument.versionNumber",
      name: "Version",
      dataType: "number",
      formatter: "align",
      alignment: "center",
      minWidth: 150,
    },
    {
      key: "activityDocument.createdAt",
      name: "Date uploaded",
      dataType: "Date",
      formatter: "dateOnly",
      minWidth: 150,
      filterable: false,
    },
    { key: "activityDocument.createdByUser.fullName", name: "Uploaded by", dataType: "string", minWidth: 150 },
    {
      key: "downloadIcon",
      name: "Download latest",
      dataType: "string",
      formatter: "custom",
      alignment: "center",
      filterable: false,
      sortable: false,
      customCellFormatter: downloadDocumentCellFormatter,
    },
  ];

  const defaultSortingCriteria: SortColumn[] = [
    { columnKey: "activityDefinitionDocumentType.displayName", direction: "ASC" },
  ];

  const formatData = useCallback(
    (responseData: SearchActivityDocumentsResponse | undefined): ResultData[] =>
      responseData?.results?.map((el) => {
        const result = flattenObject(el);

        result.downloadIcon = formatActivityDocumentHistoryDownloadLink({
          activityDocumentHistoryUuid: el.activityDocument.uuid,
        });

        return result;
      }) || [],
    []
  );

  const onChange = async ({ filtering, paging, sorting }: CursorChangeProps): Promise<ResultType> => {
    if (!activityHistoryUuid) throw new Error("Uuid must not be empty!");

    const filterCriteria = dataGridMapFilterCriteria(filtering);

    filterCriteria.activityDefinitionDocumentType = {
      ...filterCriteria.activityDefinitionDocumentType,
      isGenerated: {
        operator: "eq",
        value: false,
      },
    };

    let data: ResultType = {
      resultData: [],
      paging: {
        pageSize: 0,
        totalCount: 0,
        startCursor: "",
        endCursor: "",
        hasNextPage: false,
        hasPreviousPage: false,
      },
    };

    await searchActivityDocuments({
      paging: {
        limit: paging.pageSize,
        beforeCursor: paging.beforeCursor || null,
        afterCursor: paging.afterCursor || null,
      },
      /* eslint-disable @typescript-eslint/no-explicit-any */
      sort: sorting.map((s: { key: any; direction: any }) => ({
        key: s.key as any,
        direction: s.direction,
      })),
      filter: { results: filterCriteria },
      currentOnly: true,
      activityDocumentHistoryUuid: null,
      activityHistoryUuid,
      activityUuid: null,
    })
      .then((response) => {
        data = {
          resultData: formatData(response.data),
          paging: {
            startCursor: response.data?.paging?.startCursor || "",
            endCursor: response.data?.paging?.endCursor || "",
            pageSize: paging.pageSize || 10,
            totalCount: response.data?.paging?.total || 0,
            hasNextPage: response.data?.paging?.hasNextPage || false,
            hasPreviousPage: response.data?.paging?.hasPreviousPage || false,
          },
        };
      })
      .finally(() => {
        setDataIsLoading(false);
      });

    return data;
  };

  const getAndSetActivityData = async (uuid: string): Promise<void> => {
    const response = await getActivityHistoryDetails({ activityHistoryUuid: uuid });

    if (response.status === Status.Success && response.data) {
      setActivityData(response.data);
    }
  };

  const onView = (): void => {
    navigate(
      getActivityViewRoute(
        activityHistoryUuid,
        currentUserType,
        searchParams.get("projectUuid") ? `projectUuid=${searchParams.get("projectUuid")}` : ""
      )
    );
  };

  const onEdit = async (): Promise<void> => {
    if (activityData) {
      const filterCriteria = dataGridMapFilterCriteria([]);

      filterCriteria.activity = {
        uuid: {
          operator: "eq",
          value: activityData.activity.uuid,
        },
      };

      filterCriteria.isDraft = {
        operator: "eq",
        value: true,
      };

      const res = await searchActivityHistory({
        activityUuid: null,
        paging: {
          afterCursor: null,
          beforeCursor: null,
          limit: 1,
        },

        filter: { results: filterCriteria },
      });

      if (res.status === Status.Success && res.data) {
        const existingDraft = res.data.results.length > 0 ? res.data.results[0] : undefined;

        await resumeActivity(
          navigate,
          setDraftActivityHistoryUuid,
          setNewVersionActivityHistory,
          setShowVersionConflictModal,
          activityData.activity.uuid,
          existingDraft?.uuid,
          existingDraft?.versionNumber
        );
      }
    }
  };

  useEffect(() => {
    if (activityHistoryUuid) {
      getAndSetActivityData(activityHistoryUuid);
    }
    return () => {
      setActivityData(undefined);
    };
  }, [activityHistoryUuid]);

  return {
    activityData,
    onView,
    onEdit,
    columns,
    defaultSortingCriteria,
    dataIsLoading,
    onChange,
    showVersionConflictModal,
    setShowVersionConflictModal,
    showDownloadActivityFilesModal,
    setShowDownloadActivityFilesModal,
    newVersionActivityHistory,
    draftActivityHistoryUuid,
  };
};
