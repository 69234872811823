import { OrganisationTypeConstants } from "../../../../constants";
import { FileType } from "../../../../models";
import { DeveloperSingleCardWithTabsTemplate } from "../../../../templates";
import { VersionConflictModal } from "../../../../templates/DeveloperProjectCardWithTabsTemplate/components";
import { ActivityWorkflow } from "./components";
import { useActivityOverviewPage } from "./useActivityOverviewPage";

export const ActivityOverviewPage = (): JSX.Element => {
  const {
    tabsList,
    handleBackClick,
    activityDetails,
    projectDetails,
    workflowActivity,
    setShowVersionConflictModal,
    showVersionConflictModal,
    draftActivityHistoryUuid,
    newVersionActivityHistory,
    canManageActivity,
    hasManageProjectActivityPermission,
    currentUserType,
    backButtonText,
  } = useActivityOverviewPage();

  const workflow = (): JSX.Element => {
    return (
      <ActivityWorkflow
        activity={workflowActivity}
        projectUuid={activityDetails?.project.uuid}
        canManageActivity={canManageActivity}
        noActivitiesMessage="Loading activity details..."
        hasManageProjectActivityPermission={hasManageProjectActivityPermission}
      />
    );
  };

  if (!projectDetails || !activityDetails) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <></>;
  }

  return (
    <>
      {DeveloperSingleCardWithTabsTemplate({
        activeElement: "Activities",
        pageTitle: activityDetails?.activityDefinition?.displayName ?? "Activity",
        titlePill: projectDetails?.standard?.displayName,
        developer:
          currentUserType !== OrganisationTypeConstants.DEVELOPER ? projectDetails?.developer.displayName : undefined,
        developerIcon:
          currentUserType !== OrganisationTypeConstants.DEVELOPER
            ? projectDetails?.developer.files.find((f) => f.type === FileType.SmallLogo)?.file.url
            : undefined,
        tabsList,
        workflow: workflow(),
        backButtonText,
        handleBackClick,
      })}
      <VersionConflictModal
        showVersionConflictModal={showVersionConflictModal}
        draftActivityHistoryUuid={draftActivityHistoryUuid}
        closeModal={() => setShowVersionConflictModal(false)}
        newVersionActivityHistory={newVersionActivityHistory}
      />
    </>
  );
};
