import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { OrganisationTypeConstants } from "../../../../../../constants";
import { Origin } from "../../../../../../models";
import { GetGroupDetailsResponse, GetProjectDetailsResponse } from "../../../../../../service/query";
import { ServiceError } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import {
  getProjectAuditHistoryRoute,
  getProjectDetailsByUuid,
  getSettingsAuditHistoryRoute,
} from "../../../../../../utils/routes";
import { GroupContext } from "./GroupContext";

interface UseManageProjectGroupReturnData {
  groupDetails?: GetGroupDetailsResponse;
  projectDetails?: GetProjectDetailsResponse;
  backButtonText: string;
  errors: ServiceError[] | undefined;
  handleBackClick: () => void;
}

export const useManageProjectGroup = (): UseManageProjectGroupReturnData => {
  const navigate = useNavigate();
  const { currentUserType } = useAuth();

  const { groupDetails, origin, currentProjectUuid, errors } = useContext(GroupContext);

  const backButtonText =
    origin === Origin.ProjectAuditPage || origin === Origin.OrganisationAuditPage ? "Back to audit" : "Back to project";

  const handleBackClick = (): void => {
    if (origin === Origin.ProjectAuditPage && currentProjectUuid) {
      navigate(getProjectAuditHistoryRoute(currentProjectUuid, currentUserType));
    } else if (origin === Origin.OrganisationAuditPage) {
      navigate(getSettingsAuditHistoryRoute(currentUserType));
    } else if (currentProjectUuid) {
      navigate(getProjectDetailsByUuid(currentProjectUuid, OrganisationTypeConstants.DEVELOPER));
    }
  };

  return {
    groupDetails,
    backButtonText,
    errors,
    handleBackClick,
  };
};
