import { Dispatch, ForwardedRef, SetStateAction, useEffect, useImperativeHandle, useState } from "react";

import { StepState } from "../../../../../models";
import { StepProps } from "./Step";

export type SubStep = {
  key: string;
  valid: boolean | undefined;
  current: boolean;
  stepName: string;
  inProgress: boolean;
};

interface useStepProps {
  step: StepProps;
  handleMoveToStep?: (stepKeys: string[]) => void;
  isReview?: boolean;
  clickedSubStep: string[] | undefined;
  setClickedSubStep: Dispatch<SetStateAction<string[] | undefined>>;
}

interface useStepReturnData {
  showSubSteps: boolean;
  setShowSubSteps: Dispatch<SetStateAction<boolean>>;
  stepState: StepState;
  onStepSelect: () => void;
  onSubStepSelect: (subStepKey: string) => void;
}

export const useStep = (
  { step, handleMoveToStep, isReview, clickedSubStep, setClickedSubStep }: useStepProps,
  forwardedRef: ForwardedRef<unknown>
): useStepReturnData => {
  const [showSubSteps, setShowSubSteps] = useState(step.current);

  const checkCurrentSubstep = (): boolean => {
    let checkCurrent = false;
    if (step.current === true) checkCurrent = true;
    step.subSteps.forEach((subStep) => {
      if (subStep.current === true) checkCurrent = true;
    });
    return checkCurrent;
  };

  useImperativeHandle(forwardedRef, () => ({
    resetSubSteps: () => {
      // Currently decides whether step should be expanded based on substep.current, could be based on step.current
      setShowSubSteps(checkCurrentSubstep);
    },
  }));

  let stepState: StepState = StepState.NotStarted;

  if (step.subSteps.length) {
    const validationStatues = step.subSteps.map((el) => el?.valid);
    const inProgressStatus = step.subSteps.some((el) => el.inProgress === true);

    if (inProgressStatus || validationStatues.some((el) => el !== undefined)) {
      stepState = StepState.InProgress;
    }

    if (validationStatues.every((el) => el === true)) {
      stepState = StepState.Complete;
    }

    if (validationStatues.some((el) => el === false)) {
      stepState = StepState.Invalid;
    }
  } else {
    if (step.valid) {
      stepState = StepState.Complete;
    }

    if (step.valid === false) {
      stepState = StepState.Invalid;
    }
  }

  const onSubStepSelect = (subStepKey: string): void => {
    if (clickedSubStep && clickedSubStep[0] === step.key && clickedSubStep[1] === subStepKey) {
      return;
    }
    if (handleMoveToStep) {
      handleMoveToStep([step.key, subStepKey]);
      setClickedSubStep([step.key, subStepKey]);
    }
  };

  const onStepSelect = (): void => {
    if (clickedSubStep && clickedSubStep[0] === step.key) {
      return;
    }

    if (handleMoveToStep) {
      handleMoveToStep([step.key]);
      setClickedSubStep([step.key]);
    }
  };

  useEffect(() => {
    if (isReview) setShowSubSteps(false);
  }, [isReview]);

  return {
    showSubSteps,
    setShowSubSteps,
    stepState,
    onStepSelect,
    onSubStepSelect,
  };
};
