import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ProjectActivitiesConstants } from "../../../../constants";
import { downloadActivityDocumentsZip, transitionActivity } from "../../../../service/activity";
import { getActivityDetails } from "../../../../service/query";
import { Status } from "../../../../service/Shared";
import { getErrorMessageFromCode } from "../../../../service/ValidationErrorFormatter";
import { useAuth } from "../../../../useAuth";
import { getDashboardRoute, getProjectActivitiesRoute } from "../../../../utils/routes";
import { Toast } from "../../../../widget";
import toast from "../../../../widget/general/Toast/Toast";
import { ProjectContext } from "../../../shared/projects/project/ProjectContext";
import { isSuccessfulCompleteActivityDraft } from "../../../shared/utils/activities/completeActivity";

interface useConfirmSubmissionModalProps {
  activityUuid: string;
  activityProjectUuid?: string;
  onClose: () => void;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
}
interface useConfirmSubmissionModalReturnData {
  onConfirmClick: () => void;
  onDownloadClick: () => void;
  showDownloadActivityFilesInformationBox: boolean;
}

export const useConfirmSubmissionModal = ({
  activityUuid,
  activityProjectUuid,
  onClose,
  activityHistoryUuid,
  activityHistoryRowVersion,
}: useConfirmSubmissionModalProps): useConfirmSubmissionModalReturnData => {
  const navigate = useNavigate();
  const { projectUuid } = useParams<"projectUuid">();
  const { shouldRefreshActivities } = useContext(ProjectContext);
  const { currentUserType } = useAuth();

  const [showDownloadActivityFilesInformationBox, setShowDownloadActivityFilesInformationBox] = useState(false);

  const onConfirmClick = async (): Promise<void> => {
    if (activityHistoryRowVersion) {
      const successfullyCompletedDraft = await isSuccessfulCompleteActivityDraft(
        activityHistoryUuid,
        activityHistoryRowVersion
      );

      if (!successfullyCompletedDraft) {
        return;
      }
    }

    // `completeDraft` endpoint also updates the `activity` table, this call is needed to be get latest activity rowVersion
    const activityDetailsRes = await getActivityDetails({ activityUuid });

    if (activityDetailsRes.status === Status.Success && activityDetailsRes.data) {
      const res = await transitionActivity({
        uuid: activityUuid,
        rowVersion: activityDetailsRes.data.rowVersion,
        toStatus: ProjectActivitiesConstants.STATUS_SUBMITTED,
        fromStatus: ProjectActivitiesConstants.STATUS_IN_PROGRESS,
      });
      if (res.status === Status.Success) {
        if (shouldRefreshActivities) {
          shouldRefreshActivities();
        }

        onClose();
        toast.success({ message: "Activity submitted successfully" });

        const uuid = activityProjectUuid || projectUuid;

        if (uuid) {
          navigate(getProjectActivitiesRoute(uuid, currentUserType));
        } else if (window?.history?.state?.idx === 0) {
          navigate(getDashboardRoute(currentUserType), { replace: true });
        } else {
          navigate(-1);
        }
      } else if (res.errors && res.errors.length > 0) {
        Toast.error({ message: getErrorMessageFromCode(res.errors[0].message) });
      }
    }
  };

  const onDownloadClick = async (): Promise<void> => {
    const res = await downloadActivityDocumentsZip({ activityHistoryUuid, isKey: false });

    if (res.status === Status.Error) {
      toast.error({ message: "Could not download documents for this activity!" });

      return;
    }

    setShowDownloadActivityFilesInformationBox(true);
  };

  return {
    onConfirmClick,
    onDownloadClick,
    showDownloadActivityFilesInformationBox,
  };
};
