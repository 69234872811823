import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import { ProjectPermissionConstants } from "../../../constants";
import { ActivityPageParams } from "../../../models";
import {
  getActivityDetails,
  GetActivityDetailsResponse,
  getCurrentUserProjectPermissions,
  SearchActivityHistoryResponse,
} from "../../../service/query";
import { fetchHasUnreadMessages } from "../../../service/query/QueryService.full";
import { Status } from "../../../service/Shared";
import { useAuth } from "../../../useAuth";
import { hasActivityPermissionForProject } from "../../../utils";

interface ActivityDashboardProviderProps {
  children?: JSX.Element | JSX.Element[];
}

interface ActivityDashboardContextType {
  activityUuid: string | undefined;
  activityDetails: GetActivityDetailsResponse | undefined;
  setShowVersionConflictModal: Dispatch<SetStateAction<boolean>>;
  setNewVersionActivityHistory: Dispatch<SetStateAction<SearchActivityHistoryResponse | undefined>>;
  setDraftActivityHistoryUuid: Dispatch<SetStateAction<string | undefined>>;
  setShowDetailsPanel: Dispatch<SetStateAction<boolean>>;
  onCloseDetailsPanel: () => void;
  setHasUnreadDiscussions: (val: boolean) => void;
  refreshUnreadDiscussionsTabIndicator: () => void;
  showVersionConflictModal: boolean;
  hasUnreadDiscussions: boolean;
  showDetailsPanel: boolean;
  hasManageProjectActivityPermission: boolean;
  newVersionActivityHistory?: SearchActivityHistoryResponse;
  draftActivityHistoryUuid?: string;
}

export const ActivityDashboardContext = createContext<ActivityDashboardContextType>({} as ActivityDashboardContextType);

export const ActivityDashboardProvider = ({ children }: ActivityDashboardProviderProps): JSX.Element => {
  const { user } = useAuth();

  const userUuid = useMemo(() => user?.userUuid ?? "", [user]);

  const { activityUuid: rawActivityUuid } = useParams<ActivityPageParams>();
  const [activityDetails, setActivityDetails] = useState<GetActivityDetailsResponse>();

  const activityUuid = useMemo(() => rawActivityUuid, [rawActivityUuid]);

  const [hasManageProjectActivityPermission, setHasManageProjectActivityPermission] = useState(false);
  const [showVersionConflictModal, setShowVersionConflictModal] = useState(false);
  const [newVersionActivityHistory, setNewVersionActivityHistory] = useState<SearchActivityHistoryResponse>();
  const [draftActivityHistoryUuid, setDraftActivityHistoryUuid] = useState<string>();
  const [hasUnreadDiscussions, setHasUnreadDiscussions] = useState(false);

  const [showDetailsPanel, setShowDetailsPanel] = useState(false);
  const onCloseDetailsPanel = (): void => {
    setShowDetailsPanel(false);
  };

  const refreshUnreadDiscussionsTabIndicator = async (): Promise<void> => {
    if (activityUuid) {
      const hasUnread = await fetchHasUnreadMessages(activityUuid, userUuid);
      setHasUnreadDiscussions(hasUnread);
    }
  };

  const fetchActivityDetails = useCallback(
    async (uuid: string) => {
      const response = await getActivityDetails({ activityUuid: uuid });

      if (response.status === Status.Success && response.data) {
        setActivityDetails(response.data);

        const projectUuid = response.data?.project.uuid;
        if (!projectUuid?.length) {
          return;
        }

        await getCurrentUserProjectPermissions({ projectUuids: [response.data.project.uuid] }).then((permissions) => {
          if (permissions.status === Status.Success && permissions.data) {
            setHasManageProjectActivityPermission(
              hasActivityPermissionForProject(
                permissions.data,
                response.data?.project.uuid,
                ProjectPermissionConstants.MANAGE_PROJECT_ACTIVITY
              )
            );
          }
        });
      }
      await refreshUnreadDiscussionsTabIndicator();
    },
    [activityUuid]
  );

  useEffect(() => {
    if (userUuid && activityUuid && activityUuid.length > 10) {
      fetchActivityDetails(activityUuid);
    }
  }, [activityUuid, userUuid]);

  const memoisedValue = useMemo(
    () => ({
      activityUuid,
      activityDetails,

      hasManageProjectActivityPermission,
      showVersionConflictModal,
      newVersionActivityHistory,
      draftActivityHistoryUuid,
      showDetailsPanel,
      hasUnreadDiscussions,

      setShowVersionConflictModal,
      setNewVersionActivityHistory,
      setDraftActivityHistoryUuid,
      onCloseDetailsPanel,
      setShowDetailsPanel,
      setHasUnreadDiscussions,
      refreshUnreadDiscussionsTabIndicator,
    }),
    [
      hasUnreadDiscussions,
      activityUuid,
      activityDetails,
      showDetailsPanel,
      showVersionConflictModal,
      newVersionActivityHistory,
      draftActivityHistoryUuid,
      hasManageProjectActivityPermission,
    ]
  );

  return <ActivityDashboardContext.Provider value={memoisedValue}>{children}</ActivityDashboardContext.Provider>;
};
