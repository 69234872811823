import "./DiscussionTab.css";

import { Button, Column, DeleteDiscussionModal, Row, Select } from "../../../../../widget";
import { DiscussionDetails } from "../discussion-details";
import { DiscussionTable } from "../discussion-table";
import { NewDiscussionModal } from "./components";
import { useDiscussionTab } from "./useDiscussionTab";

export const DiscussionTab = (): JSX.Element => {
  const {
    columns,
    showNewDiscussionModal,
    setShowNewDiscussionModal,
    discussionTableVisible,
    handleShowTableHideDetails,
    selectTableRowAndHideDetails,
    resolveViewOptions,
    selectedResolveViewOption,
    changeSelectedResolveViewOption,
    showDeleteDiscussionModal,
    isConfirmDeleteLoading,
    confirmDelete,
    cancelDelete,
    canWriteDiscussion,
  } = useDiscussionTab();

  const header = (): JSX.Element => (
    <Row spacingV="ml">
      <Column span={12}>
        <div className="DiscussionHeader">
          <h2>Discussions &amp; Suggestions</h2>
          {canWriteDiscussion && <Button text="New discussion" onClick={() => setShowNewDiscussionModal(true)} />}
        </div>
      </Column>
    </Row>
  );

  const filters = (): JSX.Element => (
    <div className="DiscussionTabFilters">
      <div>
        <Select
          label="Show"
          data={resolveViewOptions}
          value={selectedResolveViewOption}
          onChange={changeSelectedResolveViewOption}
        />
      </div>
    </div>
  );

  return (
    <div className="DeveloperProjectTabSection ActivityDiscussionTab">
      {header()}
      {filters()}
      <DiscussionTable selectRow={selectTableRowAndHideDetails} columns={columns} visible={discussionTableVisible} />
      <DiscussionDetails
        onHideDiscussion={handleShowTableHideDetails}
        visible={!discussionTableVisible}
        canReply={canWriteDiscussion}
      />
      <NewDiscussionModal show={showNewDiscussionModal} closeModal={() => setShowNewDiscussionModal(false)} />
      {showDeleteDiscussionModal && (
        <DeleteDiscussionModal
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          isOnConfirmLoading={isConfirmDeleteLoading}
        />
      )}
    </div>
  );
};
