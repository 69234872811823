import { FormEvent, useCallback, useEffect, useState } from "react";

import { SelectData } from "../../../../../models";
import { createProject } from "../../../../../service/project";
import { searchStandards } from "../../../../../service/query";
import { ServiceError, Status } from "../../../../../service/Shared";
import { useAuth } from "../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../utils";
import { Toast } from "../../../../../widget";

type CodesWithProjectTypesData = {
  codeDisplayName: string | null;
  codeUuid: string;
  projectTypes: {
    displayName: string;
    uuid: string;
  }[];
}[];

interface UseCreateProjectModalReturnData {
  errors: ServiceError[] | undefined;
  codeData: SelectData | undefined;
  projectTypeData: SelectData;
  projectTypeValue: string | null;
  formData: CreateProjectModalFormData;
  displayProjectTypeSelect: boolean;
  isHandleSubmitLoading: boolean;
  onProjectNameChange: (value: string) => void;
  onCodeChange: (value: string) => void;
  onProjectTypeChange: (value: string) => void;
  handleClose: () => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
}

interface CreateProjectModalFormData {
  projectName: string;
  codeUuid: string;
  projectTypeUuid: string;
}

export const useCreateProjectModal = (closeModal: () => void): UseCreateProjectModalReturnData => {
  const { currentDeveloperUuid } = useAuth();

  const [codesSelectData, setCodesSelectData] = useState<SelectData>();
  const [codesWithProjectTypes, setCodesWithProjectTypes] = useState<CodesWithProjectTypesData>([]);
  const [projectTypeSelectData, setProjectTypeSelectData] = useState<SelectData>([]);
  const [projectTypeValue, setProjectTypeValue] = useState<string | null>(null);
  const [displayProjectTypeSelect, setDisplayProjectTypeSelect] = useState<boolean>(false);
  const [formData, setFormData] = useState<CreateProjectModalFormData>({
    projectName: "",
    codeUuid: "",
    projectTypeUuid: "",
  });
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleSubmitLoading, setIsHandleSubmitLoading] = useState(false);

  const onProjectNameChange = (value: string): void => {
    setFormData({
      ...formData,
      projectName: value,
    });
  };

  const onCodeChange = (value: string): void => {
    setFormData({
      ...formData,
      codeUuid: value,
    });
    setProjectTypeValue(null);
  };

  const onProjectTypeChange = (value: string): void => {
    setFormData({
      ...formData,
      projectTypeUuid: value,
    });
    setProjectTypeValue(value);
  };

  const fetchData = useCallback(async (): Promise<void> => {
    const res = await searchStandards({
      paging: {
        afterCursor: null,
        beforeCursor: null,
        limit: 50,
      },
      filter: {
        results: {
          canCreateNewProjects: {
            operator: "eq",
            value: true,
          },
        },
      },
    });

    if (res.status === Status.Success && res.data) {
      const codes: SelectData = res.data.results.map((code) => {
        return {
          key: code.uuid,
          value: code.displayName,
        };
      });

      const codesPlusProjectTypes: CodesWithProjectTypesData = res.data.results.map((code) => {
        return {
          codeDisplayName: code.displayName,
          codeUuid: code.uuid,
          projectTypes: code.projectTypes.map((projectType) => {
            return {
              displayName: projectType.displayName,
              uuid: projectType.uuid,
            };
          }),
        };
      });

      setCodesSelectData(codes);
      setCodesWithProjectTypes(codesPlusProjectTypes);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (formData.codeUuid && codesWithProjectTypes && codesSelectData) {
      const projectTypes = codesWithProjectTypes.find((c) => c.codeUuid === formData.codeUuid)?.projectTypes;

      if (projectTypes) {
        setProjectTypeSelectData(
          projectTypes.map((p) => {
            return { key: p.uuid, value: p.displayName };
          })
        );
        setFormData({
          ...formData,
          projectTypeUuid: "",
        });
      }
    }
  }, [formData.codeUuid]);

  useEffect(() => {
    if (projectTypeSelectData.length > 1) {
      setDisplayProjectTypeSelect(true);
    } else {
      setDisplayProjectTypeSelect(false);
    }
    if (projectTypeSelectData.length === 1) {
      onProjectTypeChange(projectTypeSelectData[0].key.toString());
    }
  }, [projectTypeSelectData]);

  const handleClose = (): void => {
    setFormData({
      projectName: "",
      codeUuid: "",
      projectTypeUuid: "",
    });
    setProjectTypeSelectData([]);
    setErrors([]);
    closeModal();
  };

  const handleSubmit = useIsLoadingWrapper(async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (currentDeveloperUuid) {
      const res = await createProject({
        currentDeveloperUuid,
        standardUuid: formData.codeUuid,
        displayName: formData.projectName,
        projectTypeUuid: formData.projectTypeUuid,
      });

      if (res.status === Status.Success) {
        Toast.success({ message: "Project created successfully" });
        handleClose();
      }

      if (res.status === Status.Error) {
        setErrors(res.errors);
      }
    }
  }, setIsHandleSubmitLoading);

  return {
    errors,
    codeData: codesSelectData,
    projectTypeData: projectTypeSelectData,
    projectTypeValue,
    formData,
    displayProjectTypeSelect,
    isHandleSubmitLoading,
    onProjectNameChange,
    onCodeChange,
    onProjectTypeChange,
    handleClose,
    handleSubmit,
  };
};
