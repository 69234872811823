import { Dispatch, SetStateAction, useContext, useState } from "react";

import { createDiscussionThread } from "../../../../../../service/discussion/DiscussionService";
import { ServiceError, Status } from "../../../../../../service/Shared";
import { useAuth } from "../../../../../../useAuth";
import { useIsLoadingWrapper } from "../../../../../../utils";
import { Toast } from "../../../../../../widget";
import { ActivityDashboardContext } from "../../../ActivityDashboardContext";
import { ActivityDiscussionContext } from "../../ActivityDiscussionContext";

interface UseNewDiscussionModalReturnData {
  message: string;
  setMessage: Dispatch<SetStateAction<string>>;
  errors: ServiceError[] | undefined;
  handleCloseModal: () => void;
  handleCreateThread: () => Promise<void>;
  isHandleCreateThreadLoading: boolean;
}

export const useNewDiscussionModal = (closeModal: () => void): UseNewDiscussionModalReturnData => {
  const { currentOrganisationUuid } = useAuth();
  const { activityUuid } = useContext(ActivityDashboardContext);
  const [message, setMessage] = useState<string>("");
  const [errors, setErrors] = useState<ServiceError[] | undefined>();
  const [isHandleCreateThreadLoading, setIsHandleCreateThreadLoading] = useState(false);

  const { refreshDiscussionTables } = useContext(ActivityDiscussionContext);

  const handleCloseModal = (): void => {
    setMessage("");
    setErrors(undefined);
    closeModal();
  };

  const handleCreateThread = useIsLoadingWrapper(async (): Promise<void> => {
    const res = await createDiscussionThread({
      componentKey: null,
      dataPath: null,
      messageContent: message,
      messageObjectType: "Activity",
      messageObjectUuid: activityUuid ?? "",
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      messageSourceOrganisationUuid: currentOrganisationUuid!,
      messageType: "Comment",
      messageStatus: "Published",
      threadObjectType: "Activity",
      threadObjectUuid: activityUuid ?? "",
      threadType: "Discussion",
      visibility: "Internal",
      messageTargetOrganisationRoles: ["Developer", "Guest - Read", "Guest - Write"],
    });

    if (res.status === Status.Success) {
      Toast.success({ message: "Discussion created" });
      refreshDiscussionTables();

      handleCloseModal();
    }

    if (res.status === Status.Error && res.errors) {
      setErrors(res.errors);
    }
  }, setIsHandleCreateThreadLoading);

  return { message, setMessage, errors, handleCloseModal, handleCreateThread, isHandleCreateThreadLoading };
};
