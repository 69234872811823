import "./ConfirmSubmissionModal.css";

import { DownloadDarkIcon, FileCornerBlueIcon } from "../../../../assets";
import { Button, Column, InformationBox, Modal, Row } from "../../../../widget";
import { useConfirmSubmissionModal } from "./useConfirmSubmissionModal";

interface ConfirmSubmissionModalProps {
  show: boolean;
  onClose: () => void;
  activityUuid: string;

  activityProjectUuid?: string;
  activityHistoryUuid: string;
  activityHistoryRowVersion: number | undefined;
}

export const ConfirmSubmissionModal = ({
  show,
  onClose,
  activityUuid,
  activityProjectUuid,
  activityHistoryUuid,
  activityHistoryRowVersion,
}: ConfirmSubmissionModalProps): JSX.Element => {
  const { onConfirmClick, onDownloadClick, showDownloadActivityFilesInformationBox } = useConfirmSubmissionModal({
    activityUuid,
    activityProjectUuid,
    onClose,
    activityHistoryUuid,
    activityHistoryRowVersion,
  });

  return (
    <Modal show={show} title="Submit activity" onClose={onClose}>
      <Row spacingV="ll" justify="center">
        <Column span={10}>
          <span className="body1">
            To submit this verification, please download the PDD and all associated documents then manually upload them
            to your external registry. Once you&rsquo;ve completed that, come back here to confirm that you&rsquo;ve
            submitted the document.
          </span>
        </Column>
      </Row>
      <Row spacingV={showDownloadActivityFilesInformationBox ? "ml" : "2xl"} justify="center">
        <Column span={10}>
          <div className="ConfirmSubmissionDownloadBox">
            <div className="ConfirmSubmissionDownloadBoxHeader">
              <FileCornerBlueIcon />
              <h3>Output + all associated documents</h3>
            </div>
            <Button text="Download" variant="secondary" icon={<DownloadDarkIcon />} onClick={onDownloadClick} />
          </div>
        </Column>
      </Row>
      {showDownloadActivityFilesInformationBox && (
        <Row spacingV="2xl" justify="center">
          <Column span={10}>
            <InformationBox variant="info">
              <div className="ConfirmSubmissionDownloadFilesInfoBoxContent">
                <span>Due to the size of these files they may take a couple of minutes to prepare.</span>

                <span>
                  We will send you an email with a link to access them once they are ready for you to download, so
                  please keep an eye on your inbox.
                </span>
              </div>
            </InformationBox>
          </Column>
        </Row>
      )}
      <Row justify="center">
        <Column span={6}>
          <div className="ConfirmSubmissionModalButtons">
            <Button text="Confirm submission" onClick={onConfirmClick} />
            <Button variant="secondary" text="Cancel" onClick={onClose} />
          </div>
        </Column>
      </Row>
    </Modal>
  );
};
